import React from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import { useNavigate } from "react-router-dom";
import InputField from "../component/InputField";
import CheckBox from "react-checkbox-css";
import { useState, useEffect } from "react";
import { X } from "react-feather";
import { addEditRegistrationAdmissions } from "../APIs/Repos";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { VALIDATION_REGEX } from "../Constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function Upload({ onUploadingImageCallBack }) {
  //#region Variables
  var [image, setImage] = useState("");
  //#endregion

  //#region other functions
  const onImageUpload = (e) => {
    const fileType = e.target.files[0];

    if (
      fileType.type == "image/jpeg" ||
      fileType.type == "image/png" ||
      fileType.type == "application/pdf"
    ) {
      setImage((image = e.target.files[0]));
      onUploadingImageCallBack(image);
    } else alert("Only Pictures and PDF files Allowed");
  };

  const onCrossButtonClickListener = () => {
    setImage((image = ""));
    onUploadingImageCallBack(image);
  };
  //#endregion
  return (
    <div className="start__up__container__form__input__box__content__upload__wrapper">
      <div className="start__up__container__form__input__box__content__upload">
        <input
          type="file"
          className="start__up__container__form__input__box__content__upload__btn"
          onChange={(e) => {
            onImageUpload(e);
          }}
        />
        Choose File
      </div>
      {image === "" ? null : (
        <div className="start__up__container__form__input__box__content__upload__uploaded">
          <button
            className="start__up__container__form__input__box__content__upload__uploaded__close"
            onClick={() => {
              onCrossButtonClickListener();
            }}
          >
            <X size={15} color="currentColor" />
          </button>
          <iframe
            src={URL.createObjectURL(image)}
            alt={image}
            className="start__up__container__form__input__box__content__upload__uploaded__img"
          />
        </div>
      )}
    </div>
  );
}

export default function Alumini() {
  //#region Variables
  const navigate = useNavigate();
  var [branchName, setBranchName] = useState("");
  const [isBranchNameRequired, setIsBranchNameRequired] = useState(true);

  var [name, setName] = useState("");
  const [isNameRequired, setIsNameRequired] = useState(true);

  var [gender, setGender] = useState("");
  const [isGenderRequired, setIsGenderRequired] = useState(true);

  var [dateOfBrith, setDateOfBirth] = useState("");
  const [isDateOfBirthRequired, setIsDateOfBirthRequired] = useState(true);

  var [placeOfBirth, setPlaceOfBirth] = useState("");
  const [isPlaceOfBirthRequired, setIsPlaceOfBirthRequired] = useState(true);

  var [classInWhichAdmission, setClassInWhichAdmission] = useState("");
  const [isClassInWhichAdmissionRequired, setIsClassInWhichAdmissionRequired] =
    useState(true);

  var [fatherNameAddress, setFatherNameAddress] = useState("");
  const [isFatherNameAddressRequired, setIsFatherNameAddressRequired] =
    useState(true);

  var [fatherProfession, setFatherProfession] = useState("");
  const [isFatherProfessionRequired, setIsFatherProfessionRequired] =
    useState(true);

  var [fatherTel, setFatherTel] = useState("");
  const [isFatherTelRequired, setIsFatherTelRequired] = useState(true);

  var [fatherOff, setFatherOff] = useState("");
  const [isFatherOffRequired, setIsFatherOffRequired] = useState(false);

  var [fatherMob, setFatherMob] = useState("");
  const [isFatherMobRequired, setIsFatherMobRequired] = useState(false);

  var [fatherCNIC, setFatherCNIC] = useState("");
  const [isFatherCNICRequired, setIsFatherCNICRequired] = useState(true);

  var [motherNameAddress, setMotherNameAddress] = useState("");
  const [isMotherNameAddressRequired, setIsMotherNameAddressRequired] =
    useState(true);

  var [motherProfession, setMotherProfession] = useState("");
  const [isMotherProfessionRequired, setIsMotherProfessionRequired] =
    useState(true);

  var [motherTel, setMotherTel] = useState("");
  const [isMotherTelRequired, setIsMotherTelRequired] = useState(true);

  var [motherOff, setMotherOff] = useState("");
  const [isMotherOffRequired, setIsMotherOffRequired] = useState(false);

  var [motherMob, setMotherMob] = useState("");
  const [isMotherMobRequired, setIsMotherMobRequired] = useState(false);

  var [motherCNIC, setMotherCNIC] = useState("");
  const [isMotherCNICRequired, setIsMotherCNICRequired] = useState(true);

  var [guardianNameAddress, setGuardianNameAddress] = useState("");
  var [guardianProfession, setGuardianProfession] = useState("");

  var [guardianTel, setGuardianTel] = useState("");
  const [isGuardianTelRequired, setIsGuardianTelRequired] = useState(false);

  var [guardianOff, setGuardianOff] = useState("");
  const [isGuardianOffRequired, setIsGuardianOffRequired] = useState(false);

  var [guardianMob, setGuardianMob] = useState("");
  const [isGuardianMobRequired, setIsGuardianMobRequired] = useState(false);

  var [guardianCNIC, setGuardianCNIC] = useState("");
  var [nationalityOfParents, setNationalityOfParents] = useState("");
  const [isNationalityOfParentsRequired, setIsNationalityOfParentsRequired] =
    useState(true);

  var [nameOfPreviousSchool, setNameOfPreviousSchool] = useState("");
  const [isNameOfPreviousSchoolRequired, setIsNameOfPreviousSchoolRequired] =
    useState(true);

  var [previousClass, setPreviousClass] = useState("");
  const [isPreviousClassRequired, setIsPreviousClassRequired] = useState(true);

  var [siblingInLGS, setSiblingInLGS] = useState("");
  const [isSiblingInLGSRequired, setIsSiblingInLGSRequired] = useState(true);

  var [siblingInLGSClass, setSiblingInLGSClass] = useState("");
  const [isSiblingInLGSClassRequired, setIsSiblingInLGSClassRequired] =
    useState(false);

  var [birthCertificateImage, setBirthCertificateImage] = useState("");
  var [leavingCertificateImage, setLeavingCertificateImage] = useState("");

  const [siblingInLGSBranchDiability, setSiblingInLGSBranchDiability] =
    useState(true);

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  function handleSubmit() {
    localStorage.setItem(
      "registerdata",
      JSON.stringify([
        {
          branchName: branchName,
          name: name,
          gender: gender,
          dateOfBrith: dateOfBrith,
          placeOfBirth: placeOfBirth,
          classInWhichAdmission: classInWhichAdmission,
          fatherNameAddress: fatherNameAddress,
          fatherProfession: fatherProfession,
          fatherTel: fatherTel,
          fatherOff: fatherOff,
          fatherMob: fatherMob,
          fatherCNIC: fatherCNIC,
          motherNameAddress: motherNameAddress,
          motherProfession: motherProfession,
          motherTel: motherTel,
          motherOff: motherOff,
          motherMob: motherMob,
          motherCNIC: motherCNIC,
          guardianNameAddress: guardianNameAddress,
          guardianProfession: guardianProfession,
          guardianTel: guardianTel,
          guardianOff: guardianOff,
          guardianMob: guardianMob,
          guardianCNIC: guardianCNIC,
          nationalityOfParents: nationalityOfParents,
          nameOfPreviousSchool: nameOfPreviousSchool,
          previousClass: previousClass,
          siblingInLGS: siblingInLGS,
          siblingInLGSClass: siblingInLGSClass,
        },
      ])
    );
    console.log("ok");
    console.log(localStorage.getItem("registerdata"));
    navigate("/forms");
  }

  //#region API Call
  const addEditRegistrationAdmissionsApiCall = () => {
    let data = {
      id: 0,
      branch_name: branchName,
      child_name: name,
      date_of_birth: dateOfBrith,
      gender: gender,
      place_of_birth: placeOfBirth,
      desired_class: classInWhichAdmission,
      father_name_and_address: fatherNameAddress,
      father_profession: fatherProfession,
      father_tel: fatherTel,
      father_tel_off: fatherOff,
      father_mobile: fatherMob,
      father_cnic: fatherCNIC,
      mother_name_and_address: motherNameAddress,
      mother_profession: motherProfession,
      mother_tel: motherTel,
      mother_tel_off: motherOff,
      mother_mobile: motherMob,
      mother_cnic: motherCNIC,
      guardian_name_and_address: guardianNameAddress,
      guardian_profession: guardianProfession,
      guardian_tel: guardianTel,
      guardian_tel_of: guardianOff,
      guardian_mobile: guardianMob,
      nationality_of_parents: nationalityOfParents,
      previous_school_name: nameOfPreviousSchool,
      previous_class_and_promotion_date: previousClass,
      siblings_name: siblingInLGS,
      siblings_branch: siblingInLGS == "Yes" ? siblingInLGSClass : "",
    };
    var bodyFormData = new FormData();
    bodyFormData.append("model", JSON.stringify(data));
    if (birthCertificateImage)
      bodyFormData.append(
        "birth_certificate",
        birthCertificateImage,
        birthCertificateImage.name
      );
    if (leavingCertificateImage)
      bodyFormData.append(
        "leaving_certificate",
        leavingCertificateImage,
        leavingCertificateImage.name
      );
    if (isViewValid()) {
      setIsLoading(true);
      addEditRegistrationAdmissions(bodyFormData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            toast("Registered Successfully!");
            resetStatesAfterSuccessfullRegisteration();
            window.location.reload();
          } else {
            alert("Ops! Unable to register now, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("addEditRegistrationAdmissions Error", error);
        });
    }
  };
  //#endregion

  //#region ReSet States

  const resetStatesAfterSuccessfullRegisteration = () => {
    setBranchName("");
    setName("");
    setGender("");
    setDateOfBirth("");
    setPlaceOfBirth("");
    setClassInWhichAdmission("");
    setFatherNameAddress("");
    setFatherProfession("");
    setFatherTel("");
    setFatherOff("");
    setFatherMob("");
    setFatherCNIC("");
    setMotherNameAddress("");
    setMotherProfession("");
    setMotherTel("");
    setMotherOff("");
    setMotherMob("");
    setMotherCNIC("");
    setGuardianNameAddress("");
    setGuardianProfession("");
    setGuardianTel("");
    setGuardianOff("");
    setGuardianMob("");
    setNationalityOfParents("");
    setNameOfPreviousSchool("");
    setPreviousClass("");
    setSiblingInLGS("");
    setSiblingInLGSClass("");
    setBirthCertificateImage("");
    setLeavingCertificateImage("");
  };

  //#endregion

  //#region Branch Name Validation

  const onBranchNameTextChangeListener = (event) => {
    setBranchName((branchName = event.currentTarget.value));
    setBranchNameErrorMessageAndVisibilityAndIsValid(branchName);
  };

  const setBranchNameErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setBranchNameErrorMessageAndVisibility(true);
    else {
      setBranchNameErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setBranchNameErrorMessageAndVisibility = (visibility) => {
    setIsBranchNameRequired(visibility);
  };
  //#endregion

  //#region Name Validation

  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorMessageAndVisibilityAndIsValid(name);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setNameErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.CAPITAL_LETTERS.test(enterdText))
      setNameErrorMessageAndVisibility(true);
    else {
      setNameErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameErrorMessageAndVisibility = (visibility) => {
    setIsNameRequired(visibility);
  };
  //#endregion

  //#region Date Of Birth Validation

  const onDateOfBirthTextChangeListener = (event) => {
    setDateOfBirth((dateOfBrith = event.currentTarget.value));
    setDateOfBirthErrorMessageAndVisibilityAndIsValid(dateOfBrith);
  };

  const setDateOfBirthErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setDateOfBirthErrorMessageAndVisibility(true);
    else {
      setDateOfBirthErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setDateOfBirthErrorMessageAndVisibility = (visibility) => {
    setIsDateOfBirthRequired(visibility);
  };
  //#endregion

  //#region Gender Validation

  const onGenderTextChangeListener = (event) => {
    setGender((gender = event));
    setGenderErrorMessageAndVisibilityAndIsValid(gender);
  };

  const setGenderErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setGenderErrorMessageAndVisibility(true);
    else {
      setGenderErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setGenderErrorMessageAndVisibility = (visibility) => {
    setIsGenderRequired(visibility);
  };
  //#endregion

  //#region Place of Birth Validation

  const onPlaceOfBirthTextChangeListener = (event) => {
    setPlaceOfBirth((placeOfBirth = event.currentTarget.value));
    setPlaceOfBirthErrorMessageAndVisibilityAndIsValid(placeOfBirth);
  };

  const setPlaceOfBirthErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setPlaceOfBirthErrorMessageAndVisibility(true);
    else {
      setPlaceOfBirthErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPlaceOfBirthErrorMessageAndVisibility = (visibility) => {
    setIsPlaceOfBirthRequired(visibility);
  };
  //#endregion

  //#region Class In which Admission Validation

  const onAdmissionClassTextChangeListener = (event) => {
    setClassInWhichAdmission(
      (classInWhichAdmission = event.currentTarget.value)
    );
    setAdmissionClassErrorMessageAndVisibilityAndIsValid(classInWhichAdmission);
  };

  const setAdmissionClassErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setAdmissionClassErrorMessageAndVisibility(true);
    else {
      setAdmissionClassErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setAdmissionClassErrorMessageAndVisibility = (visibility) => {
    setIsClassInWhichAdmissionRequired(visibility);
  };
  //#endregion

  //#region Father Name & Address Validation

  const onFatherNameAddressTextChangeListener = (event) => {
    setFatherNameAddress((fatherNameAddress = event.currentTarget.value));
    setFatherNameAddressErrorMessageAndVisibilityAndIsValid(fatherNameAddress);
  };

  const setFatherNameAddressErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setFatherNameAddressErrorMessageAndVisibility(true);
    else {
      setFatherNameAddressErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setFatherNameAddressErrorMessageAndVisibility = (visibility) => {
    setIsFatherNameAddressRequired(visibility);
  };
  //#endregion

  //#region Father Profession Validation

  const onFatherProfessionTextChangeListener = (event) => {
    setFatherProfession((fatherProfession = event.currentTarget.value));
    setFatherProfessionErrorMessageAndVisibilityAndIsValid(fatherProfession);
  };

  const setFatherProfessionErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setFatherProfessionErrorMessageAndVisibility(true);
    else {
      setFatherProfessionErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setFatherProfessionErrorMessageAndVisibility = (visibility) => {
    setIsFatherProfessionRequired(visibility);
  };
  //#endregion

  //#region Father Tel Validation

  const onFatherTelTextChangeListener = (event) => {
    if (!isNaN(event.currentTarget.value)) {
      setFatherTel((fatherTel = event.currentTarget.value));
      setFatherTelErrorMessageAndVisibilityAndIsValid(fatherTel);
    } else setFatherTelErrorMessageAndVisibility(true);
  };

  const setFatherTelErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setFatherTelErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(enterdText)) {
      setFatherTelErrorMessageAndVisibility(true);
    } else {
      setFatherTelErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setFatherTelErrorMessageAndVisibility = (visibility) => {
    setIsFatherTelRequired(visibility);
  };
  //#endregion

  //#region Father Tel Off Validation

  const onFatherOffTextChangeListener = (event) => {
    setFatherOff((fatherOff = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(fatherOff))
      setIsFatherOffRequired(true);
    else setIsFatherOffRequired(false);
    if (isNullOrEmpty(fatherOff)) setIsFatherOffRequired(false);
  };

  //#endregion

  //#region Father Mob Validation

  const onFatherMobTextChangeListener = (event) => {
    setFatherMob((fatherMob = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(fatherMob))
      setIsFatherMobRequired(true);
    else setIsFatherMobRequired(false);
    if (isNullOrEmpty(fatherMob)) setIsFatherMobRequired(false);
  };

  //#endregion

  //#region Father CNIC Validation

  const onFatherCNICTextChangeListener = (event) => {
    if (!isNaN(event.currentTarget.value)) {
      setFatherCNIC((fatherCNIC = event.currentTarget.value));
      setFatherCNICErrorMessageAndVisibilityAndIsValid(fatherCNIC);
    } else setFatherCNICErrorMessageAndVisibility(true);
  };

  const setFatherCNICErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setFatherCNICErrorMessageAndVisibility(true);
    else {
      setFatherCNICErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setFatherCNICErrorMessageAndVisibility = (visibility) => {
    setIsFatherCNICRequired(visibility);
  };
  //#endregion

  //#region Mother Name & Address Validation

  const onMotherNameAddressTextChangeListener = (event) => {
    setMotherNameAddress((motherNameAddress = event.currentTarget.value));
    setMotherNameAddressErrorMessageAndVisibilityAndIsValid(motherNameAddress);
  };

  const setMotherNameAddressErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setMotherNameAddressErrorMessageAndVisibility(true);
    else {
      setMotherNameAddressErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setMotherNameAddressErrorMessageAndVisibility = (visibility) => {
    setIsMotherNameAddressRequired(visibility);
  };
  //#endregion

  //#region Mother Profession Validation

  const onMotherProfessionTextChangeListener = (event) => {
    setMotherProfession((motherProfession = event.currentTarget.value));
    setMotherProfessionErrorMessageAndVisibilityAndIsValid(motherProfession);
  };

  const setMotherProfessionErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setMotherProfessionErrorMessageAndVisibility(true);
    else {
      setMotherProfessionErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setMotherProfessionErrorMessageAndVisibility = (visibility) => {
    setIsMotherProfessionRequired(visibility);
  };
  //#endregion

  //#region Mother Tel Validation

  const onMotherTelTextChangeListener = (event) => {
    if (!isNaN(event.currentTarget.value)) {
      setMotherTel((motherTel = event.currentTarget.value));
      setMotherTelErrorMessageAndVisibilityAndIsValid(motherTel);
    } else setMotherTelErrorMessageAndVisibility(true);
  };

  const setMotherTelErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setMotherTelErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(enterdText)) {
      setFatherTelErrorMessageAndVisibility(true);
    } else {
      setMotherTelErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setMotherTelErrorMessageAndVisibility = (visibility) => {
    setIsMotherTelRequired(visibility);
  };
  //#endregion

  //#region Mother Tel Off Validation

  const onMotherOffTextChangeListener = (event) => {
    setMotherOff((motherOff = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(motherOff))
      setIsMotherOffRequired(true);
    else setIsMotherOffRequired(false);
    if (isNullOrEmpty(motherOff)) setIsMotherOffRequired(false);
  };

  //#endregion

  //#region Mother Tel Mob Validation

  const onMotherMobTextChangeListener = (event) => {
    setMotherMob((motherMob = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(motherMob))
      setIsMotherMobRequired(true);
    else setIsMotherMobRequired(false);
    if (isNullOrEmpty(motherMob)) setIsMotherMobRequired(false);
  };

  //#endregion

  //#region Mother CNIC Validation

  const onMotherCNICTextChangeListener = (event) => {
    if (!isNaN(event.currentTarget.value)) {
      setMotherCNIC((motherCNIC = event.currentTarget.value));
      setMotherCNICErrorMessageAndVisibilityAndIsValid(motherCNIC);
    } else setMotherCNICErrorMessageAndVisibility(true);
  };

  const setMotherCNICErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setMotherCNICErrorMessageAndVisibility(true);
    else {
      setMotherCNICErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setMotherCNICErrorMessageAndVisibility = (visibility) => {
    setIsMotherCNICRequired(visibility);
  };
  //#endregion

  //#region Guardian Tel Validation

  const onGuardianTelTextChangeListener = (event) => {
    setGuardianTel((guardianTel = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(guardianTel))
      setIsGuardianTelRequired(true);
    else setIsGuardianTelRequired(false);
    if (isNullOrEmpty(motherOff)) setIsGuardianTelRequired(false);
  };

  //#endregion

  //#region Guardian Off Validation

  const onGuardianOffTextChangeListener = (event) => {
    setGuardianOff((guardianOff = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(guardianOff))
      setIsGuardianOffRequired(true);
    else setIsGuardianOffRequired(false);
    if (isNullOrEmpty(motherOff)) setIsGuardianOffRequired(false);
  };

  //#endregion

  //#region Guardian Mob Validation

  const onGuardianMobTextChangeListener = (event) => {
    setGuardianMob((guardianMob = event.currentTarget.value));
    if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(guardianMob))
      setIsGuardianMobRequired(true);
    else setIsGuardianMobRequired(false);
    if (isNullOrEmpty(motherOff)) setIsGuardianMobRequired(false);
  };

  //#endregion

  //#region Parents Nationality Validation

  const onParentsNationalityTextChangeListener = (event) => {
    setNationalityOfParents((nationalityOfParents = event.currentTarget.value));
    setNationalityOfParentsErrorMessageAndVisibilityAndIsValid(
      nationalityOfParents
    );
  };

  const setNationalityOfParentsErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setNationalityOfParentsErrorMessageAndVisibility(true);
    else {
      setNationalityOfParentsErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNationalityOfParentsErrorMessageAndVisibility = (visibility) => {
    setIsNationalityOfParentsRequired(visibility);
  };
  //#endregion

  //#region previous School Validation

  const onPreviousSchoolTextChangeListener = (event) => {
    setNameOfPreviousSchool((nameOfPreviousSchool = event.currentTarget.value));
    setNameOfPreviousSchoolErrorMessageAndVisibilityAndIsValid(
      nameOfPreviousSchool
    );
  };

  const setNameOfPreviousSchoolErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setNameOfPreviousSchoolErrorMessageAndVisibility(true);
    else {
      setNameOfPreviousSchoolErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameOfPreviousSchoolErrorMessageAndVisibility = (visibility) => {
    setIsNameOfPreviousSchoolRequired(visibility);
  };
  //#endregion

  //#region previous Class Validation

  const onPreviousClassTextChangeListener = (event) => {
    setPreviousClass((previousClass = event.currentTarget.value));
    setPreviousClassErrorMessageAndVisibilityAndIsValid(previousClass);
  };

  const setPreviousClassErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setPreviousClassErrorMessageAndVisibility(true);
    else {
      setPreviousClassErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPreviousClassErrorMessageAndVisibility = (visibility) => {
    setIsPreviousClassRequired(visibility);
  };
  //#endregion

  //#region Sibling in LGS Validation

  const onSiblingInLGSTextChangeListener = (event) => {
    setSiblingInLGS((siblingInLGS = event.currentTarget.value));
    setSiblingInLGSErrorMessageAndVisibilityAndIsValid(siblingInLGS);
  };

  const setSiblingInLGSErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setSiblingInLGSErrorMessageAndVisibility(true);
    else {
      setSiblingInLGSErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setSiblingInLGSErrorMessageAndVisibility = (visibility) => {
    setIsSiblingInLGSRequired(visibility);
  };
  //#endregion

  //#region Sibling Class Validation

  const onSiblingClassTextChangeListener = (event) => {
    setSiblingInLGSClass((siblingInLGSClass = event.currentTarget.value));
    if (siblingInLGS == "Yes")
      setSiblingInLGSClassErrorMessageAndVisibilityAndIsValid(
        siblingInLGSClass
      );
  };

  const setSiblingInLGSClassErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setSiblingInLGSClassErrorMessageAndVisibility(true);
    else {
      setSiblingInLGSClassErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setSiblingInLGSClassErrorMessageAndVisibility = (visibility) => {
    setIsSiblingInLGSClassRequired(visibility);
  };
  //#endregion

  //#region Is View Valid

  const isViewValid = () => {
    var isViewValid =
      setBranchNameErrorMessageAndVisibilityAndIsValid(branchName);
    if (isViewValid)
      isViewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    if (isViewValid)
      isViewValid =
        setDateOfBirthErrorMessageAndVisibilityAndIsValid(dateOfBrith);
    if (isViewValid)
      isViewValid = setGenderErrorMessageAndVisibilityAndIsValid(gender);
    if (isViewValid)
      isViewValid =
        setPlaceOfBirthErrorMessageAndVisibilityAndIsValid(placeOfBirth);
    if (isViewValid)
      isViewValid = setAdmissionClassErrorMessageAndVisibilityAndIsValid(
        classInWhichAdmission
      );
    if (isViewValid)
      isViewValid =
        setFatherNameAddressErrorMessageAndVisibilityAndIsValid(
          fatherNameAddress
        );
    if (isViewValid)
      isViewValid =
        setFatherProfessionErrorMessageAndVisibilityAndIsValid(
          fatherProfession
        );
    if (isViewValid)
      isViewValid = setFatherTelErrorMessageAndVisibilityAndIsValid(fatherTel);
    if (isViewValid)
      isViewValid =
        setFatherCNICErrorMessageAndVisibilityAndIsValid(fatherCNIC);
    if (isViewValid)
      isViewValid =
        setMotherNameAddressErrorMessageAndVisibilityAndIsValid(
          motherNameAddress
        );
    if (isViewValid)
      isViewValid =
        setMotherProfessionErrorMessageAndVisibilityAndIsValid(
          motherProfession
        );
    if (isViewValid)
      isViewValid = setMotherTelErrorMessageAndVisibilityAndIsValid(motherTel);
    if (isViewValid)
      isViewValid =
        setMotherCNICErrorMessageAndVisibilityAndIsValid(motherCNIC);
    if (isViewValid)
      isViewValid =
        setNationalityOfParentsErrorMessageAndVisibilityAndIsValid(
          nationalityOfParents
        );
    if (isViewValid)
      isViewValid =
        setNameOfPreviousSchoolErrorMessageAndVisibilityAndIsValid(
          nameOfPreviousSchool
        );
    if (isViewValid)
      isViewValid =
        setPreviousClassErrorMessageAndVisibilityAndIsValid(previousClass);
    if (isViewValid)
      isViewValid =
        setSiblingInLGSErrorMessageAndVisibilityAndIsValid(siblingInLGS);
    if (isViewValid)
      isViewValid =
        setSiblingInLGSClassErrorMessageAndVisibilityAndIsValid(
          siblingInLGSClass
        );
    if (isViewValid) {
      if (birthCertificateImage == "") isViewValid = false;
      else isViewValid = true;
    }
    if (isViewValid) {
      if (leavingCertificateImage == "") isViewValid = false;
      else isViewValid = true;
    }
    return isViewValid;
  };

  //#endregion

  //#region other Functions

  const setSiblingClassInLGSBranchDisability = () => {
    // siblingInLGS == "No" || siblingInLGS == "" ? true : false;
    if (siblingInLGS == "No" || siblingInLGS == "") {
      setSiblingInLGSClass("");
      setSiblingInLGSBranchDiability(true);
    } else {
      setSiblingInLGSBranchDiability(false);
    }
  };

  //#endregion
  return (
    <>
      <div className="our__school__container" style={{ height: "300px" }}>
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div
              className="our__school__overlay__content__heading"
              style={{ marginTop: "5em", textAlign: "center", fontSize: 30 }}
            >
              Registration & Admission Form
            </div>
          </div>
        </div>
      </div>
      <div
        className="career__section__content"
        style={{ marginBottom: "5em", marginTop: "5em" }}
      >
        <div className="career__section__content__input__row">
          <InputField
            label="Name Of Branch"
            required={isBranchNameRequired}
            value={branchName}
            onChange={(e) => {
              onBranchNameTextChangeListener(e);
            }}
            listName="nameofbranch"
            list={["Canal Campus", "105 Campus"]}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Child's full name (in capital letters)"
            required={isNameRequired}
            value={name}
            onChange={(e) => {
              onNameTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            type="date"
            label="Exact date of birth"
            // min="2013-12-25"
            max="today"
            // maxDate="today"
            value={dateOfBrith}
            required={isDateOfBirthRequired}
            onChange={(e) => {
              onDateOfBirthTextChangeListener(e);
            }}
          />
          <div className="start__up__container__form__input__box">
            <div className="start__up__container__form__input__box__label">
              Gender
              <div className="start__up__container__form__input__box__label__required">
                {isGenderRequired ? "*" : null}
              </div>
            </div>
            <div
              className="start__up__container__form__input__box__content"
              style={{ marginTop: "1em" }}
            >
              <CheckBox
                value={gender === "Male"}
                label="Male"
                labelColor="#242424"
                checkedColor="#005dff"
                unCheckedColor="#f6f6f6"
                iconColor="#ffffff"
                onChange={() => {
                  onGenderTextChangeListener("Male");
                }}
              />
              <div style={{ marginRight: "2em" }}></div>
              <CheckBox
                value={gender === "Female"}
                label="Female"
                labelColor="#242424"
                checkedColor="#005dff"
                unCheckedColor="#f6f6f6"
                iconColor="#ffffff"
                onChange={() => {
                  onGenderTextChangeListener("Female");
                }}
              />
            </div>
          </div>
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Place of Birth"
            required={isPlaceOfBirthRequired}
            value={placeOfBirth}
            onChange={(e) => {
              onPlaceOfBirthTextChangeListener(e);
            }}
          />
          <InputField
            label="Class in which admission is desired"
            required={isClassInWhichAdmissionRequired}
            value={classInWhichAdmission}
            onChange={(e) => {
              onAdmissionClassTextChangeListener(e);
            }}
            listName="classDesiered"
            list={[
              "Playgroup",
              "Prep",
              "One",
              "Two",
              "Three",
              "Four",
              "Five",
              "Six",
              "Seven",
              "Eighth",
              "Matric",
              "O Level",
              "As",
              "A2",
            ]}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Father's name and address"
            required={isFatherNameAddressRequired}
            value={fatherNameAddress}
            onChange={(e) => {
              onFatherNameAddressTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Father's profession / occupation"
            required={isFatherProfessionRequired}
            value={fatherProfession}
            onChange={(e) => {
              onFatherProfessionTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Father's Tel"
            required={isFatherTelRequired}
            value={fatherTel}
            onChange={(e) => {
              onFatherTelTextChangeListener(e);
            }}
          />
          <InputField
            label="Father's Tel off"
            required={isFatherOffRequired}
            value={fatherOff}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onFatherOffTextChangeListener(e);
            }}
          />
          <InputField
            label="Father's Mob"
            required={isFatherMobRequired}
            value={fatherMob}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onFatherMobTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Father's CNIC"
            required={isFatherCNICRequired}
            value={fatherCNIC}
            onChange={(e) => {
              onFatherCNICTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Mother's name and address"
            required={isMotherNameAddressRequired}
            value={motherNameAddress}
            onChange={(e) => {
              onMotherNameAddressTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Mother's profession / occupation"
            required={isMotherProfessionRequired}
            value={motherProfession}
            onChange={(e) => {
              onMotherProfessionTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Mother's Tel"
            required={isMotherTelRequired}
            value={motherTel}
            onChange={(e) => {
              onMotherTelTextChangeListener(e);
            }}
          />
          <InputField
            label="Mother's Tel off"
            value={motherOff}
            required={isMotherOffRequired}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onMotherOffTextChangeListener(e);
            }}
          />
          <InputField
            label="Mother's Mob"
            value={motherMob}
            required={isMotherMobRequired}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onMotherMobTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Mother's CNIC"
            required={isMotherCNICRequired}
            value={motherCNIC}
            onChange={(e) => {
              onMotherCNICTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Guardian's name and address"
            value={guardianNameAddress}
            onChange={(e) => {
              setGuardianNameAddress(e.target.value);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Guardian's profession / occupation"
            value={guardianProfession}
            onChange={(e) => {
              setGuardianProfession(e.target.value);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Guardian's Tel"
            required={isGuardianTelRequired}
            value={guardianTel}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onGuardianTelTextChangeListener(e);
            }}
          />
          <InputField
            label="Guardian's Tel off"
            required={isGuardianOffRequired}
            value={guardianOff}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onGuardianOffTextChangeListener(e);
            }}
          />
          <InputField
            label="Guardian's Mob"
            required={isGuardianMobRequired}
            value={guardianMob}
            onChange={(e) => {
              if (!isNaN(e.currentTarget.value))
                onGuardianMobTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Nationality of parents"
            required={isNationalityOfParentsRequired}
            value={nationalityOfParents}
            onChange={(e) => {
              onParentsNationalityTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Name of previous nursery / school"
            required={isNameOfPreviousSchoolRequired}
            value={nameOfPreviousSchool}
            onChange={(e) => {
              onPreviousSchoolTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Class previously studying in and date of promotion"
            required={isPreviousClassRequired}
            value={previousClass}
            onChange={(e) => {
              onPreviousClassTextChangeListener(e);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            label="Brother/sister studying in any LGS branch"
            required={isSiblingInLGSRequired}
            value={siblingInLGS}
            onChange={(e) => {
              onSiblingInLGSTextChangeListener(e);
              setSiblingClassInLGSBranchDisability();
            }}
            listName="siblingsInLgsBranch"
            list={["Yes", "No"]}
          />
          <InputField
            label="Class of Brother/sister studying in any LGS branch"
            required={
              siblingInLGS == "No" ||
              siblingInLGS == "" ||
              siblingInLGSClass != ""
                ? false
                : true
            }
            disabled={siblingInLGSBranchDiability}
            value={siblingInLGSClass}
            onChange={(e) => {
              onSiblingClassTextChangeListener(e);
              setSiblingClassInLGSBranchDisability();
            }}
            listName="classOfSibling"
            list={[
              "Playgroup",
              "Prep",
              "One",
              "Two",
              "Three",
              "Four",
              "Five",
              "Six",
              "Seven",
              "Eighth",
              "Matric",
              "O Level",
              "As",
              "A2",
            ]}
          />
        </div>
        <div className="career__section__content__input__row">
          <div className="start__up__container__form__input__box">
            <div className="start__up__container__form__input__box__label">
              Upload Birth certificate
              <div className="start__up__container__form__input__box__label__required">
                {birthCertificateImage ? null : "*"}
              </div>
            </div>
            <div
              className="start__up__container__form__input__box__content"
              style={{ marginTop: ".5em" }}
            >
              <Upload
                onUploadingImageCallBack={(image) => {
                  if (image)
                    setBirthCertificateImage((birthCertificateImage = image));
                  else setBirthCertificateImage("");
                }}
              />
            </div>
          </div>
          <div className="start__up__container__form__input__box">
            <div className="start__up__container__form__input__box__label">
              Upload Leaving certificate
              <div className="start__up__container__form__input__box__label__required">
                {leavingCertificateImage ? null : "*"}
              </div>
            </div>
            <div
              className="start__up__container__form__input__box__content"
              style={{ marginTop: ".5em" }}
            >
              <Upload
                onUploadingImageCallBack={(image) => {
                  if (image)
                    setLeavingCertificateImage(
                      (leavingCertificateImage = image)
                    );
                  else setLeavingCertificateImage("");
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="career__section__content__header"
          style={{
            marginTop: "1em",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => {
              if (isLoading == false) addEditRegistrationAdmissionsApiCall();
            }}
            className="section__btn__primary"
            style={{ padding: "1em 3em", marginRight: "0em" }}
          >
            {isLoading ? "Registering..." : "Register"}
          </button>
        </div>
      </div>
    </>
  );
}
