import axios from "axios";

//#region Academic calender
export const getAcademicCalender = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/academic-break-calender/get-all"
  );
};
//#endregion

//#region Alumni registration
export const addAlumniRegistration = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/alumni/add-or-edit",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};
//#endregion

//#region appointment API

export const addAppointment = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/appointment/add-or-edit",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

//#endregion

//#region Our Campus

export const getAllCampus = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/campus/get-all-active");
};

export const getAllSchool = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/school/get-all");
};

//#endregion

//#region School Events

export const getAllSchoolEventById = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/school-event/get-events-by-school?school_id=" +
      id
  );
};

export const getAllSchoolEventImagesById = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/event-gallery/get-by-event-id?event_id=" +
      id
  );
};

//#endregion

//#region Message

export const getMessagesBySchoolId = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/message/get-by-school-id?id=" + id
  );
};

//#endregion

//#region Faculty Profile

export const getFacultyProfileBySchoolId = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/faculty/get-by-school-id?id=" + id
  );
};

//#endregion

//#region Notice Board

export const getNoticeBoardBySchoolId = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/notice-board/get-by-school-id?id=" + id
  );
};

//#endregion

//#region Latest News

export const getLatestNews = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/news/get-all-active");
};

//#endregion

//#region Admisssion Dates

export const admissionDates = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/admission-date/get-all"
  );
};

//#endregion

//#region Academic Calender PDF

export const academicCalenderPdf = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/academic-calender/get-all-active"
  );
};

//#endregion

//#region Parents HandBook

export const parentsHandbookPdf = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/parent-guide/get-all-active"
  );
};

//#endregion

//#region Admission Test Requirement

export const getAdmissonTestRequirements = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/admission-requirement/get-all-active"
  );
};

export const getAdmissonRequestStorage = () => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/admission-requirement-storage/get-all-active"
  );
};

//#endregion

//#region Complaints

export const addComplaint = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/complaint/add-or-edit",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

//#endregion

//#region News and Events

export const getNewsEvents = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/news-and-event/get-all-active"
  );
};

export const getNewsAndEventsGalleryImages = (id) => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/news-and-event/get-images-by-event-id?eventId=" +
      id
  );
};

//#endregion

//#region Alumni Registeration

export const addEditRegistrationAdmissions = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL +
      "api/registration-and-admission/add-or-edit",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

//#endregion

//#region Vacancy

export const getVacancy = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + "api/vacancy/get-all-active"
  );
};

//#endregion

//#region Policies

export const getPolicies = () => {
  return axios.get(process.env.REACT_APP_API_URL + "api/policy/get-all-active");
};

//#endregion

//#region University Placement

export const getUniversityPlacement = () => {
  return axios.get(
    process.env.REACT_APP_API_URL +
      "api/learning-and-development/get-all-active"
  );
};

//#endregion

//#region Careers

export const careerRegisteration = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/career/add-or-edit",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

//#endregion

//#region Contact Us

export const contact = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "api/contact-us/add-or-edit",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

//#endregion
