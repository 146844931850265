import React, { useState, useEffect } from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import multimediaPic from "../assets/multimediaPic.png";
import presentationPic from "../assets/presentationPic.png";
import libraryPic from "../assets/libraryPic.png";
import musicandArtPic from "../assets/musicandArtPic.png";
import schoolPic from "../assets/schoolPic.png";
import shaziaZubiullah from "../assets/shaziaZubiullah.jpg";
import SUBJECT from "../assets/SUBJECT.png";
import NoticeBoardCard from "../component/NoticeBoardCard";
import FacultyCard from "../component/FacultyCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import FolderGallery from "../component/FolderGallery";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  AnamIlyas,
  AsmaBajwa,
  DuaKhalid,
  IramRizwan,
  KashifManzoor,
  KhatijaFazal,
  KishwarShahazad,
  MariaTayyab,
  MarinaMunifKhan,
  MUHAMMADSALMANALIDONA,
  SalihaShafee,
  SamanSarwar,
  SaneaKhan,
  SehrishAlvi,
  SumbalWarsha,
  TayebaAttaullah,
  UmaraTariq,
  UrvahMushtaq,
} from "../assets";
import { useLocation } from "react-router-dom";
import {
  getAllSchoolEventById,
  getMessagesBySchoolId,
  getFacultyProfileBySchoolId,
  getNoticeBoardBySchoolId,
} from "../APIs/Repos";

function CampusNav({ title, select, setSelect }) {
  return (
    <button
      onClick={() => {
        setSelect(title);
      }}
      className={
        select === title ? "cannal__campus__nav__select" : "cannal__campus__nav"
      }
    >
      {title}
    </button>
  );
}

function PricipalCard({ img, title, designation, para }) {
  return (
    <>
      <div className="cannal__campus__container__swiper__content">
        <div className="cannal__campus__container__wrapper__card__left">
          <div className="cannal__campus__container__wrapper__card__left__content">
            <img
              src={img}
              alt="pricipal_pic"
              className="cannal__campus__container__wrapper__card__left__content__img"
            />
            <div className="cannal__campus__container__wrapper__card__left__content__headings__wrapper">
              <div className="cannal__campus__container__wrapper__card__left__content__heading">
                {title}
              </div>
              <div className="cannal__campus__container__wrapper__card__left__content__para">
                {designation}
              </div>
            </div>
          </div>
        </div>
        <div
          className="cannal__campus__container__wrapper__card__right"
          dangerouslySetInnerHTML={{ __html: para }}
        >
          {/* "{para}" */}
        </div>
      </div>
    </>
  );
}

export default function PrimarySchoolCanalCampus() {
  //#region Variables
  const location = useLocation();
  const [select, setSelect] = useState("Campus Life");
  const [eventsArray, setEventsArray] = useState([]);
  var [schoolId, setSchoolId] = useState(0);
  var [schoolName, setSchoolName] = useState("");

  const [messagesArray, setMessagesArray] = useState([]);
  const [facultyProfileArrayArray, setFacultyProfileArrayArray] = useState([]);
  const [noticeBoardArray, setNoticeBoardArray] = useState([]);

  //#endregion

  const facultyList = [
    {
      img: DuaKhalid,
      name: "Dua Khalid",
      subject: "Assistant Teacher",
    },
    {
      img: KashifManzoor,
      name: "Kashif Manzoor",
      subject: "IT teacher/ Network Administrator",
    },
    {
      img: AnamIlyas,
      name: "Anam Ilyas",
      subject: "Games teacher",
    },
    {
      img: UrvahMushtaq,
      name: "Urvah Mushtaq",
      subject: "Primary Teacher",
    },
    {
      img: MUHAMMADSALMANALIDONA,
      name: "MUHAMMAD SALMAN ALI DONA",
      subject: "Games teacher",
    },
    {
      img: MarinaMunifKhan,
      name: "Marina Munif Khan",
      subject: "Art Teacher",
    },
    {
      img: KhatijaFazal,
      name: "Khatija Fazal",
      subject: "Primary Teacher",
    },
    {
      img: "",
      name: "Nimra Riaz",
      subject: "Urdu Teacher",
    },
    {
      img: SehrishAlvi,
      name: "Sehrish Alvi",
      subject: "Primary Teacher",
    },
    {
      img: SaneaKhan,
      name: "Sanea Khan",
      subject: "Montessori Teacher",
    },
    {
      img: AsmaBajwa,
      name: "Asma Bajwa",
      subject: "Library Teacher",
    },
    {
      img: SalihaShafee,
      name: "Saliha Shafee",
      subject: "",
    },
    {
      img: IramRizwan,
      name: "Iram Rizwan",
      subject: "Primary Teacher",
    },
    {
      img: TayebaAttaullah,
      name: "Tayeba Attaullah",
      subject: "",
    },
    {
      img: KishwarShahazad,
      name: "Kishwar Shahazad",
      subject: "Urdu Teacher",
    },
    {
      img: MariaTayyab,
      name: "Maria Tayyab",
      subject: "Maths teacher",
    },
    {
      img: SamanSarwar,
      name: "Saman Sarwar",
      subject: "Montessori teacher",
    },
    {
      img: UmaraTariq,
      name: "Umara Tariq",
      subject: "Urdu teacher",
    },
    {
      img: SumbalWarsha,
      name: "Sumbal Warsha",
      subject: "IT teacher",
    },
    {
      img: "",
      name: "Javeria Shafqat",
      subject: "MBA (HRM)",
    },
    {
      img: "",
      name: "Khadija Fazal",
      subject: "Master in Clothing & Textile Design, B.S",
    },
    {
      img: "",
      name: "Mehwish Hussain",
      subject: "M.A (Eng. Lit. & Linguistics), M.A (TEFL)",
    },
    {
      img: "",
      name: "Saba Nauman",
      subject: "B.Sc.",
    },
    {
      img: "",
      name: "Asma Hafeez",
      subject: "B.Sc",
    },
    {
      img: "",
      name: "Hassan Raza",
      subject: "B.Sc.",
    },
    {
      img: "",
      name: "Maria Tayyab",
      subject: "B.Sc (Math Stats)",
    },
    {
      img: "",
      name: "Marina Monif Khan",
      subject: "B.Sc.",
    },
    {
      img: "",
      name: "Saghira Amber",
      subject: "M. Phil (Urdu)",
    },
    {
      img: "",
      name: "Saneea Khan",
      subject: "B.Sc.",
    },
    {
      img: "",
      name: "Umara Tariq",
      subject: "M.A Urdu",
    },
    {
      img: "",
      name: "Urvah Mushtaq",
      subject: "B.Sc.",
    },
  ];

  //#region other function
  useEffect(() => {
    setSchoolId(
      (schoolId = location.state ? location.state.schoolDataToPass : 0)
    );

    getAllSchoolEventByIdApiCall();
    getMessagesBySchoolIdApiCall();
    getFacultyProfileBySchoolIdApiCall();
    getNoticeBoardBySchoolIdApiCall();
  }, []);
  //#endregion

  //#region API Calls

  const getAllSchoolEventByIdApiCall = () => {
    getAllSchoolEventById(schoolId)
      .then((res) => {
        if (res.data.success) {
          setEventsArray(res.data.result);
        } else {
          // alert(res.data.message);
        }
        console.log("getAllSchoolEventById Response", res.data.result);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getMessagesBySchoolIdApiCall = () => {
    getMessagesBySchoolId(schoolId)
      .then((res) => {
        if (res.data.success) {
          setMessagesArray(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getFacultyProfileBySchoolIdApiCall = () => {
    getFacultyProfileBySchoolId(schoolId)
      .then((res) => {
        if (res.data.success) {
          setFacultyProfileArrayArray(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getNoticeBoardBySchoolIdApiCall = () => {
    getNoticeBoardBySchoolId(schoolId)
      .then((res) => {
        if (res.data.success) {
          setNoticeBoardArray(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  //#endregion

  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="cannal__campus__heading__wrapper">
              <div className="cannal__campus__sub__heading">CaNAL CAMPUS</div>
              <div
                style={{ color: "#F9CC09" }}
                className="cannal__campus__heading"
              >
                Primary School
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cannal__campus__container">
        <div
          style={{ background: "#FFD934" }}
          className="cannal__campus__container__wrapper__card"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 8500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {messagesArray != null && messagesArray.length > 0
              ? messagesArray.map((data) => (
                  <SwiperSlide>
                    <PricipalCard
                      img={process.env.REACT_APP_API_URL + data.picture_path}
                      title={data.name}
                      designation={data.designation}
                      para={data.message}
                    />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
        <div
          style={{ background: "#022B43" }}
          className="cannal__campus__senior__nav__wrapper"
        >
          <CampusNav
            select={select}
            setSelect={setSelect}
            title="Campus Life"
          />
          <CampusNav title="Gallery" select={select} setSelect={setSelect} />
          <CampusNav
            title="Holistic Approach"
            select={select}
            setSelect={setSelect}
          />
          <CampusNav
            title="Faculty Profiles"
            select={select}
            setSelect={setSelect}
          />
          <CampusNav
            title="Notice board"
            select={select}
            setSelect={setSelect}
          />
        </div>
        {select === "Campus Life" ? (
          <div className="cannal__campus__primary__container">
            <div className="cannal__campus__senior__container__card">
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading">
                  Exploratorium
                </div>
                <div className="admissions__content__section__card__admission__procedure__paragraph">
                  Based on the concept of discovery learning, this is one of the
                  favorite rooms in the school. Here children get a chance to
                  carry on hands-on activities and do experiments and make
                  collective projects related to social science themes.
                </div>
              </div>
              <img
                src={SUBJECT}
                alt="addmissionPic"
                className="cannal__campus__senior__container__card__left__img"
              />
            </div>
            <div className="cannal__campus__senior__container__card__reverse">
              <img
                src={multimediaPic}
                alt="multimediaPic"
                className="cannal__campus__primary__container__card__left__img"
              />
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading__reverse">
                  Multimedia
                </div>
                <div className="admissions__content__section__card__admission__procedure__paragraph__reverse">
                  The Multimedia facility is used to show documentaries and
                  video clips of topics related to their work. Children find
                  these fascinating and we feel this is an enriching experience,
                  away from the typical cartoon and animated movies that most
                  children are exposed to.
                </div>
              </div>
            </div>
            <div className="cannal__campus__senior__container__card">
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading">
                  Library
                </div>
                <div className="admissions__content__section__card__admission__procedure__paragraph">
                  We have a well stocked library with a range of books in both
                  English and Urdu. We also offer karate, gymnastics and dance.
                </div>
              </div>
              <img
                src={libraryPic}
                alt="libraryPic"
                className="cannal__campus__senior__container__card__left__img"
              />
            </div>
            <div
              style={{ background: "#1C1C1C" }}
              className="cannal__campus__senior__container__card__reverse"
            >
              <img
                src={libraryPic}
                alt="libraryPic"
                className="cannal__campus__primary__container__card__left__img"
              />
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading__reverse">
                  Computers
                </div>
                <div className="admissions__content__section__card__admission__procedure__paragraph__reverse">
                  A well equipped computer lab for classes Nursery to Class 1.
                  Children work on-line, learning through various interactive
                  educational software. Our mathematics program is also computer
                  based and is effective in further sharpening students’
                  computational ability and developing their computer skills.
                </div>
              </div>
            </div>
            <div className="cannal__campus__senior__container__card">
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading">
                  Art & Music
                </div>
                <div className="admissions__content__section__card__admission__procedure__paragraph">
                  Art work is not limited to working in the art room alone. We
                  encourage students to express themselves using different
                  mediums. Our students have ample opportunity to go outdoors
                  and use sidewalk chalk and paint brushes to help develop their
                  fine motor skills.
                  <br />
                  <br />
                  The much loved songs, nursery rhymes and poems, both in
                  English and Urdu are incorporated in our music curriculum.
                  Besides developing a sense of rhythm and harmony, poems are a
                  wonderful tool for language development. The selection of
                  songs and rhymes are based on the different themes covered in
                  the curriculum.
                </div>
              </div>
              <img
                src={musicandArtPic}
                alt="musicandArtPic"
                className="cannal__campus__senior__container__card__left__img"
              />
            </div>
            <div
              style={{ background: "#F9CC09" }}
              className="cannal__campus__senior__container__card__reverse"
            >
              <img
                src={libraryPic}
                alt="libraryPic"
                className="cannal__campus__primary__container__card__left__img"
              />
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading__reverse__yellow">
                  Puppet Class
                </div>
                <div
                  style={{ color: "#2B2B2B" }}
                  className="admissions__content__section__card__admission__procedure__paragraph__reverse"
                >
                  Children enjoy the world of make believe and are fascinated by
                  the movement of the puppets and the exciting stories they get
                  to hear in the puppet class.
                </div>
              </div>
            </div>
            <div className="cannal__campus__senior__container__card">
              <div className="cannal__campus__senior__container__card__left">
                <div className="cannal__campus__primary__container__card__left__heading">
                  Presentations
                </div>
                <div className="admissions__content__section__card__admission__procedure__paragraph">
                  We believe that the ability to share and express ideas and
                  experiences with their teachers and peers is an important
                  aspect of child’s development process. At LGS, children
                  regularly give class presentations on a range of curriculum
                  based topics. We feel it’s never too early to start; even the
                  playgroups have circle time for ‘show and tell’.
                </div>
              </div>
              <img
                src={presentationPic}
                alt="presentationPic"
                className="cannal__campus__senior__container__card__left__img"
              />
            </div>
          </div>
        ) : null}
        {select === "Gallery" ? (
          <FolderGallery eventsArray={eventsArray} />
        ) : null}
        {select === "Faculty Profiles" ? (
          <>
            <div className="faculty__section__heading">Faculty Profiles</div>
            <div className="faculty__section__card__wrapper">
              {facultyProfileArrayArray != null &&
              facultyProfileArrayArray.length > 0
                ? facultyProfileArrayArray.map((item) => (
                    <FacultyCard data={item} />
                  ))
                : null}
            </div>
          </>
        ) : null}
        {select === "Notice board" ? (
          <div style={{ marginTop: "4em" }} className="notice__board__section">
            <div className="notice__board__section__content__heading">
              NOTICE BOARD
            </div>
            <div className="notice__board__section__content__paragraph">
              Important Notices will be uploaded here whenever possible.
              However, please make sure that your current cell number has been
              entered in the School database as our primary means of
              communication with parents and students is via SMS.Prep Curriculum
              Outline
            </div>
            {noticeBoardArray != null && noticeBoardArray.length > 0
              ? noticeBoardArray.map((data) => (
                  <NoticeBoardCard
                    title={data.title}
                    description={data.description}
                  />
                ))
              : null}
          </div>
        ) : null}
        {select === "Holistic Approach" ? (
          <div className="global__skill__section">
            <div className="heroistic__section__heading">
              Welcome to our website!
            </div>

            <div
              style={{ marginTop: "3em" }}
              className="cannal__campus__senior__container__card"
            >
              <div className="cannal__campus__senior__container__card__left">
                <div className="heroistic__section__heading__para">
                  We hope that this site not only provides you with useful
                  information, but also acts as a window to what makes LGS a
                  unique school.
                </div>
                <div className="heroistic__section__heading__para">
                  We believe that children should be exposed to as many ideas
                  and opportunities as possible and given the freedom to explore
                  and question them. We want our students to form their own
                  opinions and make their own choices. We strongly encourage our
                  students to take part in extra curricular activities and
                  community service projects as well. We aim to provide a
                  learning environment that enables our children to be thinking,
                  socially responsible, well rounded individuals.
                </div>
                <div className="heroistic__section__heading__para">
                  At LGS we aim to foster that kind of independence and
                  self-belief. We encourage our young children to learn by doing
                  and to discover things for themselves. We appreciate
                  children’s creativity and are not looking for a perfect
                  product. We look at the process of learning and making
                  connections; this is what we feel has real value.
                </div>
              </div>
              <img
                src={schoolPic}
                alt="schoolPic"
                className="cannal__campus__senior__container__card__left__img"
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
