import React, { useState, useEffect } from "react";
import main_video from "../assets/main_video.mp4";
import schoolPic from "../assets/schoolPic.png";
import aboutPic from "../assets/aboutPic.png";
import campusPic from "../assets/campusPic.png";
import communityServiceImg from "../assets/comunityService.png";
import internationalExposure from "../assets/internationalExposure.png";
import publicSpeaking from "../assets/publicSpeaking.png";
import DRAMA from "../assets/DRAMA.png";
import SPORTS from "../assets/SPORTS.png";
import MEDIA from "../assets/MEDIA.png";
import tech from "../assets/tech.png";
import FORM from "../assets/FORM.png";
import glits from "../assets/glits.jpeg";
import Colorfestival from "../assets/Colorfestival.jpeg";
import springbreak from "../assets/springbreak.jpeg";
import alevelplacement from "../assets/alevelplacement.jpeg";
import internationplacement from "../assets/internationplacement.jpeg";
import harasmentPic from "../assets/harasmentPic.jpeg";
import HarassmentandBullyingSafeguardingPolicy from "../assets/HarassmentandBullyingSafeguardingPolicy.pdf";
import LGSSHBPolicySummary from "../assets/LGSSHBPolicySummary.pdf";
import LGSStatementAH from "../assets/LGSStatementAH.pdf";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  getLatestNews,
  admissionDates,
  getNewsEvents,
  getPolicies,
} from "../APIs/Repos";
import moment from "moment";

function EventCard({ title, img, to, state }) {
  const navigate = useNavigate();
  return (
    <div
      className="events__main__container__content__card"
      onClick={() => {
        navigate(to, { state: { eventDataToPass: state } });
      }}
    >
      <img
        src={process.env.REACT_APP_API_URL + img}
        alt="eventPic"
        className="events__main__container__content__card__img"
      />
      <div className="events__main__container__content__card__overlay">
        {title}
      </div>
    </div>
  );
}

function CampusLifeCard({ title, subtitle, img, href }) {
  return (
    <Link
      to="/campus-life"
      onClick={() => {
        setTimeout(() => {
          document.getElementById(href).scrollIntoView();
        }, 100);
      }}
      className="campus__life__main__container__content__card"
    >
      <img
        src={img ? img : campusPic}
        alt="campusPic"
        className="campus__life__main__container__content__card__img"
      />
      <div className="campus__life__main__container__content__card__overlay">
        <div className="campus__life__main__container__content__card__overlay__heading">
          {title}
          <span>{subtitle}</span>
        </div>
      </div>
    </Link>
  );
}

function NavLink({ title, selected, setSelected }) {
  return (
    <button
      onClick={() => {
        setSelected(title);
      }}
      className={
        selected === title
          ? "about__main__container__content__right__nav__selected about__main__container__content__right__nav"
          : "about__main__container__content__right__nav"
      }
    >
      {title}
    </button>
  );
}

export default function Home() {
  //#region Variables
  const [selected, setSelected] = useState("Middle School");
  let [latestNewsData, setLatestNewsData] = useState([]);
  var [postingDate, setPostingDate] = useState("");
  var [description, setDescription] = useState("");

  let [admissionDatesData, setAdmissionDatesData] = useState([]);
  const [earlyAdmissionTestDate, setEarlyAdmissionTestDate] = useState("");
  const [regularAdmissionTest1Date, setRegularAdmissionTest1Date] =
    useState("");
  const [regularAdmissionTest2Date, setRegularAdmissionTest2Date] =
    useState("");

  let [newsAndEventsList, setNewsAndEventsList] = useState([]);

  var [policiesData, setPoliciesData] = useState([]);
  const [harassmentPolicy, setHarassmentPolicy] = useState("");
  const [harassmentPolicyFiles, setHarassmentPolicyFiles] = useState([]);

  const [noOfSlidesPerView, setNoOfSlidesPerView] = useState(5);

  var today = new Date();

  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  //#endregion

  //#region other functions
  useEffect(() => {
    getLatestNewsApiCall();
    admissionDatesApiCall();
    getNewsEventsAPiCall();
    getPoliciesApiCall();

    if (window.innerWidth < 1130) setNoOfSlidesPerView(3);
    else if (window.innerWidth <= 900) setNoOfSlidesPerView(2);
    else setNoOfSlidesPerView(5);

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1130) setNoOfSlidesPerView(3);
      else if (window.innerWidth <= 900) setNoOfSlidesPerView(2);
      else setNoOfSlidesPerView(5);
    });
  }, []);
  //#endregion

  //#region API Calls
  const getLatestNewsApiCall = () => {
    let newArray = [];
    getLatestNews()
      .then((res) => {
        if (res.data.success) {
          setLatestNewsData((latestNewsData = res.data.result));
          for (let index = 0; index < 2; index++) {
            const element = latestNewsData[index];
            if (element.is_featured == true) {
              setPostingDate((postingDate = element.postingDate));
              setDescription((description = element.description));
              newArray.push(element);
            }
          }
          setLatestNewsData((latestNewsData = newArray));
        }
        console.log("getLatestNews Response", res.data.result);
      })
      .catch((error) => {
        console.log("getLatestNews Error", error);
      });
  };

  const admissionDatesApiCall = () => {
    admissionDates()
      .then((res) => {
        if (res.data.success) {
          setAdmissionDatesData((admissionDatesData = res.data.result));
          for (let index = 0; index < admissionDatesData.length; index++) {
            const element = admissionDatesData[index];
            if (element.grade_type == "Middle School") {
              setEarlyAdmissionTestDate(element.early_admission_test_date);
              setRegularAdmissionTest1Date(
                element.regular_admission_test_1_date
              );
              setRegularAdmissionTest2Date(
                element.regular_admission_test_2_date
              );
            }
          }
        }
        console.log("admissionDates Response", res.data.result);
      })
      .catch((error) => {
        console.log("admissionDates Error", error);
      });
  };

  const getNewsEventsAPiCall = () => {
    getNewsEvents()
      .then((res) => {
        if (res.data.success) {
          setNewsAndEventsList((newsAndEventsList = res.data.result));
          var reversedArray = newsAndEventsList.reverse();
          setNewsAndEventsList(reversedArray);
        } else {
          console.log("getNewsEvents Message", res.data.message);
        }
        console.log("getNewsEvents Response", res.data.result);
      })
      .catch((error) => {
        console.log("getNewsEvents Error", error);
      });
  };

  const getPoliciesApiCall = () => {
    getPolicies()
      .then((res) => {
        if (res.data.success) {
          setPoliciesData((policiesData = res.data.result));
          for (let index = 0; index < policiesData.length; index++) {
            const element = policiesData[index];
            if (element.title == "Harassments Policy")
              setHarassmentPolicy(element.paragraph);
            setHarassmentPolicyFiles(element.files);
          }
        } else {
          console.log("getPolicies MESSAGE", res.data.message);
        }
        console.log("getPolicies Response", res.data.result);
      })
      .catch((error) => {
        console.log("getPolicies ERROR", error);
      });
  };

  //#endregion
  return (
    <>
      <div className="home__main__container">
        <video
          src={main_video}
          alt="main_video"
          autoPlay={"autoplay"}
          muted={true}
          loop={true}
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="home__main__container__overlay__content">
            <div className="home__main__container__overlay__content__heading">
              <span>Welcome To</span>
              LAHORE GRAMMAR SCHOOL
              <div className="home__main__container__overlay__content__heading__buttom__text">
                Faisalabad Campus
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="events__main__container">
        <div className="events__main__container__heading">News & Events</div>
        <div className="events__main__container__content">
          <Swiper slidesPerView={noOfSlidesPerView} spaceBetween={30}>
            {newsAndEventsList != null && newsAndEventsList.length > 0
              ? newsAndEventsList.map((data) => (
                  <SwiperSlide>
                    <EventCard
                      title={data.title}
                      img={data.image}
                      to="/event_detail"
                      state={data}
                    />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
          {/* <EventCard
            title="Spring break"
            img={springbreak}
            to="/event_detail"
          /> */}
          {/* <EventCard
            title="A levels farewell"
            img={internationplacement}
            to="/event_detail"
          />
          <EventCard
            title="Color festival"
            img={Colorfestival}
            to="/event_detail"
          />
          <EventCard
            title="International placements"
            img={alevelplacement}
            to="/event_detail"
          />
          <EventCard title="Glits" img={glits} to="/event_detail" /> */}
        </div>
      </div>
      <div className="news__main__section">
        <div className="news__main__section__content">
          {/* <div className="news__main__section__header">
            <div className="news__main__section__heading">LATEST NEWS</div>
            <div className="news__main__section__date">
              {moment(postingDate).format("DD MMM YYYY")}
            </div>
          </div>
          {latestNewsData != null && latestNewsData.length > 0
            ? latestNewsData.map((data) => (
                <div
                  className="news__main__section__paragraph"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                >
                   {latestNewsData[0].description} 
                </div>
              ))
            : null} */}
          <Swiper>
            {latestNewsData != null && latestNewsData.length > 0
              ? latestNewsData.map((data) => (
                  <SwiperSlide>
                    <div
                      className="news__main__section__content"
                      style={{
                        backgroundColor: "transparent",
                        padding: "0em",
                        border: "none",
                      }}
                    >
                      <div className="news__main__section__header">
                        <div className="news__main__section__heading">
                          LATEST NEWS
                        </div>
                        <div className="news__main__section__date">
                          {data.posting_date
                            ? moment(data.posting_date).format("DD MMM YYYY")
                            : moment(date).format("DD MMM YYYY")}
                        </div>
                      </div>
                      <div
                        className="news__main__section__paragraph"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      >
                        {/* Spring Break from 25th March to 4th April, 2022. */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </div>

      <div className="about__main__container">
        <div className="about__main__container__content">
          <img
            src={aboutPic}
            alt="aboutPic"
            className="about__main__container__content__img"
          />
          <div className="about__main__container__content__right">
            <div className="about__main__container__content__right__heading">
              ABOUT LGS
            </div>
            <div className="about__main__container__content__right__nav__wrapper">
              <NavLink
                title="Middle School"
                selected={selected}
                setSelected={setSelected}
              />
              <NavLink
                title="Senior School"
                selected={selected}
                setSelected={setSelected}
              />
              <NavLink
                title="A-Levels"
                selected={selected}
                setSelected={setSelected}
              />
            </div>
            {selected === "Middle School" ? (
              <>
                <div className="about__main__container__content__right__paragraph">
                  In middle school we aim at: Providing the students with skills
                  for coping with everyday pressure. Encouraging them to respect
                  differences among their peers. Stimulating a career awareness
                  by developing decision_ making skills in them. Providing them
                  the opportunity to learn & utilize independent study skills.
                  Recognizing that reading, writing, and computing are skills
                  basic to success in our society and that the teaching of them
                  is not restricted to anyone but a shared responsibility of
                  both teachers & the students. Assisting the development of
                  academic, social, aesthetic, emotional and physical skills.
                  Above all we encourage them to become increasingly
                  self-directing, moving from dependent childhood to
                  self-actuating adolescence to be a responsible, respectful &
                  mature members of their class, school and society.
                </div>
                {/* <div className="about__main__container__content__right__enrollment__wrapper">
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      433
                    </div>
                    <span>Enrollments</span>
                    <span>2021</span>
                  </div>
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      42
                    </div>
                    <span>Enrollments</span>
                    <span>2020</span>
                  </div>
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      123
                    </div>
                    <span>Enrollments</span>
                    <span>2019</span>
                  </div>
                </div> */}
              </>
            ) : null}
            {selected === "Senior School" ? (
              <>
                <div className="about__main__container__content__right__paragraph">
                  In Senior School we aim to challenge our students, knowing
                  they will rise to the occasion. We aspire to provide as many
                  opportunities as possible to explore, make choices and learn
                  about themselves and each other. We want them to learn to
                  inquire, reflect and discuss; to think critically, ask
                  meaningful questions, listen with an open mind and to learn
                  the skills needed to find solutions and solve problems for
                  themselves. Above all else, we want them to enjoy themselves
                  and to be involved, responsible, respectful citizens of their
                  class, school and society.
                </div>
                {/* <div className="about__main__container__content__right__enrollment__wrapper">
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      474
                    </div>
                    <span>Enrollments</span>
                    <span>2021</span>
                  </div>
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      145
                    </div>
                    <span>Enrollments</span>
                    <span>2020</span>
                  </div>
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      73
                    </div>
                    <span>Enrollments</span>
                    <span>2019</span>
                  </div>
                </div> */}
              </>
            ) : null}
            {selected === "A-Levels" ? (
              <>
                <div className="about__main__container__content__right__paragraph">
                  A warm welcome to LGS A-Level Section “Education, knowledge
                  and learning are mutual responsibilities amongst devoted
                  teachers, motivated students and zealous parents with high
                  expectations.” The role of an academic institution is not only
                  to pursue academic excellence but also to motivate and empower
                  its students to be lifelong learners, critical thinkers, and
                  productive members of an ever-changing global society. At LGS,
                  we provide an atmosphere to our students for heterogeneous
                  development, where students are encouraged to channelize their
                  true potential. This can only be possible in a comprehensive,
                  student centric environment. The abilities, skills and the
                  achievements need to be nurtured and encouraged so that the
                  student is able to attain the heights of achievements. LGS- A
                  Level provides them opportunities to exhibit and express their
                  skills other than providing them with good education. The
                  school is diligently working to make the best possible efforts
                  to inculcate strong values combined with academics in the
                  students. At the Intellect, academic excellence is a major
                  thrust and because of this we have put a great deal of effort
                  in hiring the best teachers for our students.
                </div>
                {/* <div className="about__main__container__content__right__enrollment__wrapper">
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      421
                    </div>
                    <span>Enrollments</span>
                    <span>2021</span>
                  </div>
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      142
                    </div>
                    <span>Enrollments</span>
                    <span>2020</span>
                  </div>
                  <div className="about__main__container__content__right__enrollment">
                    <div className="about__main__container__content__right__enrollment__title">
                      521
                    </div>
                    <span>Enrollments</span>
                    <span>2019</span>
                  </div>
                </div> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="campus__life__main__container">
        <div className="campus__life__main__container__heading">
          CAMPUS LIFE
        </div>
        <div className="campus__life__main__container__content">
          <CampusLifeCard
            img={internationalExposure}
            title="International"
            subtitle="Exposure"
            href="international"
          />
          <CampusLifeCard
            img={publicSpeaking}
            title="Public"
            subtitle="Speaking"
            href="public"
          />
          <CampusLifeCard
            img={communityServiceImg}
            title="Community Service"
            subtitle="And The Enviroment"
            href="community"
          />
          <CampusLifeCard
            img={tech}
            title="Science and"
            subtitle="Technology"
            href="science"
          />
          <CampusLifeCard
            img={DRAMA}
            title="Drama, Music,"
            subtitle="And Languages"
            href="drama"
          />
          <CampusLifeCard img={SPORTS} title="Sports" href="sports" />
          <CampusLifeCard
            img={MEDIA}
            title="Qalamkaar"
            subtitle=""
            href="qalamkaar"
          />
        </div>
      </div>
      <div className="addmission__main__container">
        <div className="addmission__main__container__content__wrapper__headings__wrapper">
          <div className="addmission__main__container__content__wrapper__heading">
            ADMISSIONS
          </div>
          <div className="addmission__main__container__content__wrapper__paragraph">
            Could LGS FSD be right for you? We seek to admit optimistic,
            energetic, and engaged students who are ready to take advantage of
            the world of endless opportunities and challenges we provide to our
            students. LGS FSD offers a rigorous yet flexible curriculum enriched
            by a wide variety of interdisciplinary courses and activities.
          </div>
          <div className="addmission__main__container__content__wrapper__paragraph">
            To apply to our branch, parents must visit the school with all
            required documents to complete the Admission Application Form. If we
            think you are the right fit, the School Office will get in touch
            with you regarding relevant Admission Tests.
          </div>
        </div>
        <div className="addmission__main__container__content">
          <img
            src={FORM}
            alt="addmission_bg"
            className="addmission__main__container__content__img"
          />
          <div className="addmission__main__container__content__overlay__new">
            <div className="addmission__main__container__content__overlay__content__new">
              <div className="addmission__main__container__content__overlay__content__new__enrollment__wrapper">
                <div className="addmission__main__container__content__overlay__content__new__enrollment">
                  {moment(earlyAdmissionTestDate).format("DD MMM")}
                  <span>Early Admission</span>
                  <span>Test</span>
                </div>
                <div className="addmission__main__container__content__overlay__content__new__enrollment">
                  {moment(regularAdmissionTest1Date).format("DD MMM")}
                  <span>Regular Admission</span>
                  <span>Test I</span>
                </div>
                <div className="addmission__main__container__content__overlay__content__new__enrollment">
                  {moment(regularAdmissionTest2Date).format("DD MMM")}
                  <span>Regular Admission</span>
                  <span>Test II</span>
                </div>
              </div>
            </div>

            <div className="addmission__main__container__content__wrapper"></div>
            <div className="addmission__main__container__content__wrapper__headings__container">
              <div className="addmission__main__container__content__wrapper__heading">
                ADMISSIONS
              </div>
              <div className="addmission__main__container__content__wrapper__paragraph">
                Could LGS FSD be right for you? We seek to admit optimistic,
                energetic, and engaged students who are ready to take advantage
                of the world of endless opportunities and challenges we provide
                to our students. LGS FSD offers a rigorous yet flexible
                curriculum enriched by a wide variety of interdisciplinary
                courses and activities.
              </div>
              <div className="addmission__main__container__content__wrapper__paragraph">
                To apply to our branch, parents must visit the school with all
                required documents to complete the Admission Application Form.
                If we think you are the right fit, the School Office will get in
                touch with you regarding relevant Admission Tests.
              </div>
            </div>
          </div>
        </div>
        <div className="haramsment__section__wrapper__content">
          <img
            src={harasmentPic}
            alt="harasmentPic"
            className="haramsment__section__wrapper__content__img"
          />
          <div className="haramsment__section__wrapper__content__overlay">
            <div className="haramsment__section__wrapper__content__overlay__content">
              <div
                style={{ color: "#fff", marginBottom: "0.3em", fontSize: 30 }}
                className="our__school__main__container__heading"
              >
                Harassment Policy
              </div>
              <div
                className="haramsment__section__wrapper__content__para"
                dangerouslySetInnerHTML={{ __html: harassmentPolicy }}
              >
                {/* Lahore Grammar School (“LGS”) is committed to establishing and
                maintaining a working and learning environment that is free from
                harassment and bullying of any kind; creating a working and
                learning environment where all members are treated with
                courtesy, dignity and respect, providing an effective complaints
                procedure wherein all complaints are treated in a serious,
                sensitive, fair, timely and confidential manner; and
                guaranteeing against victimization or reprisals. */}
              </div>
              <div
                className="haramsment__section__wrapper__content__para"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  marginTop: 20,
                }}
              >
                {harassmentPolicyFiles != null &&
                harassmentPolicyFiles.length > 0
                  ? harassmentPolicyFiles.map((data) => (
                      <a
                        href={process.env.REACT_APP_API_URL + data.file_path}
                        target="_blank"
                      >
                        {data.file_name}
                      </a>
                    ))
                  : null}
                {/* <a href={LGSSHBPolicySummary} target="_blank">
                  LGS SHB Policy Summary
                </a>
                <a href={LGSStatementAH} target="_blank">
                  LGS Statement AH
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className="our__school__main__container__left">
<div className="our__school__main__container__heading">
  Harassment Policy
</div>
<div className="our__school__main__container__paragragh">
  Lahore Grammar School (“LGS”) is committed to establishing and
  maintaining a working and learning environment that is free from
  harassment and bullying of any kind; creating a working and
  learning environment where all members are treated with courtesy,
  dignity and respect, providing an effective complaints procedure
  wherein all complaints are treated in a serious, sensitive, fair,
  timely and confidential manner; and guaranteeing against
  victimization or reprisals.
</div>
<div className="addmission__requirment__section__content__right__para">
  <li>
    <a
      href={HarassmentandBullyingSafeguardingPolicy}
      style={{
        color: "#242424",
      }}
      target="_blank"
    >
      Harassment and Bullying Safeguarding Policy
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-link"
        style={{ marginLeft: ".5em", marginBottom: "-.15em" }}
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
      </svg>
    </a>
  </li>
  <li>
    <a
      href={LGSSHBPolicySummary}
      style={{
        color: "#242424",
      }}
      target="_blank"
    >
      LGS SHB Policy Summary
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-link"
        style={{ marginLeft: ".5em", marginBottom: "-.15em" }}
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
      </svg>
    </a>
  </li>
  <li>
    <a
      href={LGSStatementAH}
      style={{
        color: "#242424",
      }}
      target="_blank"
    >
      LGS Statement AH
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-link"
        style={{ marginLeft: ".5em", marginBottom: "-.15em" }}
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
      </svg>
    </a>
  </li>
</div>
</div> */
}
