import React, { useState } from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import InputField from "../component/InputField";
import InputFile from "../component/InputFile";
import InputRadio from "../component/InputRadio";
import InputTextArea from "../component/InputTextArea";
import SelectBox from "../component/SelectBox";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { careerRegisteration } from "../APIs/Repos";
import { X } from "react-feather";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { VALIDATION_REGEX } from "../Constants";
toast.configure();

const genderOption = [
  {
    label: "Male",
  },
  {
    label: "Female",
  },
];

function Upload({ onUploadingImageCallBack }) {
  //#region Variables
  var [image, setImage] = useState("");
  //#endregion

  //#region other functions
  const onImageUpload = (e) => {
    const fileType = e.target.files[0];
    if (fileType.type == "application/pdf") {
      setImage((image = e.target.files[0]));
      onUploadingImageCallBack(image);
    } else alert("Upload PDF files");
  };

  const onCrossButtonClickListener = () => {
    setImage((image = ""));
    onUploadingImageCallBack(image);
  };
  //#endregion
  return (
    <div className="start__up__container__form__input__box__content__upload__wrapper">
      <div className="start__up__container__form__input__box__content__upload">
        <input
          type="file"
          className="start__up__container__form__input__box__content__upload__btn"
          onClick={(event) => (event.target.value = null)}
          onChange={(e) => {
            onImageUpload(e);
          }}
        />
        Choose File
      </div>
      {image === "" ? null : (
        <div className="start__up__container__form__input__box__content__upload__uploaded">
          <button
            className="start__up__container__form__input__box__content__upload__uploaded__close"
            onClick={() => {
              onCrossButtonClickListener();
            }}
          >
            <X size={15} color="currentColor" />
          </button>
          <iframe
            src={URL.createObjectURL(image)}
            alt={image}
            className="start__up__container__form__input__box__content__upload__uploaded__img"
          />
        </div>
      )}
    </div>
  );
}

export default function CareerRegistration() {
  //#region Variables
  const location = useLocation();
  var [name, setName] = useState("");
  const [isNameRequired, setIsNameRequired] = useState(true);

  var [email, setEmail] = useState("");
  const [isEmailRequired, setIsEmailRequired] = useState(true);

  var [phone, setPhone] = useState("");
  const [isPhoneRequired, setIsPhoneRequired] = useState(true);

  var [city, setCity] = useState("");
  const [isCityRequired, setIsCityRequired] = useState(true);

  var [address, setAddress] = useState("");
  const [isAddressRequired, setIsAddressRequired] = useState(true);

  var [gender, setGender] = useState("");
  const [isGenderRequired, setIsGenderRequired] = useState(true);

  var [nameOfSchool, setNameOfSchool] = useState("");
  const [isNameOfSchoolRequired, setIsNameOfSchoolRequired] = useState(true);

  var [classesAttended, setClassesAttended] = useState("");
  const [isClassesAttendedRequired, setIsClassesAttendedRequired] =
    useState(true);

  var [yearsAttended, setYearsAttended] = useState("");
  const [isYearsAttendedRequired, setIsYearsAttendedRequired] = useState(true);

  var [schoolYearFrom, setSchoolYearFrom] = useState("");
  const [isYearsFromAttendedRequired, setIsYearsFromAttendedRequired] =
    useState(true);

  var [schoolYearTo, setSchoolYearTo] = useState("");
  const [isYearsToAttendedRequired, setIsYearsToAttendedRequired] =
    useState(true);

  var [degreeAttained, setDegreeAttained] = useState("");
  const [isDegreeAttainedRequired, setIsDegreeAttainedRequired] =
    useState(true);

  var [nameOfCollege, setNameOfCollege] = useState("");
  const [isNameOfCollegeRequired, setIsNameOfCollegeRequired] = useState(true);

  var [collegeYearsAttended, setCollegeYearsAttended] = useState("");
  const [isCollegeYearsAttendedRequired, setIsCollegeYearsAttendedRequired] =
    useState(true);

  var [collegeYearFrom, setCollegeYearFrom] = useState("");
  const [
    isCollegeYearsFromAttendedRequired,
    setIsCollegeYearsFromAttendedRequired,
  ] = useState(true);

  var [collegeYearTo, setCollegeYearTo] = useState("");
  const [
    isCollegeYearsToAttendedRequired,
    setIsCollegeYearsToAttendedRequired,
  ] = useState(true);

  var [experienceInstituteName, setExperienceInstituteName] = useState("");
  const [
    isExperienceInstituteNameRequired,
    setIsExperienceInstituteNameRequired,
  ] = useState(true);

  var [instituteAddress, setInstituteAddress] = useState("");
  const [isInstituteAddressRequired, setIsInstituteAddressRequired] =
    useState(true);

  var [dateOfEmployment, setDateOfEmployment] = useState("");
  const [isDateOfEmployentRequired, setIsDateOfEmploymentRequired] =
    useState(true);

  var [leavingReason, setLeavingReason] = useState("");
  const [isLeavingReasonRequired, setIsLeavingReasonRequired] = useState(true);

  var [subjectToTeach, setSubjectToTeach] = useState("");
  const [isSubjectToTeachRequired, setIsSubjectToTeachRequired] =
    useState(true);

  var [levelToTeach, setLevelToTeach] = useState("");
  const [isLevelToTeachRequired, setIsLevelToTeachRequired] = useState(true);

  var [additionalInformation, setAdditionalInformation] = useState("");

  var [profileImage, setProfileImage] = useState("");
  var [coverLetter, setCoverLetter] = useState("");
  var [cv, setCv] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  //#endregion

  //#region API Call

  const careerRegisterationAPiCall = () => {
    let data = {
      id: 0,
      name: name,
      email: email,
      phone: phone,
      city: city,
      address: address,
      gender: gender,
      picture_path: "",
      school_name: nameOfSchool,
      school_attended_classes: classesAttended,
      school_years_from: schoolYearFrom,
      school_years_to: schoolYearTo,
      college_degree_attained: degreeAttained,
      college_institiution_name: nameOfCollege,
      college_years_from: collegeYearFrom,
      college_years_to: collegeYearTo,
      experience_institiution: experienceInstituteName,
      experience_address: instituteAddress,
      experience_employment_date: dateOfEmployment,
      experience_reason_for_leaving: leavingReason,
      subject_to_teach: subjectToTeach,
      level_to_teach: levelToTeach,
      additional_information: additionalInformation,
      campus_name:
        location.state && location.state.vacancyDataToRegister
          ? location.state.vacancyDataToRegister.campus_name
          : "",
      designation:
        location.state && location.state.vacancyDataToRegister
          ? location.state.vacancyDataToRegister.title
          : "",
      cover_letter_path: "",
      cv_path: "",
    };

    var bodyFormData = new FormData();
    bodyFormData.append("model", JSON.stringify(data));
    if (profileImage)
      bodyFormData.append("picture", profileImage, profileImage.name);
    if (coverLetter)
      bodyFormData.append("cover_letter", coverLetter, coverLetter.name);
    if (cv) bodyFormData.append("cv", cv, cv.name);
    if (isViewValid()) {
      setIsLoading(true);
      careerRegisteration(bodyFormData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            window.location.reload();
            toast("Registered Successfully!");
            resetStatesAfterSuccessfullRegisteration();
          } else {
            alert("Ops! Unable to register, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert(error);
        });
    }
  };

  //#endregion

  //#region ReSet States

  const resetStatesAfterSuccessfullRegisteration = () => {
    setName("");
    setEmail("");
    setPhone("");
    setCity("");
    setAddress("");
    setGender("");
    setProfileImage("");
    setNameOfSchool("");
    setYearsAttended("");
    setSchoolYearFrom("");
    setSchoolYearTo("");
    setClassesAttended("");
    setNameOfCollege("");
    setDegreeAttained("");
    setCollegeYearsAttended("");
    setCollegeYearFrom("");
    setCollegeYearTo("");
    setExperienceInstituteName("");
    setInstituteAddress("");
    setDateOfEmployment("");
    setLeavingReason("");
    setSubjectToTeach("");
    setLevelToTeach("");
    setCoverLetter("");
    setCv("");
  };

  //#endregion

  //#region Name Validation

  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorAndVisibilityAndIsValid(name);
  };

  const setNameErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setNameErrorAndVisibility(true);
    else {
      setNameErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameErrorAndVisibility = (visibility) => {
    setIsNameRequired(visibility);
  };
  //#endregion

  //#region Email Validation

  const onEmailTextChangeListener = (event) => {
    setEmail((email = event.currentTarget.value));
    setEmailErrorAndVisibilityAndIsValid(email);
  };

  const setEmailErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setEmailErrorAndVisibility(true);
    else if (!VALIDATION_REGEX.EMAIL_REGEX.test(enterdText))
      setEmailErrorAndVisibility(true);
    else {
      setEmailErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setEmailErrorAndVisibility = (visibility) => {
    setIsEmailRequired(visibility);
  };
  //#endregion

  //#region Phone Validation

  const onPhoneTextChangeListener = (event) => {
    setPhone((phone = event.currentTarget.value));
    setPhoneErrorAndVisibilityAndIsValid(phone);
  };

  const setPhoneErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setPhoneErrorAndVisibility(true);
    else if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(enterdText))
      setPhoneErrorAndVisibility(true);
    else {
      setPhoneErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPhoneErrorAndVisibility = (visibility) => {
    setIsPhoneRequired(visibility);
  };
  //#endregion

  //#region City Validation

  const onCityTextChangeListener = (event) => {
    setCity((city = event.currentTarget.value));
    setCityErrorAndVisibilityAndIsValid(city);
  };

  const setCityErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setCityErrorAndVisibility(true);
    else {
      setCityErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setCityErrorAndVisibility = (visibility) => {
    setIsCityRequired(visibility);
  };
  //#endregion

  //#region Address Validation

  const onAddressTextChangeListener = (event) => {
    setAddress((address = event.currentTarget.value));
    setAddressErrorAndVisibilityAndIsValid(address);
  };

  const setAddressErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setAddressErrorAndVisibility(true);
    else {
      setAddressErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setAddressErrorAndVisibility = (visibility) => {
    setIsAddressRequired(visibility);
  };
  //#endregion

  //#region Gender Validation

  const onGenderTextChangeListener = (event) => {
    setGender((gender = event.currentTarget.value));
    setGenderErrorAndVisibilityAndIsValid(gender);
  };

  const setGenderErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setGenderErrorAndVisibility(true);
    else {
      setGenderErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setGenderErrorAndVisibility = (visibility) => {
    setIsGenderRequired(visibility);
  };
  //#endregion

  //#region Name Of School Validation

  const onNameOfSchoolTextChangeListener = (event) => {
    setNameOfSchool((nameOfSchool = event.currentTarget.value));
    setNameOfSchoolErrorAndVisibilityAndIsValid(nameOfSchool);
  };

  const setNameOfSchoolErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setNameOfSchoolErrorAndVisibility(true);
    else {
      setNameOfSchoolErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameOfSchoolErrorAndVisibility = (visibility) => {
    setIsNameOfSchoolRequired(visibility);
  };
  //#endregion

  //#region Classes Attended Validation

  const onClassesAttendedTextChangeListener = (event) => {
    setClassesAttended((classesAttended = event.currentTarget.value));
    setClassesAttendedErrorAndVisibilityAndIsValid(classesAttended);
  };

  const setClassesAttendedErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setClassesAttendedErrorAndVisibility(true);
    else {
      setClassesAttendedErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setClassesAttendedErrorAndVisibility = (visibility) => {
    setIsClassesAttendedRequired(visibility);
  };
  //#endregion

  //#region School Years From Attended Validation

  const onYearFromAttendedTextChangeListener = (event) => {
    setSchoolYearFrom((schoolYearFrom = event.currentTarget.value));
    setYearsFromAttendedErrorAndVisibilityAndIsValid(schoolYearFrom);
  };

  const setYearsFromAttendedErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setYearsFromAttendedErrorAndVisibility(true);
    else {
      setYearsFromAttendedErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setYearsFromAttendedErrorAndVisibility = (visibility) => {
    setIsYearsFromAttendedRequired(visibility);
  };
  //#endregion

  //#region School Years To Attended Validation

  const onYearToAttendedTextChangeListener = (event) => {
    setSchoolYearTo((schoolYearTo = event.currentTarget.value));
    setYearsToAttendedErrorAndVisibilityAndIsValid(schoolYearTo);
  };

  const setYearsToAttendedErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setYearsToAttendedErrorAndVisibility(true);
    else {
      setYearsToAttendedErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setYearsToAttendedErrorAndVisibility = (visibility) => {
    setIsYearsToAttendedRequired(visibility);
  };
  //#endregion

  //#region Degree Attained Validation

  const onDegreeAttainedTextChangeListener = (event) => {
    setDegreeAttained((degreeAttained = event.currentTarget.value));
    setDegreeAttainedErrorAndVisibilityAndIsValid(degreeAttained);
  };

  const setDegreeAttainedErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setDegreeAttainedErrorAndVisibility(true);
    else {
      setDegreeAttainedErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setDegreeAttainedErrorAndVisibility = (visibility) => {
    setIsDegreeAttainedRequired(visibility);
  };
  //#endregion

  //#region Name of College Validation

  const onNameOfCollegeTextChangeListener = (event) => {
    setNameOfCollege((nameOfCollege = event.currentTarget.value));
    setNameOfCollegeErrorAndVisibilityAndIsValid(nameOfCollege);
  };

  const setNameOfCollegeErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setNameOfCollegeErrorAndVisibility(true);
    else {
      setNameOfCollegeErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameOfCollegeErrorAndVisibility = (visibility) => {
    setIsNameOfCollegeRequired(visibility);
  };
  //#endregion

  //#region College years From Attended Validation

  const onCollegeYearFromAttendedTextChangeListener = (event) => {
    setCollegeYearFrom((collegeYearFrom = event.currentTarget.value));
    setCollegeYearsFromAttendedErrorAndVisibilityAndIsValid(collegeYearFrom);
  };

  const setCollegeYearsFromAttendedErrorAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setCollegeYearsFromAttendedErrorAndVisibility(true);
    else {
      setCollegeYearsFromAttendedErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setCollegeYearsFromAttendedErrorAndVisibility = (visibility) => {
    setIsCollegeYearsFromAttendedRequired(visibility);
  };
  //#endregion

  //#region College years To Attended Validation

  const onCollegeYearToAttendedTextChangeListener = (event) => {
    setCollegeYearTo((collegeYearTo = event.currentTarget.value));
    setCollegeYearsToAttendedErrorAndVisibilityAndIsValid(collegeYearTo);
  };

  const setCollegeYearsToAttendedErrorAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setCollegeYearsToAttendedErrorAndVisibility(true);
    else {
      setCollegeYearsToAttendedErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setCollegeYearsToAttendedErrorAndVisibility = (visibility) => {
    setIsCollegeYearsToAttendedRequired(visibility);
  };
  //#endregion

  //#region Experience Institute Validation

  const onExperienceInstituteTextChangeListener = (event) => {
    setExperienceInstituteName(
      (experienceInstituteName = event.currentTarget.value)
    );
    setExperienceInstituteNameErrorAndVisibilityAndIsValid(
      experienceInstituteName
    );
  };

  const setExperienceInstituteNameErrorAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setExperienceInstituteNameErrorAndVisibility(true);
    else {
      setExperienceInstituteNameErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setExperienceInstituteNameErrorAndVisibility = (visibility) => {
    setIsExperienceInstituteNameRequired(visibility);
  };
  //#endregion

  //#region Institute Address Validation

  const onInstituteAddressTextChangeListener = (event) => {
    setInstituteAddress((instituteAddress = event.currentTarget.value));
    setInstituteAddressErrorAndVisibilityAndIsValid(instituteAddress);
  };

  const setInstituteAddressErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setInstituteAddressErrorAndVisibility(true);
    else {
      setInstituteAddressErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setInstituteAddressErrorAndVisibility = (visibility) => {
    setIsInstituteAddressRequired(visibility);
  };
  //#endregion

  //#region Date of Employment Validation

  const onEmploymentDateTextChangeListener = (event) => {
    setDateOfEmployment((dateOfEmployment = event.currentTarget.value));
    setDateOfEmploymentErrorAndVisibilityAndIsValid(dateOfEmployment);
  };

  const setDateOfEmploymentErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setDateOfEmploymentErrorAndVisibility(true);
    else {
      setDateOfEmploymentErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setDateOfEmploymentErrorAndVisibility = (visibility) => {
    setIsDateOfEmploymentRequired(visibility);
  };
  //#endregion

  //#region Reason For Leaving Validation

  const onLeavingReasonTextChangeListener = (event) => {
    setLeavingReason((leavingReason = event.currentTarget.value));
    setLeavingReasonErrorAndVisibilityAndIsValid(leavingReason);
  };

  const setLeavingReasonErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setLeavingReasonErrorAndVisibility(true);
    else {
      setLeavingReasonErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setLeavingReasonErrorAndVisibility = (visibility) => {
    setIsLeavingReasonRequired(visibility);
  };
  //#endregion

  //#region Subjects To Teach Validation

  const onSujectsToTeachTextChangeListener = (event) => {
    setSubjectToTeach((subjectToTeach = event.currentTarget.value));
    setSubjectToTeachErrorAndVisibilityAndIsValid(subjectToTeach);
  };

  const setSubjectToTeachErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setSubjectToTeachErrorAndVisibility(true);
    else {
      setSubjectToTeachErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setSubjectToTeachErrorAndVisibility = (visibility) => {
    setIsSubjectToTeachRequired(visibility);
  };
  //#endregion

  //#region Level To Teach Validation

  const onLevelToTeachTextChangeListener = (event) => {
    setLevelToTeach((levelToTeach = event.currentTarget.value));
    setLevelToTeachErrorAndVisibilityAndIsValid(levelToTeach);
  };

  const setLevelToTeachErrorAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setLevelToTeachErrorAndVisibility(true);
    else {
      setLevelToTeachErrorAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setLevelToTeachErrorAndVisibility = (visibility) => {
    setIsLevelToTeachRequired(visibility);
  };
  //#endregion

  //#region Is View Valid

  const isViewValid = () => {
    var isViewValid = setNameErrorAndVisibilityAndIsValid(name);
    if (isViewValid) isViewValid = setEmailErrorAndVisibilityAndIsValid(email);
    if (isViewValid) isViewValid = setPhoneErrorAndVisibilityAndIsValid(phone);
    if (isViewValid) isViewValid = setCityErrorAndVisibilityAndIsValid(city);
    if (isViewValid)
      isViewValid = setAddressErrorAndVisibilityAndIsValid(address);
    if (isViewValid)
      isViewValid = setGenderErrorAndVisibilityAndIsValid(gender);
    if (isViewValid) {
      if (profileImage == "") isViewValid = false;
      else isViewValid = true;
    }
    if (isViewValid)
      isViewValid = setNameOfSchoolErrorAndVisibilityAndIsValid(nameOfSchool);
    if (isViewValid)
      isViewValid =
        setClassesAttendedErrorAndVisibilityAndIsValid(classesAttended);
    if (isViewValid)
      isViewValid =
        setYearsFromAttendedErrorAndVisibilityAndIsValid(schoolYearFrom);
    if (isViewValid)
      isViewValid =
        setYearsToAttendedErrorAndVisibilityAndIsValid(schoolYearTo);
    if (isViewValid)
      isViewValid =
        setDegreeAttainedErrorAndVisibilityAndIsValid(degreeAttained);
    if (isViewValid)
      isViewValid = setNameOfCollegeErrorAndVisibilityAndIsValid(nameOfCollege);
    if (isViewValid)
      isViewValid =
        setCollegeYearsFromAttendedErrorAndVisibilityAndIsValid(
          collegeYearFrom
        );
    if (isViewValid)
      isViewValid =
        setCollegeYearsToAttendedErrorAndVisibilityAndIsValid(collegeYearTo);
    if (isViewValid)
      isViewValid = setExperienceInstituteNameErrorAndVisibilityAndIsValid(
        experienceInstituteName
      );
    if (isViewValid)
      isViewValid =
        setInstituteAddressErrorAndVisibilityAndIsValid(instituteAddress);
    if (isViewValid)
      isViewValid =
        setDateOfEmploymentErrorAndVisibilityAndIsValid(dateOfEmployment);
    if (isViewValid)
      isViewValid = setLeavingReasonErrorAndVisibilityAndIsValid(leavingReason);
    if (isViewValid)
      isViewValid =
        setSubjectToTeachErrorAndVisibilityAndIsValid(subjectToTeach);
    if (isViewValid)
      isViewValid = setLevelToTeachErrorAndVisibilityAndIsValid(levelToTeach);
    if (isViewValid) {
      if (coverLetter == "") isViewValid = false;
      else isViewValid = true;
    }
    if (isViewValid) {
      if (cv == "") isViewValid = false;
      else isViewValid = true;
    }
    return isViewValid;
  };

  //#endregion

  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              <div
                style={{
                  textAlign: "center",
                }}
                className="our__school__overlay__content__heading"
              >
                Careers Registration
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career__section">
        <div
          className="career__section__content"
          style={{ marginBottom: "5em" }}
        >
          <div className="career__section__content__header">
            <div
              style={{ marginBottom: 20, fontSize: 14 }}
              className="career__section__content__para"
            >
              If you are interested in applying for a job at Lahore Grammar
              School, 55 Main Gulberg, Lahore, please fill in the form below and
              attach your photograph and your resume.
            </div>
          </div>
          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter Name"
              label="Name"
              required={isNameRequired}
              value={name}
              onChange={(event) => onNameTextChangeListener(event)}
            />
            <InputField
              placeholder="Enter Email"
              label="Email"
              type="email"
              required={isEmailRequired}
              value={email}
              onChange={(event) => onEmailTextChangeListener(event)}
            />
          </div>
          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter Phone"
              label="Phone"
              type="tel"
              required={isPhoneRequired}
              value={phone}
              onChange={(event) => {
                if (!isNaN(event.currentTarget.value))
                  onPhoneTextChangeListener(event);
              }}
            />
            <InputField
              placeholder="Enter City"
              label="City"
              required={isCityRequired}
              value={city}
              onChange={(event) => onCityTextChangeListener(event)}
            />
          </div>
          <InputField
            placeholder="Enter Address"
            label="Address"
            required={isAddressRequired}
            value={address}
            onChange={(event) => onAddressTextChangeListener(event)}
          />
          <InputRadio
            options={genderOption}
            placeholder="Gender"
            label="Gender"
            required={isGenderRequired}
            value={gender}
            onChange={(event) => onGenderTextChangeListener(event)}
          />
          <InputFile
            label="Upload Picture"
            required={profileImage ? false : true}
            onUploadingImageCallBack={(image) => {
              if (image) setProfileImage((profileImage = image));
              else setProfileImage("");
            }}
          />

          <div className="input__form__headings">Academic History</div>
          <div className="input__form__sub__header">
            <div className="input__form__sub__heading">Schooling</div>
            {/* <button className="input__form__sub__header__btn">Add</button> */}
          </div>

          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter Name"
              label="Name of School"
              required={isNameOfSchoolRequired}
              value={nameOfSchool}
              onChange={(event) => onNameOfSchoolTextChangeListener(event)}
            />
            <InputField
              placeholder="Enter Classes"
              label="Attended Classes"
              required={isClassesAttendedRequired}
              value={classesAttended}
              onChange={(event) => onClassesAttendedTextChangeListener(event)}
            />
          </div>
          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter year"
              label="Year From"
              required={isYearsFromAttendedRequired}
              type="date"
              value={schoolYearFrom}
              onChange={(event) => onYearFromAttendedTextChangeListener(event)}
            />
            <InputField
              placeholder="Enter year"
              label="Year To"
              required={isYearsToAttendedRequired}
              type="date"
              value={schoolYearTo}
              onChange={(event) => onYearToAttendedTextChangeListener(event)}
            />
          </div>
          <div className="input__form__sub__header">
            <div className="input__form__sub__heading">College/University</div>
            {/* <button className="input__form__sub__header__btn">Add</button> */}
          </div>

          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter title"
              label="Degree/Diploma attained"
              required={isDegreeAttainedRequired}
              value={degreeAttained}
              onChange={(event) => onDegreeAttainedTextChangeListener(event)}
            />
            <InputField
              placeholder="Enter Name"
              label="Name of Institiution"
              required={isNameOfCollegeRequired}
              value={nameOfCollege}
              onChange={(event) => onNameOfCollegeTextChangeListener(event)}
            />
          </div>
          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter years"
              label="Year From"
              required={isCollegeYearsFromAttendedRequired}
              type="date"
              value={collegeYearFrom}
              onChange={(event) =>
                onCollegeYearFromAttendedTextChangeListener(event)
              }
            />
            <InputField
              placeholder="Enter years"
              label="Year To"
              required={isCollegeYearsToAttendedRequired}
              type="date"
              value={collegeYearTo}
              onChange={(event) =>
                onCollegeYearToAttendedTextChangeListener(event)
              }
            />
          </div>
          <div className="input__form__sub__header">
            <div className="input__form__sub__heading">Experience</div>
            {/* <button className="input__form__sub__header__btn">Add</button> */}
          </div>
          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter Name"
              label="Institution"
              required={isExperienceInstituteNameRequired}
              value={experienceInstituteName}
              onChange={(event) =>
                onExperienceInstituteTextChangeListener(event)
              }
            />
            <InputField
              placeholder="Enter Address"
              label="Address"
              required={isInstituteAddressRequired}
              value={instituteAddress}
              onChange={(event) => onInstituteAddressTextChangeListener(event)}
            />
          </div>
          <div className="career__section__content__input__row">
            <InputField
              placeholder="Enter Date"
              label="Date of Employment"
              required={isDateOfEmployentRequired}
              type="date"
              value={dateOfEmployment}
              onChange={(event) => onEmploymentDateTextChangeListener(event)}
            />
            <InputField
              placeholder="Enter reason"
              label="Reason for Leaving"
              required={isLeavingReasonRequired}
              value={leavingReason}
              onChange={(event) => onLeavingReasonTextChangeListener(event)}
            />
          </div>
          <InputField
            placeholder="Enter subjects"
            label="What subject would you like to teach?"
            required={isSubjectToTeachRequired}
            value={subjectToTeach}
            onChange={(event) => onSujectsToTeachTextChangeListener(event)}
          />
          <SelectBox
            required={isLevelToTeachRequired}
            placeholder="Select choice"
            label="At what level would you like to teach?"
            value={levelToTeach}
            onChange={(event) => onLevelToTeachTextChangeListener(event)}
          />
          <InputTextArea
            placeholder="Enter Details"
            label="Additional Information:"
            // required={isAdd}
            value={additionalInformation}
            onChange={(event) =>
              setAdditionalInformation(event.currentTarget.value)
            }
          />
          {/* <div
            style={{ width: "30%" }}
            className="career__section__content__input__row"
          >
            <InputFile
              label="Cover Letter"
              required={coverLetter ? false : true}
              onUploadingImageCallBack={(image) => {
                console.log("image", image);
                setCoverLetter((coverLetter = image));
              }}
            />
            <InputFile
              label="Upload CV"
              required={cv ? false : true}
              onUploadingImageCallBack={(image) => {
                console.log("image", image);
                setCv((cv = image));
              }}
            /> */}
          <div className="career__section__content__input__row">
            <div className="start__up__container__form__input__box">
              <div className="start__up__container__form__input__box__label">
                Upload Cover Letter
                <div className="start__up__container__form__input__box__label__required">
                  {coverLetter ? null : "*"}
                </div>
              </div>
              <div
                className="start__up__container__form__input__box__content"
                style={{ marginTop: ".5em" }}
              >
                <Upload
                  onUploadingImageCallBack={(image) => {
                    if (image) setCoverLetter((coverLetter = image));
                    else setCoverLetter("");
                  }}
                />
              </div>
            </div>
            <div className="start__up__container__form__input__box">
              <div className="start__up__container__form__input__box__label">
                Upload CV
                <div className="start__up__container__form__input__box__label__required">
                  {cv ? null : "*"}
                </div>
              </div>
              <div
                className="start__up__container__form__input__box__content"
                style={{ marginTop: ".5em" }}
              >
                <Upload
                  onUploadingImageCallBack={(image) => {
                    if (image) setCv((cv = image));
                    else setCv("");
                  }}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="input__form__btn__wrapper">
            <button
              className="input__form__btn"
              onClick={() => {
                if (isLoading == false) careerRegisterationAPiCall();
              }}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
