import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import sportsGallery1 from "../assets/sportsGallery1.png";
import sportsGallery2 from "../assets/sportsGallery2.png";
import sportsGallery3 from "../assets/sportsGallery3.png";
import sportsGallery4 from "../assets/sportsGallery4.png";
import sportsGallery5 from "../assets/sportsGallery5.png";
import sportsGallery6 from "../assets/sportsGallery6.png";

export default function PhotoGallery({
  galleryImagesArray,
  eventGalleryArray,
}) {
  console.log("galleryImagesArray", galleryImagesArray);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  var [imageSrc, setImageSrc] = useState([]);
  const images = [
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
    sportsGallery4,
  ];

  let newArray = [];
  const setImagesArray = () => {
    // for (let index = 0; index < galleryImagesArray.length; index++) {
    //   let element = galleryImagesArray[index];
    //   console.log(process.env.REACT_APP_API_URL + element.image_path);
    // }
    // setImageSrc((imageSrc = newArray));
  };
  galleryImagesArray.map((data) => {
    newArray.push(process.env.REACT_APP_API_URL + data.image_path);
  });

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setImagesArray();
    // setImageSrc((imageSrc = src.image_path));
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  console.log("Images Array", newArray);

  return (
    <>
      <div className="photo__gallery__container__content__heading">
        Photo Gallery
      </div>
      <div className="photo__gallery__container__content">
        {galleryImagesArray != null && galleryImagesArray.length > 0 ? (
          galleryImagesArray.map((src, index) => (
            <div className="photo__gallery__container__content__img__wrapper">
              <img
                loading="lazy"
                src={process.env.REACT_APP_API_URL + src.image_path}
                onClick={() => openImageViewer(index)}
                key={index}
                alt="photo__gallery__container__content__img"
                className="photo__gallery__container__content__img"
              />
            </div>
          ))
        ) : (
          <div style={{ fontSize: "30px", width: "100%" }}>
            Images Not Available
          </div>
        )}

        {isViewerOpen && (
          <ImageViewer
            src={newArray}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </>
  );
}
