import React, { useState } from "react";
import { Link } from "react-router-dom";
import sportsGallery4 from "../assets/sportsGallery4.png";
import PhotoGallery from "./PhotoGallery";
import { getAllSchoolEventImagesById } from "../APIs/Repos";

function GalleryFolder({ title, setIsGallery, data, onCardClickCallBack }) {
  return (
    <button
      onClick={() => {
        setIsGallery(true);
        window.scrollTo({
          top: 600,
          behavior: "smooth",
        });
        onCardClickCallBack(data);
      }}
      className="campus__life__main__container__content__card"
    >
      <img
        src={
          data ? process.env.REACT_APP_API_URL + data.thumbnail : sportsGallery4
        }
        alt="GalleryPic"
        className="campus__life__main__container__content__card__img"
      />
      <div className="campus__life__main__container__content__card__overlay">
        <div className="campus__life__main__container__content__card__overlay__heading">
          {title}
        </div>
      </div>
    </button>
  );
}

export default function FolderGallery({ eventsArray }) {
  const [isGallery, setIsGallery] = useState(false);
  var [galleryImagesArray, setGalleryImagesArray] = useState([]);

  const getAllSchoolEventImagesByIdApiCall = (data) => {
    getAllSchoolEventImagesById(data.id)
      .then((res) => {
        if (res.data.success) {
          setGalleryImagesArray((galleryImagesArray = res.data.result));
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  return (
    <>
      {isGallery ? (
        <div className="folder__gallery__container__content">
          <PhotoGallery galleryImagesArray={galleryImagesArray} />
        </div>
      ) : (
        <>
          <div className="folder__gallery__heading">Photos Collections</div>
          <div className="folder__gallery__container__content">
            {eventsArray != null && eventsArray.length > 0
              ? eventsArray.map((data) => (
                  <GalleryFolder
                    setIsGallery={setIsGallery}
                    title={data.title}
                    data={data}
                    onCardClickCallBack={(data) => {
                      getAllSchoolEventImagesByIdApiCall(data);
                    }}
                  />
                ))
              : null}
            {/* <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            />
            <GalleryFolder
              setIsGallery={setIsGallery}
              title="Alumni Dinner'21"
            /> */}
          </div>
        </>
      )}
    </>
  );
}
