import React, { useState } from "react";
import contactPic from "../assets/contactPic.png";
import { contact } from "../APIs/Repos";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { isEmailInvalid } from "../Utils/Validations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactUsSection() {
  //#region Variables
  var [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] =
    useState(false);

  var [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailErrorMessageVisibility, setEmailErrorMessageVisibility] =
    useState(false);

  var [subjectInfo, setSubjcetInfo] = useState("");
  const [subjectInfoErrorMessage, setSubjectInfoErrorMessage] = useState("");
  const [
    subjectInfoErrorMessageVisibility,
    setSubjectInfoErrorMessageVisibility,
  ] = useState(false);

  var [message, setMessage] = useState("");
  const [messageErrorMessage, setMessageErrorMessage] = useState("");
  const [messageErrorMessageVisibility, setMessageErrorMessageVisibility] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //#endregion

  //#region API Call
  const contactAPICall = () => {
    let data = {
      id: 0,
      name: name,
      email: email,
      subject_info: subjectInfo,
      message: message,
    };
    if (isViewValid()) {
      setIsLoading(true);
      contact(data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            toast("Sent Successfully!");
            setName("");
            setEmail("");
            setSubjcetInfo("");
            setMessage("");
          } else {
            alert("Ops! unable to send your request, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("contact Error", error);
        });
    }
  };
  //#endregion

  //#region Name Validation

  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorMessageAndVisibilityAndIsValid(name);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setNameErrorMessageAndVisibility("Enter name", true);
    else {
      setNameErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameErrorMessageAndVisibility = (text, visibility) => {
    setNameErrorMessage(text);
    setNameErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Email Validation

  const onEmailTextChangeListener = (event) => {
    setEmail((email = event.currentTarget.value));
    setEmailErrorMessageAndVisibilityAndIsValid(email);
  };

  const setEmailErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setEmailErrorMessageAndVisibility("Enter email", true);
    else if (isEmailInvalid(enterdText))
      setEmailErrorMessageAndVisibility("Enter Valid email", true);
    else {
      setEmailErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setEmailErrorMessageAndVisibility = (text, visibility) => {
    setEmailErrorMessage(text);
    setEmailErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Subject Info Validation

  const onSubjectInfoTextChangeListener = (event) => {
    setSubjcetInfo((subjectInfo = event.currentTarget.value));
    setSubjectInfoErrorMessageAndVisibilityAndIsValid(subjectInfo);
  };

  const setSubjectInfoErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setSubjectInfoErrorMessageAndVisibility("Enter Subject", true);
    else {
      setSubjectInfoErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setSubjectInfoErrorMessageAndVisibility = (text, visibility) => {
    setSubjectInfoErrorMessage(text);
    setSubjectInfoErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Message Validation

  const onMessageTextChangeListener = (event) => {
    setMessage((message = event.currentTarget.value));
    setMessageErrorMessageAndVisibilityAndIsValid(message);
  };

  const setMessageErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setMessageErrorMessageAndVisibility("Enter message", true);
    else {
      setMessageErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setMessageErrorMessageAndVisibility = (text, visibility) => {
    setMessageErrorMessage(text);
    setMessageErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
    if (viewValid)
      viewValid = setEmailErrorMessageAndVisibilityAndIsValid(email);
    if (viewValid)
      viewValid =
        setSubjectInfoErrorMessageAndVisibilityAndIsValid(subjectInfo);
    if (viewValid)
      viewValid = setMessageErrorMessageAndVisibilityAndIsValid(message);
    return viewValid;
  };
  //#endregion

  return (
    <div className="contact__us__main__container">
      <img
        src={contactPic}
        alt="contactPic"
        className="contact__us__main__container__img"
      />
      <div className="contact__us__main__container__overlay">
        <div className="contact__us__main__container__overlay__content">
          <div
            action=""
            className="contact__us__main__container__overlay__content__form"
          >
            <div className="contact__us__main__container__overlay__content__form__heading">
              Contact Us!
            </div>
            <div className="contact__us__main__container__overlay__content__form__paragraph">
              We are here to help and answer any questions you might have. We
              look forward to hearing from you.
            </div>
            <div className="contact__us__main__container__overlay__content__form__">
              <input
                type="text"
                placeholder="Name"
                className="contact__us__main__container__overlay__content__form__input"
                value={name}
                onChange={(event) => onNameTextChangeListener(event)}
              />
              <div className="contact__us__error__message">
                {nameErrorMessageVisibility ? nameErrorMessage : null}
              </div>
            </div>
            <div className="contact__us__main__container__overlay__content__form__">
              <input
                type="email"
                placeholder="Email"
                required
                className="contact__us__main__container__overlay__content__form__input"
                value={email}
                onChange={(event) => onEmailTextChangeListener(event)}
              />
              <div className="contact__us__error__message">
                {emailErrorMessageVisibility ? emailErrorMessage : null}
              </div>
            </div>
            <div className="contact__us__main__container__overlay__content__form__">
              <input
                type="text"
                placeholder="Subject Info"
                required
                className="contact__us__main__container__overlay__content__form__input"
                value={subjectInfo}
                onChange={(event) => onSubjectInfoTextChangeListener(event)}
              />
              <div className="contact__us__error__message">
                {subjectInfoErrorMessageVisibility
                  ? subjectInfoErrorMessage
                  : null}
              </div>
            </div>
            <div className="contact__us__main__container__overlay__content__form__">
              <textarea
                type="text"
                placeholder="Message"
                rows="5"
                className="contact__us__main__container__overlay__content__form__input"
                value={message}
                onChange={(event) => onMessageTextChangeListener(event)}
              />
              <div className="contact__us__error__message">
                {messageErrorMessageVisibility ? messageErrorMessage : null}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "flex-end",
                marginTop: "1em",
              }}
            >
              <button
                className="primary__btn"
                onClick={() => {
                  if (isLoading == false) contactAPICall();
                }}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
          <div className="contact__us__main__container__overlay__content__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d870143.0614934689!2d73.2308772!3d31.5791484!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392268e32412f851%3A0xd441d41668a45797!2sLahore%20Grammar%20School!5e0!3m2!1sen!2s!4v1648135880375!5m2!1sen!2s"
              style={{ borderRadius: 30, border: "none" }}
              loading="lazy"
            />
          </div>
        </div>
        <div className="contact__us__main__container__overlay__content__office__hours">
          School Time <span>8 AM - 2 PM</span>
        </div>
        <div className="contact__us__main__container__overlay__content__contact__wrapper">
          <div className="contact__us__main__container__overlay__content__contact__line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.387"
              height="21.387"
              viewBox="0 0 21.387 21.387"
            >
              <path
                id="Icon_awesome-phone-alt"
                data-name="Icon awesome-phone-alt"
                d="M20.777,15.114,16.1,13.108a1,1,0,0,0-1.17.288l-2.072,2.531a15.483,15.483,0,0,1-7.4-7.4L7.987,6.454a1,1,0,0,0,.288-1.17L6.27.606A1.009,1.009,0,0,0,5.121.026l-4.344,1A1,1,0,0,0,0,2.005,19.38,19.38,0,0,0,19.383,21.388a1,1,0,0,0,.977-.777l1-4.344a1.015,1.015,0,0,0-.585-1.153Z"
                transform="translate(0 0)"
                fill="#f7c902"
              />
            </svg>
            <div style={{ marginLeft: ".5em" }}>
              <b>Boys Canal Campus:</b>
              <br />
              Tel: 041-2421232
            </div>
          </div>
          <div className="contact__us__main__container__overlay__content__contact__line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.387"
              height="21.387"
              viewBox="0 0 21.387 21.387"
            >
              <path
                id="Icon_awesome-phone-alt"
                data-name="Icon awesome-phone-alt"
                d="M20.777,15.114,16.1,13.108a1,1,0,0,0-1.17.288l-2.072,2.531a15.483,15.483,0,0,1-7.4-7.4L7.987,6.454a1,1,0,0,0,.288-1.17L6.27.606A1.009,1.009,0,0,0,5.121.026l-4.344,1A1,1,0,0,0,0,2.005,19.38,19.38,0,0,0,19.383,21.388a1,1,0,0,0,.977-.777l1-4.344a1.015,1.015,0,0,0-.585-1.153Z"
                transform="translate(0 0)"
                fill="#f7c902"
              />
            </svg>
            <div style={{ marginLeft: ".5em" }}>
              <b>Girls Canal Campus:</b>
              <br />
              Tel: 041-2421234
            </div>
          </div>
          <div className="contact__us__main__container__overlay__content__contact__line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.387"
              height="21.387"
              viewBox="0 0 21.387 21.387"
            >
              <path
                id="Icon_awesome-phone-alt"
                data-name="Icon awesome-phone-alt"
                d="M20.777,15.114,16.1,13.108a1,1,0,0,0-1.17.288l-2.072,2.531a15.483,15.483,0,0,1-7.4-7.4L7.987,6.454a1,1,0,0,0,.288-1.17L6.27.606A1.009,1.009,0,0,0,5.121.026l-4.344,1A1,1,0,0,0,0,2.005,19.38,19.38,0,0,0,19.383,21.388a1,1,0,0,0,.977-.777l1-4.344a1.015,1.015,0,0,0-.585-1.153Z"
                transform="translate(0 0)"
                fill="#f7c902"
              />
            </svg>
            <div style={{ marginLeft: ".5em" }}>
              <b>105-C,Peoples Colony, Junior Branch:</b>
              <br />
              Tel: 041-8542438, 8714283, 8540935
            </div>
          </div>
          <div className="contact__us__main__container__overlay__content__contact__line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.116"
              height="18.979"
              viewBox="0 0 28.116 18.979"
            >
              <path
                id="Icon_zocial-email"
                data-name="Icon zocial-email"
                d="M.072,21.366V5.749q0-.027.081-.515L9.345,13.1.18,21.908a2.3,2.3,0,0,1-.108-.542ZM1.292,4.149a1.168,1.168,0,0,1,.461-.081H26.507A1.535,1.535,0,0,1,27,4.149l-9.218,7.89-1.22.976-2.413,1.979-2.413-1.979-1.22-.976Zm.027,18.817L10.565,14.1,14.144,17l3.579-2.9,9.246,8.866a1.3,1.3,0,0,1-.461.081H1.753a1.228,1.228,0,0,1-.434-.081ZM18.943,13.1l9.164-7.863a1.618,1.618,0,0,1,.081.515V21.366a2.078,2.078,0,0,1-.081.542Z"
                transform="translate(-0.072 -4.068)"
                fill="#f7c902"
              />
            </svg>
            info@lgsfsd.edu.pk
          </div>
        </div>
      </div>
    </div>
  );
}
