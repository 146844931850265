import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import overviewPic from "../assets/overviewPic.png";
import PhotoGallery from "../component/PhotoGallery";
import { getNewsAndEventsGalleryImages } from "../APIs/Repos";

export default function EventCardDetail() {
  //#region Variables
  const location = useLocation();
  const [eventData, setEventData] = useState(
    location.state ? location.state.eventDataToPass : null
  );
  const [eventGalleryArray, setEventGalleryArray] = useState([]);
  //#endregion

  //#region other functions
  useEffect(() => {
    getNewsAndEventsGalleryImagesApiCall();
  }, []);
  //#endregion

  //#region Api Call
  const getNewsAndEventsGalleryImagesApiCall = () => {
    getNewsAndEventsGalleryImages(eventData.id)
      .then((res) => {
        if (res.data.success) {
          setEventGalleryArray(res.data.result);
        } else {
          console.log(
            "getNewsAndEventsGalleryImages MESSAGE",
            res.data.message
          );
        }
      })
      .catch((error) => {
        console.log("getNewsAndEventsGalleryImages ERROR", error);
      });
  };
  //#endregion
  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              <svg
                style={{ width: 330 }}
                xmlns="http://www.w3.org/2000/svg"
                width="308"
                height="42"
                viewBox="0 0 598 42"
              >
                <rect
                  id="Rectangle_190"
                  data-name="Rectangle 190"
                  width="598"
                  height="42"
                  rx="21"
                  fill="#ec5700"
                />
              </svg>
              <div
                style={{ fontSize: 34 }}
                className="our__school__overlay__content__heading"
              >
                News & Events
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="careers__content__section">
        <div className="careers__content__section__cards__wrapper">
          <div
            className="careers__content__section__card__public__speaking"
            style={{ flexWrap: "nowrap" }}
          >
            <div className="careers__content__section__card__public__speaking__left">
              <div className="careers__content__section__card__public__speaking__left__heading">
                Overview
              </div>
              <div
                className="careers__content__section__card__public__speaking__left__paragraph"
                dangerouslySetInnerHTML={{ __html: eventData.description }}
              >
                {/* {eventData.description} */}
              </div>
            </div>
            <img
              src={process.env.REACT_APP_API_URL + eventData.banner}
              alt="overviewPic"
              className="careers__content__section__card__public__speaking__img"
            />
          </div>
        </div>
      </div>
      <div className="alumini__photo__gallery__wrapper">
        <PhotoGallery galleryImagesArray={eventGalleryArray} />
      </div>
    </>
  );
}
