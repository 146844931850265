import React, { useState, useEffect } from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import primarySchool from "../assets/primarySchool.png";
import middleSchool from "../assets/middleSchool.png";
import seniorSchoolO from "../assets/seniorSchoolO.png";
import seniorSchoolA from "../assets/seniorLevelA.png";
import { Link } from "react-router-dom";
import { getAllCampus, getAllSchool } from "../APIs/Repos";

export default function OurCampus() {
  //#region Variables
  var [campusArray, setCampusArray] = useState([]);
  var [schoolArray, setSchoolArray] = useState([]);
  var [canalCampusId, setCanalCampusId] = useState(0);
  var [campus105, setCampus105] = useState(0);
  const [canalCampusSchoolArray, setCanalCampusSchoolArray] = useState([]);
  const [campus105SchoolArray, setCampus105SchoolArray] = useState([]);

  const [primaryCanalCampusId, setPrimaryCanalCampusId] = useState(0);
  const [middleCanalCampusId, setMiddleCanalCampusId] = useState(0);
  const [seniorMatricCanalCampusId, setSeniorMatricCanalCampusId] = useState(0);
  const [seniorALevelCanalCampusId, setSeniorALevelCanalCampusId] = useState(0);

  const [primaryCampus105Id, setPrimaryCampus105Id] = useState(0);
  const [middleCampus105Id, setMiddleCampus105Id] = useState(0);

  //#endregion

  //#region Othen Functions
  useEffect(() => {
    getAllCampusApiCall();
    getAllSchoolApiCall();
  }, []);
  //#endregion

  //#region API Call

  const getAllCampusApiCall = () => {
    getAllCampus()
      .then((res) => {
        if (res.data.success) {
          setCampusArray((campusArray = res.data.result));
          for (let index = 0; index < campusArray.length; index++) {
            const element = campusArray[index];
            if (element.name == "CANAL CAMPUS")
              setCanalCampusId((canalCampusId = element.id));
            if (element.name == "105 CAMPUS")
              setCampus105((campus105 = element.id));
          }
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getAllSchoolApiCall = () => {
    let canaCampusArray = [];
    let campus105Array = [];
    getAllSchool()
      .then((res) => {
        if (res.data.success) {
          setSchoolArray((schoolArray = res.data.result));
          for (let index = 0; index < schoolArray.length; index++) {
            const element = schoolArray[index];
            if (element.name == "Primary School")
              setPrimaryCanalCampusId(element.id);
            if (element.name == "Middle School")
              setMiddleCanalCampusId(element.id);
            if (element.name == "Senior School(A-Level)")
              setSeniorALevelCanalCampusId(element.id);
            if (element.name == "Senior School(O-Level/Matric)")
              setSeniorMatricCanalCampusId(element.id);
            if (element.name == "Primary School Campus 105")
              setPrimaryCampus105Id(element.id);
            if (element.name == "Middle School Campus 105")
              setMiddleCampus105Id(element.id);
          }
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  //#endregion

  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="598"
                height="42"
                viewBox="0 0 598 42"
              >
                <rect
                  id="Rectangle_190"
                  data-name="Rectangle 190"
                  width="598"
                  height="42"
                  rx="21"
                  fill="#ec5700"
                />
              </svg>
              <div className="our__school__overlay__content__heading">
                OUR CAMPUS
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="events__main__container">
        <div id="canalcampus" className="campus__main__container__content">
          <div className="campus__main__container__content__left">
            <div className="campus__main__container__content__left__heading">
              Canal Campus
            </div>
            <div className="campus__main__container__content__left__content">
              {/* {canalCampusSchoolArray != null &&
              canalCampusSchoolArray.length > 0
                ? canalCampusSchoolArray.map((data) => ( */}
              <Link
                to="/canal-campus-primary-school"
                state={{ schoolDataToPass: primaryCanalCampusId }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="campus__main__container__content__left__entry campus__main__container__content__left__entry__senior"
              >
                <img src={primarySchool} alt="primarySchool" />
                Primary School
              </Link>
              {/* ))
                : null} */}
              <Link
                to="/canal-campus-middle-school"
                state={{ schoolDataToPass: middleCanalCampusId }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="campus__main__container__content__left__entry campus__main__container__content__left__entry__o__level"
              >
                <img src={middleSchool} alt="middleSchool" />
                Middle School
              </Link>
              <Link
                to="/canal-campus-senior-school-o-level"
                state={{ schoolDataToPass: seniorMatricCanalCampusId }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="campus__main__container__content__left__entry campus__main__container__content__left__entry__o__level"
              >
                <img src={seniorSchoolA} alt="seniorSchoolA" />
                Senior School(O-Level/Matric)
              </Link>

              <Link
                to="/canal-campus-senior-school-a-level"
                state={{ schoolDataToPass: seniorALevelCanalCampusId }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="campus__main__container__content__left__entry campus__main__container__content__left__entry__a__level"
              >
                <img src={seniorSchoolO} alt="seniorSchoolO" />
                Senior School(A-Level)
              </Link>
            </div>
          </div>
          <div
            id="105campus"
            className="campus__main__container__content__right"
          >
            <div className="campus__main__container__content__left__heading">
              105 Campus
            </div>
            <div className="campus__main__container__content__left__content">
              {/* {campus105SchoolArray != null && campus105SchoolArray.length > 0
                ? campus105SchoolArray.map((data) => ( */}
              <Link
                to="/105-c-campus-primary-school"
                state={{ schoolDataToPass: primaryCampus105Id }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="campus__main__container__content__left__entry campus__main__container__content__left__entry__senior"
              >
                <img src={primarySchool} alt="primarySchool" />
                Primary School
              </Link>
              {/* ))
                : null} */}
              <Link
                to="/105-c-campus-middle-school"
                state={{ schoolDataToPass: middleCampus105Id }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="campus__main__container__content__left__entry"
              >
                <img src={middleSchool} alt="middleSchool" />
                Middle School
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
