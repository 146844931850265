import React, { useState, useEffect } from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import zunaira from "../assets/zunaira.jpeg";
import kanwal from "../assets/kanwal.jpeg";
import dania from "../assets/dania.jpeg";
import contactPic from "../assets/contactPic.png";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { addAppointment, getUniversityPlacement } from "../APIs/Repos";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { VALIDATION_REGEX } from "../Constants";
import moment from "moment";

function ChoiceBtn({ title, onClick, id }) {
  return (
    <div className="personalized__section__form__container__left__content__right__btn">
      <input
        name={
          "personalized__section__form__container__left__content__right__btn" +
          id
        }
        type="radio"
        className="personalized__section__form__container__left__content__right__btn__input"
        onClick={onClick}
      />
      <div className="personalized__section__form__container__left__content__right__btn__content">
        {title}
      </div>
    </div>
  );
}

function InputChoice({ select, setSelect }) {
  return (
    <div className="swings__mood__choices">
      <input
        type="radio"
        defaultChecked={select ? true : false}
        onChange={() => {
          setSelect(true);
        }}
        className="swings__mood__choices__input"
      />
      <div className="swings__mood__choices__content">1</div>
    </div>
  );
}

export default function CounsellingCenter() {
  //#region Variables
  const [id1Value, setId1Value] = useState(0);
  const [id2Value, setId2Value] = useState(0);
  const [id3Value, setId3Value] = useState(0);
  const [id4Value, setId4Value] = useState(0);
  const [id5Value, setId5Value] = useState(0);
  const [select, setSelect] = useState(false);
  const [checked, setChecked] = useState(false);
  var [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameErrorMessageVisibility, setNameErrorMessageVisibility] =
    useState(false);
  var [contact, setContact] = useState("");
  const [contactErrorMessage, setContactErrorMessage] = useState("");
  const [contactErrorMessageVisibility, setContactErrorMessageVisibility] =
    useState(false);
  var [issue, setIssue] = useState("");
  const [issueErrorMessage, setIssueErrorMessage] = useState("");
  const [issueErrorMessageVisibility, setIssueErrorMessageVisibility] =
    useState(false);

  var [nameUP, setNameUP] = useState("");
  const [nameUPErrorMessage, setNameUPErrorMessage] = useState("");
  const [nameUPErrorMessageVisibility, setNameUPErrorMessageVisibility] =
    useState(false);

  var [contactUP, setContactUP] = useState("");
  const [contactUPErrorMessage, setContactUPErrorMessage] = useState("");
  const [contactUPErrorMessageVisibility, setContactUPErrorMessageVisibility] =
    useState(false);

  var [message, setMessage] = useState("");
  const [messageErrorMessage, setMessageErrorMessage] = useState("");
  const [messageErrorMessageVisibility, setMessageErrorMessageVisibility] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUP, setIsLoadingUP] = useState(false);

  let [UPList, setUPList] = useState([]);

  const [noOfSlidesPerView, setNoOfSlidesPerView] = useState(3);

  //#endregion

  //#region other functions

  useEffect(() => {
    getUniversityPlacementApiCall();
    if (window.innerWidth < 650) setNoOfSlidesPerView(1);
    else if (window.innerWidth <= 900) setNoOfSlidesPerView(2);
    else setNoOfSlidesPerView(3);

    window.addEventListener("resize", () => {
      if (window.innerWidth < 650) setNoOfSlidesPerView(1);
      else if (window.innerWidth <= 900) setNoOfSlidesPerView(2);
      else setNoOfSlidesPerView(3);
    });
  }, []);

  //#endregion

  //#region API Calls

  const addApointmentApiCall = (type) => {
    let data = {
      id: 0,
      name: type == "Appointment" ? name : nameUP,
      contact: type == "Appointment" ? contact : contactUP,
      issue: type == "Appointment" ? issue : message,
      type: type,
    };

    if (isViewValid(type)) {
      if (type == "Appointment") setIsLoading(true);
      else setIsLoadingUP(true);
      addAppointment(data)
        .then((res) => {
          if (type == "Appointment") setIsLoading(false);
          else setIsLoadingUP(false);
          if (res.data.success) {
            toast("Your request has been sent to our counsellors!");
            setName("");
            setContact("");
            setIssue("");
            setNameUP("");
            setContactUP("");
            setMessage("");
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          if (type == "Appointment") setIsLoading(false);
          else setIsLoadingUP(false);
          alert(error);
        });
    }
  };

  const getUniversityPlacementApiCall = () => {
    let newArray = [];
    getUniversityPlacement()
      .then((res) => {
        if (res.data.success) {
          setUPList((UPList = res.data.result));
          for (let index = 0; index < UPList.length; index++) {
            const element = UPList[index];
            if (element.is_featured == true) newArray.push(element);
          }
          setUPList(newArray);
        } else {
          console.log("getUniversityPlacement MESSAGE", res.data.message);
        }
      })
      .catch((error) => {
        console.log("getUniversityPlacement ERROR", error);
      });
  };

  //#endregion

  //#region Name Validation

  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorMessageAndVisibilityAndIsValid(name);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setNameErrorMessageAndVisibility("Enter name", true);
    else {
      setNameErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameErrorMessageAndVisibility = (text, visibility) => {
    setNameErrorMessage(text);
    setNameErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Name UP Validation

  const onNameUPTextChangeListener = (event) => {
    setNameUP((nameUP = event.currentTarget.value));
    setNameUPErrorMessageAndVisibilityAndIsValid(nameUP);
  };

  const setNameUPErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setNameUPErrorMessageAndVisibility("Enter name", true);
    else {
      setNameUPErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameUPErrorMessageAndVisibility = (text, visibility) => {
    setNameUPErrorMessage(text);
    setNameUPErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Contact Validation

  const onContactTextChangeListener = (event) => {
    setContact((contact = event.currentTarget.value));
    setContactErrorMessageAndVisibilityAndIsValid(contact);
  };

  const setContactErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setContactErrorMessageAndVisibility("Enter Contact info", true);
    else if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(enterdText))
      setContactErrorMessageAndVisibility("Enter Valid number", true);
    else {
      setContactErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setContactErrorMessageAndVisibility = (text, visibility) => {
    setContactErrorMessage(text);
    setContactErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Contact UP Validation

  const onContactUPTextChangeListener = (event) => {
    setContactUP((contactUP = event.currentTarget.value));
    setContactUPErrorMessageAndVisibilityAndIsValid(contactUP);
  };

  const setContactUPErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setContactUPErrorMessageAndVisibility("Enter Contact info", true);
    else if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(enterdText))
      setContactUPErrorMessageAndVisibility("Enter Valid Number", true);
    else {
      setContactUPErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setContactUPErrorMessageAndVisibility = (text, visibility) => {
    setContactUPErrorMessage(text);
    setContactUPErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Issue Validation

  const onIssueTextChangeListener = (event) => {
    setIssue((issue = event.currentTarget.value));
    setIssueErrorMessageAndVisibilityAndIsValid(issue);
  };

  const setIssueErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setIssueErrorMessageAndVisibility("Write Issue to be discussed", true);
    else {
      setIssueErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setIssueErrorMessageAndVisibility = (text, visibility) => {
    setIssueErrorMessage(text);
    setIssueErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region Message Validation

  const onMessageTextChangeListener = (event) => {
    setMessage((message = event.currentTarget.value));
    setMessageErrorMessageAndVisibilityAndIsValid(message);
  };

  const setMessageErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setMessageErrorMessageAndVisibility("Write message", true);
    else {
      setMessageErrorMessageAndVisibility("", false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setMessageErrorMessageAndVisibility = (text, visibility) => {
    setMessageErrorMessage(text);
    setMessageErrorMessageVisibility(visibility);
  };
  //#endregion

  //#region View Valid
  const isViewValid = (type) => {
    if (type == "Appointment") {
      var viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);
      if (viewValid)
        viewValid = setContactErrorMessageAndVisibilityAndIsValid(contact);
      if (viewValid)
        viewValid = setIssueErrorMessageAndVisibilityAndIsValid(issue);
      return viewValid;
    } else {
      var viewValid = setNameUPErrorMessageAndVisibilityAndIsValid(nameUP);
      if (viewValid)
        viewValid = setContactUPErrorMessageAndVisibilityAndIsValid(contactUP);
      if (viewValid)
        viewValid = setMessageErrorMessageAndVisibilityAndIsValid(message);
      return viewValid;
    }
  };
  //#endregion

  const newArray = [
    { value: "item 1" },
    { value: "item 2" },
    { value: "item 3" },
    { value: "item 4" },
  ];

  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              {/* <svg
                style={{ width: 550 }}
                xmlns="http://www.w3.org/2000/svg"
                width="598"
                height="42"
                viewBox="0 0 598 42"
              >
                <rect
                  id="Rectangle_190"
                  data-name="Rectangle 190"
                  width="598"
                  height="42"
                  rx="21"
                  fill="#ec5700"
                />
              </svg> */}
              <div
                style={{ fontSize: 30, textAlign: "center" }}
                className="our__school__overlay__content__heading"
              >
                Counseling & Wellness Center
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="events__main__container">
        <div className="campus__main__container__content campus__main__container__content__reverse">
          <div className="campus__main__container__content__heading">
            introduction <span>Meet our Counsellors</span>
          </div>
          <div className="campus__main__container__content__counselling">
            <button className="campus__main__container__content__counselling__card">
              <img
                src={dania}
                alt="dania"
                className="campus__main__container__content__counselling__card__img"
              />
              <div className="campus__main__container__content__counselling__card__heading">
                Dania Nawaz
              </div>
              <div
                style={{ color: "#005DFF", fontWeight: "500 " }}
                className="campus__main__container__content__counselling__card__para"
              >
                Career Counsellor
              </div>
              <div className="campus__main__container__content__counselling__card__para">
                Miss Dania Nawaz has served as a Career Counsellor for students
                across Pakistan as well as internationally, with reputable
                counselling firms as well as privately...
              </div>
            </button>
            <button className="campus__main__container__content__counselling__card">
              <img
                src={zunaira}
                alt="zunaira"
                className="campus__main__container__content__counselling__card__img"
              />
              <div className="campus__main__container__content__counselling__card__heading">
                Zunaira Amjad
              </div>
              <div
                style={{ color: "#005DFF", fontWeight: "500 " }}
                className="campus__main__container__content__counselling__card__para"
              >
                Career Counsellor
              </div>
              <div className="campus__main__container__content__counselling__card__para">
                Zunaira Amjad is an IB certified counsellor directly dealing the
                educational and industrial activity-based profile of the
                students.
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="personalized__section__wrapper">
        <div className="personalized__section__wrapper__content">
          <div className="personalized__section__wrapper__content__entry">
            <div
              style={{ color: "#F9CC09" }}
              className="personalized__section__wrapper__content__entry__heading"
            >
              Personalized Counseling
            </div>
            <div className="personalized__section__wrapper__content__entry__para">
              A process of self-discovery and growth. It can help increase
              self-confidence, improve relationships, solve problems, achieve
              educational goals, and make good decisions for emotional,
              intellectual, physical, and spiritual well-being.{" "}
            </div>
          </div>
          <div className="personalized__section__wrapper__content__entry">
            <div
              style={{ color: "#00B1FF" }}
              className="personalized__section__wrapper__content__entry__heading"
            >
              We are offering
            </div>
            <div className="personalized__section__wrapper__content__entry__para">
              <li>School based counseling</li>
              <li>Adolescent Counseling</li>
              <li>Socio-emotional Adjustment</li>
            </div>
          </div>
          <div className="personalized__section__wrapper__content__entry">
            <div
              style={{ color: "#FF6AE6" }}
              className="personalized__section__wrapper__content__entry__heading"
            >
              Come and share with us
            </div>
            <div className="personalized__section__wrapper__content__entry__para">
              <li> School adjustment</li>
              <li>Emotional disturbances and changes</li>
              <li>Peer pressure</li>

              <li>Psychosexual adjustment</li>
              <li>Family</li>
              <li>Siblings rivalry </li>
              <li>Academic concerns</li>
              <li>Social adjustment</li>
              <li>Sleep problems</li>
              <li>Dreaming</li>
              <li>
                Behavioral concerns, personal growth and overall development
              </li>
            </div>
          </div>
        </div>
      </div>
      <div className="personalized__section__form__container">
        <div className="personalized__section__form__container__left">
          <div className="personalized__section__form__container__left__heading">
            Mood swings check your mood
          </div>
          <div className="personalized__section__form__container__left__content">
            <div className="personalized__section__form__container__left__content__heading">
              I am pleasant most of the time
            </div>
            <div className="personalized__section__form__container__left__content__right">
              <ChoiceBtn
                id="1"
                title="1"
                onClick={() => {
                  setId1Value(1);
                }}
              />
              <ChoiceBtn
                id="1"
                title="2"
                onClick={() => {
                  setId1Value(2);
                }}
              />
            </div>
          </div>
          <div className="personalized__section__form__container__left__content">
            <div className="personalized__section__form__container__left__content__heading">
              I feel good most of the time
            </div>
            <div className="personalized__section__form__container__left__content__right">
              <ChoiceBtn
                id="2"
                title="1"
                onClick={() => {
                  setId2Value(1);
                }}
              />
              <ChoiceBtn
                id="2"
                title="2"
                onClick={() => {
                  setId2Value(2);
                }}
              />
            </div>
          </div>
          <div className="personalized__section__form__container__left__content">
            <div className="personalized__section__form__container__left__content__heading">
              I like meeting people
            </div>
            <div className="personalized__section__form__container__left__content__right">
              <ChoiceBtn
                id="3"
                title="1"
                onClick={() => {
                  setId3Value(1);
                }}
              />
              <ChoiceBtn
                id="3"
                title="2"
                onClick={() => {
                  setId3Value(2);
                }}
              />
            </div>
          </div>
          <div className="personalized__section__form__container__left__content">
            <div className="personalized__section__form__container__left__content__heading">
              I like high gatherings
            </div>
            <div className="personalized__section__form__container__left__content__right">
              <ChoiceBtn
                id="4"
                title="1"
                onClick={() => {
                  setId4Value(1);
                }}
              />
              <ChoiceBtn
                id="4"
                title="2"
                onClick={() => {
                  setId4Value(2);
                }}
              />
            </div>
          </div>
          <div className="personalized__section__form__container__left__content">
            <div className="personalized__section__form__container__left__content__heading">
              I understand changes happening around
            </div>
            <div className="personalized__section__form__container__left__content__right">
              <ChoiceBtn
                id="5"
                title="1"
                onClick={() => {
                  setId5Value(1);
                }}
              />
              <ChoiceBtn
                id="5"
                title="2"
                onClick={() => {
                  setId5Value(2);
                }}
              />
            </div>
          </div>
          <div className="personalized__section__form__container__left__content">
            <div className="personalized__section__form__container__left__content__heading"></div>
            <div
              className="personalized__section__form__container__left__content__heading"
              style={{ fontSize: 18 }}
            >
              Your Score is{" "}
              {id1Value + id2Value + id3Value + id4Value + id5Value}
            </div>
          </div>
          <div className="personalized__section__form__container__left__footer">
            Score 6 and above means pleasant mood Score 5 and below means sad
            mood-Recommendation meet the psychologist.
          </div>
        </div>
        <div className="personalized__section__form__container__right">
          <div className="personalized__section__form__container__right__heading">
            <span> Make an </span>
            appointment!
          </div>
          <div>
            <input
              type="text"
              placeholder="Name"
              className="personalized__section__form__container__right__input"
              value={name}
              onChange={(event) => onNameTextChangeListener(event)}
            />
            <div className="form__error__messages">
              {nameErrorMessageVisibility ? nameErrorMessage : null}
            </div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Contact"
              className="personalized__section__form__container__right__input"
              value={contact}
              onChange={(event) => {
                if (!isNaN(event.currentTarget.value))
                  onContactTextChangeListener(event);
              }}
            />
            <div className="form__error__messages">
              {contactErrorMessageVisibility ? contactErrorMessage : null}
            </div>
          </div>
          <div>
            <textarea
              id=""
              placeholder="Issues to be discussed"
              rows="5"
              cols="14"
              className="personalized__section__form__container__right__input"
              value={issue}
              onChange={(event) => onIssueTextChangeListener(event)}
            ></textarea>
            <div className="form__error__messages">
              {issueErrorMessageVisibility ? issueErrorMessage : null}
            </div>
          </div>
          <div className="personalized__section__form__btn__wrapper">
            <button
              className="personalized__section__form__btn"
              onClick={() => {
                if (isLoading == false) addApointmentApiCall("Appointment");
              }}
            >
              {isLoading ? "Submiting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <div className="learning__developmet__section">
        <div className="learning__developmet__section__heading">
          Learning & Development
        </div>
        <div className="learning__developmet__section__para">
          Workshops And Programs
        </div>
        <div className="learning__developmet__section__content">
          <Swiper slidesPerView={noOfSlidesPerView} spaceBetween={30}>
            {UPList != null && UPList.length > 0
              ? UPList.map((data, i) => {
                  if (i % 2 == 0) {
                    return (
                      <SwiperSlide>
                        <div className="learning__developmet__section__content__card">
                          <div className="learning__developmet__section__content__card__heading">
                            {data.title}
                          </div>
                          <div className="learning__developmet__section__content__card__entry">
                            <b>Date</b>{" "}
                            <span>
                              {moment(data.date).format("DD MMM YYYY")}
                            </span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry">
                            <b>Time</b>{" "}
                            <span>{moment(data.time).format("hh:mm a")}</span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry">
                            <b>Venue</b> <span>{data.venue}</span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry">
                            <b>Speaker</b> <span>{data.speaker}</span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry">
                            <b>Registration</b>{" "}
                            <span>{data.registration} PKR</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  } else if (i % 2 != 0) {
                    return (
                      <SwiperSlide>
                        <div
                          style={{ background: "#005DFF" }}
                          className="learning__developmet__section__content__card"
                        >
                          <div className="learning__developmet__section__content__card__heading__white">
                            {/* Faculty Development Programs */}
                            <span>{data.title}</span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry__white">
                            <b>Date</b>{" "}
                            <span>
                              {moment(data.date).format("DD MMM YYYY")}
                            </span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry__white">
                            <b>Time</b>{" "}
                            <span>{moment(data.time).format("hh:mm a")}</span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry__white">
                            <b>Venue</b> <span>{data.venue}</span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry__white">
                            <b>Speaker</b>
                            <span style={{ textAlign: "right" }}>
                              {data.speaker}
                            </span>
                          </div>
                          <div className="learning__developmet__section__content__card__entry__white">
                            <b>Registration</b>{" "}
                            <span>{data.registration} PKR</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  }
                  // else if (i == i + 3) {
                  //   return (
                  //     <div
                  //       style={{ background: "#2B2B2B" }}
                  //       className="learning__developmet__section__content__card"
                  //     >
                  //       <div className="learning__developmet__section__content__card__heading__white">
                  //         Bifurcation Session for <span>Grade 8th</span>
                  //       </div>
                  //       <div className="learning__developmet__section__content__card__entry__white">
                  //         <b>Date</b> <span>3 May, 20222</span>
                  //       </div>
                  //       <div className="learning__developmet__section__content__card__entry__white">
                  //         <b>Time</b> <span>10:00 AM</span>
                  //       </div>
                  //       <div className="learning__developmet__section__content__card__entry__white">
                  //         <b>Venue</b> <span>LGS AUDITORIUM 1</span>
                  //       </div>
                  //       <div className="learning__developmet__section__content__card__entry__white">
                  //         <b>Speaker</b> <span>Ms Tahira Abbas</span>
                  //       </div>
                  //       <div className="learning__developmet__section__content__card__entry__white">
                  //         <b>Registration</b> <span>Free</span>
                  //       </div>
                  //     </div>
                  //   );
                  // }
                })
              : null}
          </Swiper>
        </div>
      </div>
      <div className="international__office__container">
        <img
          src={contactPic}
          alt="contactPic"
          className="international__office__img"
        />
        <div className="contact__us__main__container__overlay__new">
          <div className="contact__us__main__container__overlay__content__new">
            <div
              style={{ color: "#fff" }}
              className="learning__developmet__section__heading"
            >
              International Office
            </div>
            <div
              style={{ color: "#F9CC09" }}
              className="learning__developmet__section__para"
            >
              Workshops And Programs
            </div>
            <div className="international__office__content">
              <div className="international__office__content__left">
                <div className="international__office__content__left__heading">
                  <span>Career Counseling</span> Counseling
                </div>
                <div className="international__office__content__left__para">
                  A process that will help you to know and understand yourself
                  and the world of work in order to make career, educational,
                  and life decisions. Career development is more than just
                  deciding on a major and what job you want to get when you
                  graduate.
                </div>

                <div style={{ marginTop: "2em" }}>
                  <div className="international__office__content__left__heading">
                    <span> SEEK HELP </span>
                    DURING OFFICE HOURS
                  </div>
                  <div className="international__office__content__left__para">
                    Counseling & Wellness Center
                  </div>
                  <div className="international__office__content__left__sub__para">
                    123 Lahore Grammar School, Canal Park, East Canal Campus,
                    canal Road Faisalabad
                  </div>

                  <div className="international__office__content__left__sub__para">
                    <span>Email:</span> psykanwal@gmail.com
                  </div>
                  <div className="international__office__content__left__sub__para">
                    You can make offline appointments:
                  </div>
                  <div
                    className="international__office__content__left__sub__para"
                    style={{ display: "flex" }}
                  >
                    <div
                      className="international__office__content__left__sub__para"
                      style={{ marginRight: 10 }}
                    >
                      Online
                    </div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setChecked(e.target.checked);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <div
                      className="international__office__content__left__sub__para"
                      style={{ marginLeft: 10 }}
                    >
                      Offline
                    </div>
                  </div>

                  <div className="international__office__content__left__sub__para">
                    Visiting hours
                  </div>
                  <div className="international__office__content__left__sub__para">
                    {checked ? (
                      <>
                        <span>Mon-Fri:</span> 5PM - 8PM
                      </>
                    ) : (
                      <>
                        <span>Mon-Fri:</span> 8AM- 2PM
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="international__office__content__right">
                <div className="international__office__content__left__heading">
                  <span>University</span>
                  Placements
                </div>
                <div className="international__office__content__left__para">
                  Our International Office offers potential national and
                  international university placements to Grammarian family with
                  scholarships and financial aids. Our aim is to develop a
                  student and university fit. We are dealing with admission in
                  USA, Canada, UK, Italy, Ireland, Dubai, Europe, Turkey &
                  Russia.
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Name"
                    className="international__office__content__left__para__input"
                    value={nameUP}
                    onChange={(event) => onNameUPTextChangeListener(event)}
                  />
                  <div className="form__error__messages">
                    {nameUPErrorMessageVisibility ? nameUPErrorMessage : null}
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Contact"
                    className="international__office__content__left__para__input"
                    value={contactUP}
                    onChange={(event) => {
                      if (!isNaN(event.currentTarget.value))
                        onContactUPTextChangeListener(event);
                    }}
                  />
                  <div className="form__error__messages">
                    {contactUPErrorMessageVisibility
                      ? contactUPErrorMessage
                      : null}
                  </div>
                </div>
                <div>
                  <textarea
                    id=""
                    placeholder="Message"
                    rows="5"
                    cols="14"
                    className="international__office__content__left__para__input"
                    value={message}
                    onChange={(event) => onMessageTextChangeListener(event)}
                  ></textarea>
                  <div className="form__error__messages">
                    {messageErrorMessageVisibility ? messageErrorMessage : null}
                  </div>
                </div>
                {/* <input
                  type="text"
                  placeholder="Name"
                  className="international__office__content__left__para__input"
                />
                <input
                  type="text"
                  placeholder="Contact"
                  className="international__office__content__left__para__input"
                />
                <textarea
                  id=""
                  placeholder="Issues to be discussed"
                  rows="5"
                  cols="14"
                  className="international__office__content__left__para__input"
                ></textarea> */}
                <div className="personalized__section__form__btn__wrapper">
                  <button
                    className="international__office__content__left__btn"
                    onClick={() => {
                      if (isLoadingUP == false)
                        addApointmentApiCall("University Placement");
                    }}
                  >
                    {isLoadingUP ? "Submiting..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career__section" style={{ minHeight: "fit-content" }}>
        <div
          className="career__section__content"
          style={{ minHeight: "fit-content", marginBottom: "5em" }}
        >
          <div className="vacency__heading" style={{ textAlign: "center" }}>
            Success Stories
          </div>
          <Swiper modules={[Navigation]} navigation>
            <SwiperSlide>
              <SuccessStoriesCard />
            </SwiperSlide>
            <SwiperSlide>
              <SuccessStoriesCard />
            </SwiperSlide>
            <SwiperSlide>
              <SuccessStoriesCard />
            </SwiperSlide>
            <SwiperSlide>
              <SuccessStoriesCard />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

function SuccessStoriesCard({}) {
  return (
    <div className="success__stories__card">
      <img
        src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        alt=""
        className="success__stories__card__img"
      />
      <div className="success__stories__card__content">
        <div className="success__stories__card__content__heading">
          First Position In Competition
        </div>
        <div className="success__stories__card__content__info">
          Our International Office offers potential national and international
          university placements to Grammarian family with scholarships and
          financial aids. Our aim is to develop a student and university fit. We
          are dealing with admission in USA, Canada, UK, Italy, Ireland, Dubai,
          Europe, Turkey & Russia. Our International Office offers potential
          national and international university placements to Grammarian family
          with scholarships and financial aids. Our aim is to develop a student
          and university fit. We are dealing with admission in USA, Canada, UK,
          Italy, Ireland, Dubai, Europe, Turkey & Russia. international
          university placements to Grammarian family with scholarships and
          financial aids. Our aim is to develop a student and university fit. We
          are dealing with admission in USA, Canada, UK, Italy, Ireland, Dubai,
          Europe, Turkey & Russia. Our International Office offers potential
          national and international university placements to Grammarian family
          with scholarships and financial aids. Our aim is to develop a student
          and university fit. We are dealing with admission in USA, Canada, UK,
          Italy, Ireland, Dubai, Europe, Turkey & Russia.{" "}
        </div>
      </div>
    </div>
  );
}
