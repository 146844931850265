import React, { useState } from "react";
import "animate.css";

export default function ({ title, description }) {
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);
  return (
    <>
      {isNoticeOpen ? (
        <>
          <button
            onClick={() => {
              setIsNoticeOpen(false);
            }}
            className="notice__board__section__content__card"
          >
            <div className="notice__board__section__content__card__header">
              <div className="notice__board__section__content__card__heading">
                {title
                  ? title
                  : " Class Ones 1st Term Newsletetr (24th Aug- 24th December’ 2021)"}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-up"
              >
                <polyline points="18 15 12 9 6 15"></polyline>
              </svg>
            </div>
            <div
              className="notice__board__section__content__card__paragraph animate__animated  animate__fadeInDown"
              dangerouslySetInnerHTML={{ __html: description }}
            >
              {/* {description
                ? description
                : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae enim eius earum animi exercitationem deleniti amet, quis nam ratione ex aspernatur placeat? Excepturi repudiandae adipisci vel dolor quas accusantium, optio iusto magnam voluptatibus, asperiores beatae. Vitae enim eius earum animi exercitationem deleniti amet, quis nam ratione ex aspernatur placeat? Excepturi repudiandae adipisci vel dolor quas accusantium, optio iusto magnam voluptatibus, asperiores beatae. earum animi exercitationem deleniti amet, quis nam ratione ex aspernatur placeat? Excepturi repudiandae adipisci vel dolor quas accusantium, optio iusto magnam voluptatibus, asperiores beatae."} */}
            </div>
            {/* <div className="notice__board__section__content__card__paragraph">
              <li>
                <a
                  href="http://www.africau.edu/images/default/sample.pdf"
                  style={{
                    color: "#242424",
                  }}
                  target="_blank"
                >
                  Academic Policy 2021 2022
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-link"
                    style={{ marginLeft: ".5em", marginBottom: "-.15em" }}
                  >
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="http://www.africau.edu/images/default/sample.pdf"
                  style={{
                    color: "#242424",
                  }}
                  target="_blank"
                >
                  Dress Code Policy
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-link"
                    style={{ marginLeft: ".5em", marginBottom: "-.15em" }}
                  >
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                  </svg>
                </a>
              </li>
            </div> */}
          </button>
        </>
      ) : (
        <button
          onClick={() => {
            setIsNoticeOpen(true);
          }}
          className="notice__board__section__content__card"
        >
          <div className="notice__board__section__content__card__header">
            <div className="notice__board__section__content__card__heading">
              {title
                ? title
                : " Class Ones 1st Term Newsletetr (24th Aug- 24th December’ 2021)"}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </button>
      )}
    </>
  );
}
