import React, { useState, useEffect } from "react";
import ourSchoolBg from "../assets/ourSchoolBg.jpg";
import pricipal_pic from "../assets/pricipal_pic.png";
import ourVisionPic from "../assets/ourVisionPic.png";
import academicBg from "../assets/academicBg.png";
import AliaRehman from "../assets/AliaRehman.jpeg";
import MeenaGul from "../assets/MeenaGul.jpeg";
import irfanaAziz from "../assets/irfanaAziz.jpg";
import AIRMS from "../assets/AIRMS.png";
import PARENTHANDBOOK from "../assets/PARENTHANDBOOK.png";
import academicPic from "../assets/academicPic.png";
import ParentsHandbook from "../assets/ParentsHandbook.pdf";
import assesmentPolicy from "../assets/AssessmentPolicy.pdf";
import TermCalendar from "../assets/2ndTermCalendar.pdf";
import AcademicCard from "../component/AcademicCard";
import schoolPic from "../assets/schoolPic.png";
import storyPic from "../assets/storyPic.jpg";
import {
  getAcademicCalender,
  academicCalenderPdf,
  parentsHandbookPdf,
  getPolicies,
} from "../APIs/Repos";
import moment from "moment";

export default function OurSchool() {
  //#region Variables
  const [academicCalenderList, setAcademicCalenderList] = useState([]);
  let [academicCalenderPDF, setAcademicCalenderPDF] = useState([]);
  const [academicPDF, setAcademicPDF] = useState("");
  let PDFLink = "";

  let [parentsHandBookPDF, setParentsHandBookPDF] = useState([]);
  const [handbookPDF, setHandbookPDF] = useState("");
  let HandbookPDFLink = "";

  var [policiesData, setPoliciesData] = useState([]);
  const [behaviorPolicy, setBehaviorPolicy] = useState("");
  const [aimsOfBhvrPolicy, setAimsOfBhvrPolicy] = useState("");
  const [codeOfConduct, setCodeOfConduct] = useState("");
  //#endregion

  //#region other functions
  useEffect(() => {
    getAcademicCalenderApiCall();
    academicCalenderPdfApiCall();
    parentsHandbookPdfApiCall();
    getPoliciesApiCall();
  }, []);

  //#endregion

  //#region API call
  const getAcademicCalenderApiCall = () => {
    getAcademicCalender()
      .then((res) => {
        if (res.data.success) {
          setAcademicCalenderList(res.data.result);
        }
        console.log("getAcademicCalender Response", res.data.result);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const academicCalenderPdfApiCall = () => {
    academicCalenderPdf()
      .then((res) => {
        if (res.data.success) {
          setAcademicCalenderPDF((academicCalenderPDF = res.data.result));
          for (let index = 0; index < academicCalenderPDF.length; index++) {
            const element = academicCalenderPDF[index];
            setAcademicPDF(element.pdf_path);
          }
        }
        console.log("academicCalenderPdf Response", res.data.result);
      })
      .catch((error) => {
        console.log("academicCalenderPdf Error");
      });
  };

  PDFLink = process.env.REACT_APP_API_URL + academicPDF;

  const parentsHandbookPdfApiCall = () => {
    parentsHandbookPdf()
      .then((res) => {
        if (res.data.success) {
          setParentsHandBookPDF((parentsHandBookPDF = res.data.result));
          for (let index = 0; index < parentsHandBookPDF.length; index++) {
            const element = parentsHandBookPDF[index];
            setHandbookPDF(element.pdf_path);
          }
        }
        console.log("parentsHandbookPdf Response", res.data.result);
      })
      .catch((error) => {
        console.log("parentsHandbookPdf Error", error);
      });
  };

  HandbookPDFLink = process.env.REACT_APP_API_URL + handbookPDF;

  const getPoliciesApiCall = () => {
    getPolicies()
      .then((res) => {
        if (res.data.success) {
          setPoliciesData((policiesData = res.data.result));
          for (let index = 0; index < policiesData.length; index++) {
            const element = policiesData[index];
            if (element.title == "Behavior Policy")
              setBehaviorPolicy(element.paragraph);
            if (element.title == "Aims Of The Behavior Policy")
              setAimsOfBhvrPolicy(element.paragraph);
            if (element.title == "Code Of Conduct")
              setCodeOfConduct(element.paragraph);
          }
        } else {
          console.log("getPolicies MESSAGE", res.data.message);
        }
        console.log("getPolicies Response", res.data.result);
      })
      .catch((error) => {
        console.log("getPolicies ERROR", error);
      });
  };

  //#endregion
  return (
    <>
      <div className="our__school__container">
        <img
          src={ourSchoolBg}
          alt="ourSchoolBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="598"
                height="42"
                viewBox="0 0 598 42"
              >
                <rect
                  id="Rectangle_190"
                  data-name="Rectangle 190"
                  width="598"
                  height="42"
                  rx="21"
                  fill="#ec5700"
                />
              </svg>
              <div className="our__school__overlay__content__heading">
                OUR school
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our__school__main__content__wrapper">
        <div className="our__story__main__container__content__reverse">
          <img
            src={schoolPic}
            alt="ourVisionPic"
            className="our__story__main__container__content__img"
          />
          <div
            id="ourschool"
            className="our__story__main__container__content__left__reverse"
          >
            <div className="our__story__main__container__content__left__heading">
              Our School
            </div>
            <div className="our__story__main__container__content__left__paragraph">
              In 1979, Lahore Grammar School opened its doors to a handful of
              students. We’ve come a long way since then! From the very
              beginning, however, the philosophy of the school has been to
              combine collaborative learning, critical and logical thinking
              skills and a diverse co-curricular program to help create a more
              balanced, well-rounded individual, with interests and ideas, a
              questioning mind and a genuine love for learning. Today, Lahore
              Grammar School provides education to students till A Level. It has
              extended its network to cater to the needs of the middle-income
              group in its Landmark Schools. LGS has branches in Islamabad,
              Karachi, Peshawar, Quetta, Multan, Gujranwala, Sialkot, Faisalabad
              and Gujrat. It offers quality education to the low-income group
              through the Lahore Education Society School, entirely supported
              and funded by LGS.
            </div>
          </div>
        </div>
        <div id="ourstory" className="our__story__main__container__content">
          <div className="our__story__main__container__content__left">
            <div className="our__story__main__container__content__left__heading">
              Our Story
            </div>
            <div className="our__story__main__container__content__left__paragraph">
              Lahore Grammar School, Faisalabad is committed to provide high
              quality education through affiliation with the Cambridge Board of
              International Examinations for O and A Level programs and Board of
              Intermediate and Secondary Education for Matriculation. Both
              academic vistas ensure excellent progression routes to higher
              education for our students. Our students have been placed in some
              of the best national and international colleges and universities.
              Today we have a large pool of enterprising alumni proving their
              mettle in a variety of professions.
              <br />
              <br />
              Moreover, our Early Years, Primary and Middle schools conform to
              stringent quality assurance measures to teach a curriculum that is
              appropriately engaging yet challenging to help attain expectations
              set for various grade levels. Our curriculum is duly aided and
              complemented by a well-rounded year-long program for extra and
              co-curricular activities.
            </div>
          </div>
          <img
            src={storyPic}
            alt="storyPic"
            className="our__story__main__container__content__img"
          />
        </div>
        <div
          id="ourvision"
          className="our__story__main__container__content__reverse"
        >
          <img
            src={ourVisionPic}
            alt="ourVisionPic"
            className="our__story__main__container__content__img"
          />
          <div className="our__story__main__container__content__left__reverse">
            <div className="our__story__main__container__content__left__heading">
              Our Vision
            </div>
            <div className="our__story__main__container__content__left__paragraph">
              Our vision is a holistic and dynamic one that appreciates
              diversity and stresses the importance of opinions based on
              rational and informed premises rather that on superstition,
              emotion and insufficient understanding of issues. Emphasis is laid
              on creating a culture of thinking in the classroom and education
              is seen as a broad and complex process of acquiring both knowledge
              and understanding. We aspire to make our students aware of
              contemporary issues, both national and worldwide, and their
              personal role not only in developing an understanding of these
              issues but prompting a desire to translate that knowledge into
              active commitment and take steps, where possible, to bring about
              positive change in their environment.
            </div>
            <div className="our__story__main__container__content__left__paragraph">
              We have taken several initiatives in the field of education in
              Pakistan, including the promotion of the performing arts which we
              felt were being neglected at great cost to our cultural tradition
              and a healthier social milieu. LGS also lays great emphasis on the
              teaching of regional languages so that our students develop an
              appreciation of their cultural roots and in the rich traditions of
              literature and music of their province.
            </div>
            <div className="our__story__main__container__content__left__paragraph">
              We take great pride in the fact that community service is a
              critical element of our programme, where our students are
              encouraged to share their skills and learn from the courage and
              resilience of those less fortunate than themselves.
            </div>
          </div>
        </div>

        <div className="from__the__pricipal__container__wrapper">
          <div
            className="from__the__pricipal__container"
            style={{ width: "100%", marginRight: 0 }}
          >
            <div className="from__the__pricipal__container__heading">
              Mrs. Saltanat Bokhari - Director
            </div>
            <div className="from__the__pricipal__container__para">
              I am delighted to offer you a warm welcome to LGS FSD. At LGS.
              FSD, we view diversity as our biggest strength. We aim to inspire
              in our students the curiosity to learn, the self-confidence to
              lead and the compassion to serve. Our primary source of insight
              and energy is our students’ validation, critique and innovative
              ideas. We mutually guide and encourage one another to understand
              the importance of not just academic achievement but also values of
              tolerance and respect. I hope our website gives you a sense of our
              purpose and vision. -
            </div>
          </div>
          <div className="from__the__pricipal__container">
            <img
              src={MeenaGul}
              alt="pricipal_pic"
              className="from__the__pricipal__container__img"
            />

            <div className="from__the__pricipal__container__heading">
              Ms. Meena Gul - Regional Director
            </div>
            <div className="from__the__pricipal__container__para">
              With an experience of more than 35 years as an educationist, Ms.
              Meena Gul serves as a Regional Director at LGS Faisalabad. Through
              her leadership, Ms. Meena has been organising and implementing
              training programs for staff aimed at achieving their academic
              goals by the end of the year. Through weekly, monthly and annual
              staff evaluation reports, a systematic approach is taken while
              mentoring and training the staff members.
            </div>
          </div>
          <div className="from__the__pricipal__container">
            <img
              src={AliaRehman}
              alt="pricipal_pic"
              className="from__the__pricipal__container__img"
            />

            <div className="from__the__pricipal__container__heading">
              Mrs. Alia Noman - Regional Coordinator
            </div>
            <div className="from__the__pricipal__container__para">
              What sets Lahore Grammar School Faisalabad apart is that its
              students exude a strong commitment in developing a personal
              responsibility in all pursuits and endeavours, striving to achieve
              academic success, sporting prowess, artistic and creative
              excellence and develop social as well as moral mind set. Here, the
              student’s potential is nurtured in a stimulating educational
              environment, inspiring them to achieve their goals. Our students
              are encouraged to be resilient in their learning and we at Lahore
              Grammar School, ensure that they are valued and cared for.
            </div>
          </div>
          {/* <div className="from__the__pricipal__container">
            <img
              src={pricipal_pic}
              alt="pricipal_pic"
              className="from__the__pricipal__container__img"
            />

            <div className="from__the__pricipal__container__heading">
              Mr. Imran Shahid - Principal
            </div>
            <div className="from__the__pricipal__container__para">
              It gives me immense pleasure and a feeling of sheer pride and
              accomplishment to address you as Principal of the prestigious
              Lahore Grammar school, Faisalabad, an institution that has striven
              to maintain high academic standards ever since its inception. Our
              commitment is to provide our students with an array of
              opportunities, both curricular and co-curricular, to help them
              attain their highest potentials. Our aim is not just giving our
              students an education that entitles them to a job but to nurture
              in them lifelong love for learning and inculcate in them values
              that will help them become kind, caring and responsible
              individuals. I feel privileged to be working with highly qualified
              and dedicated faculty always striving to provide students with the
              latest skills and knowledge to help them stand out in this ever
              evolving and fiercely competitive world. Our school life is
              vibrant with a variety of activities, happy noises of learning and
              positive energy. I welcome you to LGS, Faisalabad and assure you
              that it will be our endeavor to make our students’ school life an
              enriching and memorable experience!
            </div>
          </div> */}
          <div className="from__the__pricipal__container">
            <img
              src={irfanaAziz}
              alt="pricipal_pic"
              className="from__the__pricipal__container__img"
            />

            <div className="from__the__pricipal__container__heading">
              Mrs. Irfana Aziz - PRINCIPAL
            </div>
            <div className="from__the__pricipal__container__para">
              Welcome to the official website of Lahore Grammar School,
              Faisalabad; a prestigious institution that has been providing high
              quality learning opportunities and experiences to young minds of
              the city for more than two decades. We at LGS, Faisalabad strive
              to make education an enjoyable and enlightening process. Our
              school life is an excellent amalgamation of analytical,
              intellectual, and healthy physical growth of our students along
              with inculcating human values in them. We are also proud to have a
              faculty comprising of highly qualified individuals always doing
              their best to develop and nurture the young minds of today into
              well-rounded global citizens of tomorrow!
            </div>
          </div>
        </div>
        <div
          id="parentshandbook"
          className="parents__handbook__main__container"
        >
          <div className="parents__handbook__main__container__left">
            <div className="parents__handbook__main__container__left__heading">
              Parents Handbook
            </div>
            <div className="parents__handbook__main__container__left__paragraph">
              This Parents’ Handbook aims to provide you with the necessary
              information regarding school rules and policies. However, if you
              would like further clarification or have some queries of your own,
              please feel free to call our school office for additional
              information. Our website, www.lgs.edu.pk is also a good source of
              information about our various policies (Behaviour Policy, Academic
              Policy, Anti- Harassment Policy) as well as other information
              about school life in general.
            </div>
            <a
              href={HandbookPDFLink}
              style={{
                width: 180,
                marginTop: "1em",
                textAlign: "center",
                textDecoration: "none",
              }}
              className="btn__white"
              target="_blank"
            >
              Download
            </a>
          </div>
          <img
            src={PARENTHANDBOOK}
            alt="parentHandbookPic"
            className="parents__handbook__main__container__img"
          />
        </div>
        <div className="assignment__main__container__new">
          <div className="assignment__main__container__heading">
            Assessment Policy for Classes
          </div>

          <div
            className="assignment__main__container__paragraph"
            style={{ textAlign: "center", marginBottom: 10 }}
          >
            In view of the ongoing challenges presented by the Covid-19
            pandemic, we are continuing with our revised assessment policy from
            2022.
          </div>
          <a
            href={assesmentPolicy}
            target="_blank"
            className="section__btn__primary"
            style={{
              width: "fit-content",
              height: "fit-content",
              textDecoration: "none",
              margin: "0em auto",
            }}
          >
            Assesment Policy
          </a>
        </div>
      </div>
      <div id="academiccalendar" className="academic__main__container">
        <div className="academic__main__container__content">
          <img
            src={academicBg}
            alt="academicBg"
            className="academic__main__container__img"
          />
          <div className="academic__main__container__content__overlay">
            <div className="academic__main__container__content__overlay__content">
              <div className="academic__main__container__content__overlay__heading">
                academic calender
              </div>
              <a
                href={PDFLink}
                target="_blank"
                style={{
                  width: 180,
                  marginTop: ".6em",
                  marginBottom: "2em",
                  textDecoration: "none",
                }}
                className="btn__white"
              >
                Download
              </a>
              <img
                src={academicPic}
                alt="academicPic"
                className="academic__main__container__content__overlay__pic"
              />
              <div className="academic__main__container__content__entry__wrapper">
                {academicCalenderList != null && academicCalenderList.length > 0
                  ? academicCalenderList.map((data) => (
                      <AcademicCard
                        month={
                          moment(data.start_date).format("MMM") +
                          " - " +
                          moment(data.end_date).format("MMM")
                        }
                        date={
                          moment(data.start_date).format("DD-MM") +
                          " - " +
                          moment(data.end_date).format("DD-MM")
                        }
                        voca={data.break_type + " Break"}
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="behaviourpolicy"
        style={{ marginBottom: "4em" }}
        className="behaviour__policy__container"
      >
        <div className="behaviour__policy__container__heading">
          Behavior Policy
        </div>
        <div
          className="behaviour__policy__container__para"
          dangerouslySetInnerHTML={{ __html: behaviorPolicy }}
        >
          {/* {behaviorPolicy} */}
        </div>
        <div className="aims__behaviour__policy__container">
          <div className="aims__behaviour__policy__container__left">
            <div className="behaviour__policy__container__heading__dark">
              Aims of the Behaviour Policy
            </div>
            <div
              className="behaviour__policy__container__para__dark"
              dangerouslySetInnerHTML={{ __html: aimsOfBhvrPolicy }}
            >
              {/* {aimsOfBhvrPolicy} */}
            </div>
          </div>
          <img
            src={AIRMS}
            alt="AIRMS"
            className="aims__behaviour__policy__container__right"
          />
        </div>

        <div
          id="codeofconduct"
          className="behaviour__policy__container__heading"
        >
          Code of Conduct
        </div>
        <div
          className="behaviour__policy__container__para"
          dangerouslySetInnerHTML={{ __html: codeOfConduct }}
        >
          {/* {codeOfConduct} */}
        </div>
      </div>
    </>
  );
}
