import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import InputField from "../component/InputField";
import InputFile from "../component/InputFile";
import InputRadio from "../component/InputRadio";
import InputTextArea from "../component/InputTextArea";
import SelectBox from "../component/SelectBox";
import { getVacancy } from "../APIs/Repos";

const genderOption = [
  {
    label: "Male",
  },
  {
    label: "Female",
  },
];

export default function Career() {
  const [vacanciesList, setVacanciesList] = useState([]);

  useEffect(() => {
    getVacancyApiCall();
  }, []);

  const getVacancyApiCall = () => {
    getVacancy()
      .then((res) => {
        if (res.data.success) {
          setVacanciesList(res.data.result);
        } else {
          console.log("getVacancy MESSAGE", res.data.message);
        }
        console.log("getVacancy Response", res.data.result);
      })
      .catch((error) => {
        console.log("getVacancy ERROR", error);
      });
  };
  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="498"
                height="42"
                viewBox="0 0 598 42"
              >
                <rect
                  id="Rectangle_190"
                  data-name="Rectangle 190"
                  width="598"
                  height="42"
                  rx="21"
                  fill="#ec5700"
                />
              </svg>
              <div
                style={{
                  textAlign: "center",
                }}
                className="our__school__overlay__content__heading"
              >
                Careers
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career__section">
        <div
          className="career__section__content"
          style={{ marginBottom: "5em" }}
        >
          <div className="vacency__heading">Vacancies</div>
          <div className="vacency__list">
            {vacanciesList != null && vacanciesList.length > 0
              ? vacanciesList.map((data) => (
                  <VacencyEntry vacancyDataToPass={data} />
                ))
              : null}
            {/* <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry />
            <VacencyEntry /> */}
          </div>
        </div>
      </div>
    </>
  );
}

function VacencyEntry({ vacancyDataToPass }) {
  let pdfLink = process.env.REACT_APP_API_URL + vacancyDataToPass.pdf;
  return (
    <div className="vacency__list__entry">
      <div className="vacency__list__entry__content">
        <div className="vacency__list__entry__content__heading">
          {vacancyDataToPass.title}
        </div>
        <div className="vacency__list__entry__content__sub__heading">
          {vacancyDataToPass.campus_name}
        </div>
      </div>
      <a
        href={pdfLink}
        target="_blank"
        className="vacency__list__entry__download"
      >
        Download details
      </a>
      <Link
        to="/career-registration"
        state={{ vacancyDataToRegister: vacancyDataToPass }}
        onClick={() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 100);
        }}
        className="vacency__list__entry__apply"
      >
        Apply Now
      </Link>
    </div>
  );
}
