import React, { useState, useEffect } from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import NoticeBoardCard from "../component/NoticeBoardCard";
import FacultyCard from "../component/FacultyCard";
import cannalCampusPricipalPic from "../assets/cannalCampusPricipalPic.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  AmmaraMohsin,
  JunaidAkram,
  KanwalShabaz,
  NailaSalman,
  Rabeeaomer,
  RohanTariq,
  SohailHaider,
  TahirNaushad,
  ZahidHussain,
} from "../assets";
import FolderGallery from "../component/FolderGallery";
import { useLocation } from "react-router-dom";
import {
  getAllSchoolEventById,
  getMessagesBySchoolId,
  getFacultyProfileBySchoolId,
  getNoticeBoardBySchoolId,
} from "../APIs/Repos";

function CampusNav({ title, select, setSelect }) {
  return (
    <button
      onClick={() => {
        setSelect(title);
      }}
      className={
        select === title ? "cannal__campus__nav__select" : "cannal__campus__nav"
      }
    >
      {title}
    </button>
  );
}
function SubjectBtn({ title }) {
  return <button className="cannal__campus__btn">{title}</button>;
}

function PricipalCard({ img, title, designation, para }) {
  return (
    <>
      <div className="cannal__campus__container__swiper__content">
        <div className="cannal__campus__container__wrapper__card__left">
          <div className="cannal__campus__container__wrapper__card__left__content">
            <img
              src={img}
              alt="pricipal_pic"
              className="cannal__campus__container__wrapper__card__left__content__img"
            />
            <div className="cannal__campus__container__wrapper__card__left__content__headings__wrapper">
              <div className="cannal__campus__container__wrapper__card__left__content__heading">
                {title}
              </div>
              <div className="cannal__campus__container__wrapper__card__left__content__para">
                {designation}
              </div>
            </div>
          </div>
        </div>
        <div
          className="cannal__campus__container__wrapper__card__right"
          dangerouslySetInnerHTML={{ __html: para }}
        >
          {/* "{para}" */}
        </div>
      </div>
    </>
  );
}

export default function SeniorSchoolALevelCanalCampus() {
  //#region Variables
  const location = useLocation();
  const [eventsArray, setEventsArray] = useState([]);
  var [schoolId, setSchoolId] = useState(0);
  var [schoolName, setSchoolName] = useState("");

  const [messagesArray, setMessagesArray] = useState([]);
  const [facultyProfileArrayArray, setFacultyProfileArrayArray] = useState([]);
  const [noticeBoardArray, setNoticeBoardArray] = useState([]);

  const [select, setSelect] = useState("Subject Choices");
  //#endregion
  // const [select, setSelect] = useState("Subject Choices");
  const subjectChoices = [
    "World History – 9489",
    "Psychology – 9990",
    "Economics - 9708",
    "Mathematics - 9709",
    "Sociology – 9699",
    "Urdu – 9686",
    "Chemistry - 9701",
    "Accounting - 9706",
    "English Literature - 9093",
    "Computer Science - 9618",
    "Travel and Tourism – 9395",
    "Business – 9609",
    "Physics - 9702",
    "Information Technology - 9626",
    "Law - 9084",
  ];
  const facultyList = [
    {
      img: SohailHaider,
      name: "Sohail Haider",
      subject:
        "A- Level Academic Coordinator, Qualification: MPhil in English Literature, Experience: 19 years, Subjects: Sociology A Level & English Language (IGCSE, GCE & A Levels)",
    },
    {
      img: JunaidAkram,
      name: "Junaid Akram",
      subject:
        "Accounting/Business 8 years of teaching experience in IGCSE, O level and A level",
    },
    {
      img: RohanTariq,
      name: "Rohan Tariq",
      subject: "Mathematics(O Levels & A Levels) Experience 8 Years",
    },
    {
      img: TahirNaushad,
      name: "Tahir Naushad",
      subject: "Urdu O & A-Level 21 years experience",
    },
    {
      img: KanwalShabaz,
      name: "Kanwal Shabazz",
      subject: "Phycology 8 years",
    },
    {
      img: ZahidHussain,
      name: "ZAHID HUSSAIN",
      subject: "BIOLOGY 18 YEARS",
    },
    {
      img: AmmaraMohsin,
      name: "Ammara Mohsin",
      subject: "Chemistry O & A Level 6 years of experience",
    },
    {
      img: Rabeeaomer,
      name: "Rabeea omer",
      subject:
        "Economics/ Business studies M-phill Economics 13 years of teaching experience in University  & O/A levels",
    },
    {
      img: NailaSalman,
      name: "Naila Salman",
      subject: "Chemistry O/A level 12 years",
    },
    {
      img: "",
      name: "Ghulam Abbas Saqib",
      subject: "Mathematics O and A level  experience 15 years",
    },
    {
      img: "",
      name: "Ali Nadeem Shibli",
      subject: "Law English Lang and Literature 6 years O/A levels",
    },
    {
      img: "",
      name: "Umar Bilal",
      subject: "Business 14 years O/A Levels",
    },
    {
      img: "",
      name: "Jawaad Bin Mansoor",
      subject: "B.Sc.",
    },
    {
      img: "",
      name: "Muhammad Shafiq",
      subject: "M.Sc. (Phy)",
    },
    {
      img: "",
      name: "Muhammad Shahzad Miran",
      subject: "B.A",
    },
  ];

  //#region other function
  useEffect(() => {
    setSchoolId(
      (schoolId = location.state ? location.state.schoolDataToPass : 0)
    );

    getAllSchoolEventByIdApiCall();
    getMessagesBySchoolIdApiCall();
    getFacultyProfileBySchoolIdApiCall();
    getNoticeBoardBySchoolIdApiCall();
  }, []);
  //#endregion

  //#region API Calls

  const getAllSchoolEventByIdApiCall = () => {
    getAllSchoolEventById(schoolId)
      .then((res) => {
        if (res.data.success) {
          setEventsArray(res.data.result);
        } else {
          // alert(res.data.message);
        }
        console.log("getAllSchoolEventById Response", res.data.result);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getMessagesBySchoolIdApiCall = () => {
    getMessagesBySchoolId(schoolId)
      .then((res) => {
        if (res.data.success) {
          setMessagesArray(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getFacultyProfileBySchoolIdApiCall = () => {
    getFacultyProfileBySchoolId(schoolId)
      .then((res) => {
        if (res.data.success) {
          setFacultyProfileArrayArray(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getNoticeBoardBySchoolIdApiCall = () => {
    getNoticeBoardBySchoolId(schoolId)
      .then((res) => {
        if (res.data.success) {
          setNoticeBoardArray(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  //#endregion
  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="cannal__campus__heading__wrapper">
              <div className="cannal__campus__sub__heading">CaNAL CAMPUS</div>
              <div className="cannal__campus__heading">Senior School</div>
              <div className="cannal__campus__para">A Levels</div>
            </div>
          </div>
        </div>
      </div>
      <div className="cannal__campus__container">
        <div className="cannal__campus__container__wrapper__card">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 8500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {messagesArray != null && messagesArray.length > 0
              ? messagesArray.map((data) => (
                  <SwiperSlide>
                    <PricipalCard
                      img={
                        data.picture_path
                          ? process.env.REACT_APP_API_URL + data.picture_path
                          : ""
                      }
                      title={data.name}
                      designation={data.designation}
                      para={data.message}
                    />
                  </SwiperSlide>
                ))
              : null}
            {/* <SwiperSlide>
              <PricipalCard
                img={cannalCampusPricipalPic}
                title="Mr. Sohail Haider"
                designation="A-Levels Coordinator"
                para="We live in exciting and rapidly changing times; these times mean that education is more important than ever. Education that prepares young people to be able to be life-long learners, to be able to adapt and adjust to the many changes they will face in their future. 

                At LGS, the growth of each individual student is a focus, we recognise that as we 'work together and learn together' students will be best able to achieve their potential.  The co-operative effort of students, parents and teachers is at the heart of the learning pathways followed by our students and the outcomes they achieve.
                
                We firmly believe that every student can experience success and go on to graduate as a responsible, resilient and confident young adult, well prepared to take charge of their future and to become a productive member of the country.
                Our school prides itself on the 'multiple learning outcomes' curricular and co-curricular programs available to students, on the highly positive attitude and behavior of our students and on the constant focus by our professional staff on developing, personalising and transforming teaching approaches to best meet the needs of students."
              />
            </SwiperSlide> */}
          </Swiper>
        </div>
        <div className="cannal__campus__nav__wrapper">
          <CampusNav
            select={select}
            setSelect={setSelect}
            title="Subject Choices"
          />

          <CampusNav title="Gallery" select={select} setSelect={setSelect} />
          <CampusNav
            title="Faculty Profiles"
            select={select}
            setSelect={setSelect}
          />
          <CampusNav
            title="Notice board"
            select={select}
            setSelect={setSelect}
          />
        </div>
        {select === "Subject Choices" ? (
          <div className="cannal__campus__btn__wrapper">
            {subjectChoices.map((subjectChoice) => (
              <SubjectBtn title={subjectChoice} />
            ))}
          </div>
        ) : null}
        {select === "Gallery" ? (
          <FolderGallery eventsArray={eventsArray} />
        ) : null}
        {select === "Faculty Profiles" ? (
          <>
            <div className="faculty__section__heading">Faculty Profiles</div>
            <div className="faculty__section__card__wrapper">
              {facultyProfileArrayArray != null &&
              facultyProfileArrayArray.length > 0
                ? facultyProfileArrayArray.map((item) => (
                    <FacultyCard data={item} />
                  ))
                : null}
            </div>
          </>
        ) : null}
        {select === "Notice board" ? (
          <div style={{ marginTop: "4em" }} className="notice__board__section">
            <div className="notice__board__section__content__heading">
              NOTICE BOARD
            </div>
            <div className="notice__board__section__content__paragraph">
              Important Notices will be uploaded here whenever possible.
              However, please make sure that your current cell number has been
              entered in the School database as our primary means of
              communication with parents and students is via SMS.Prep Curriculum
              Outline
            </div>
            {noticeBoardArray != null && noticeBoardArray.length > 0
              ? noticeBoardArray.map((data) => (
                  <NoticeBoardCard
                    title={data.title}
                    description={data.description}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>
    </>
  );
}
