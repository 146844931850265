import React, { useState } from "react";
import ourCampusBg from "../assets/ourCampusBg.jpg";
import campusPic from "../assets/campusPic.png";
import AlumniRegistrationsPic from "../assets/AlumniRegistrationsPic.png";
import ReunionsPic from "../assets/ReunionsPic.png";
import overviewPic from "../assets/overviewPic.png";
import { Link } from "react-router-dom";
import InputField from "../component/InputField";
import { addAlumniRegistration } from "../APIs/Repos";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { VALIDATION_REGEX } from "../Constants";
toast.configure();

function AluminiGalleryCard({ title }) {
  return (
    <Link
      to="/alumni-card-details"
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
      className="campus__life__main__container__content__card"
    >
      <img
        src={campusPic}
        alt="campusPic"
        className="campus__life__main__container__content__card__img"
      />
      <div className="campus__life__main__container__content__card__overlay">
        <div className="campus__life__main__container__content__card__overlay__heading">
          {title}
        </div>
      </div>
    </Link>
  );
}

export default function Alumini() {
  //#region Variables
  var [name, setName] = useState("");
  var [fatherName, setFatherName] = useState("");
  var [email, setEmail] = useState("");
  var [session, setSession] = useState("");
  var [phoneNumber, setPhoneNumber] = useState("");
  var [education, setEducation] = useState("");
  var [graduationUniversity, setGraduationUniversity] = useState("");
  var [postGraduationUniversity, setPostGraduationUniversity] = useState("");
  var [profession, setProfession] = useState("");
  var [currenBusinessAddress, setCurrentBusinessAddress] = useState("");
  var [lifeAfterLgs, setLifeAfterLgs] = useState("");
  var [fondestMemory, setFondestMemory] = useState("");
  var [isNameRequired, setIsNameRequired] = useState(true);
  var [isFatherNameRequired, setIsFatherNameRequired] = useState(true);
  var [isEmailRequired, setIsEmailRequired] = useState(true);
  var [isPhoneRequired, setIsPhoneRequired] = useState(true);
  var [isSessionRequired, setIsSessionRequired] = useState(true);
  var [isEducationRequired, setIsEducationRequired] = useState(true);
  var [isGraduationUniRequired, setIsGraduationUniRequired] = useState(true);
  var [isPostGraduationUniRequired, setIsPostGraduationUniRequired] =
    useState(true);
  var [isProfessionRequired, setIsProfessionRequired] = useState(true);
  var [isCurrentBusinessRequired, setIsCurrentBusinessRequired] =
    useState(true);
  var [isLifeAfterLgsRequired, setIsLifeAfterLgsRequired] = useState(true);
  var [isFondestMemoryRequired, setIsFondestMemoryRequired] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region API Call

  const addAlumniRegistrationApiCall = () => {
    let data = {
      id: 0,
      name: name,
      father_name: fatherName,
      email: email,
      phone_no: phoneNumber,
      session: session,
      education: education,
      graduation_university: graduationUniversity,
      post_graduation_university: postGraduationUniversity,
      profession: profession,
      current_business_address: currenBusinessAddress,
      life_after_lgs: lifeAfterLgs,
      fondest_memory: fondestMemory,
    };
    if (isViewValid()) {
      setIsLoading(true);
      addAlumniRegistration(data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            toast("Registered Successfully!");
            setName("");
            setFatherName("");
            setEducation("");
            setPhoneNumber("");
            setEmail("");
            setSession("");
            setGraduationUniversity("");
            setPostGraduationUniversity("");
            setProfession("");
            setCurrentBusinessAddress("");
            setLifeAfterLgs("");
            setFondestMemory("");
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert(error);
        });
    }
  };
  //#endregion

  //#region Name Validation

  const onNameTextChangeListener = (event) => {
    setName((name = event.currentTarget.value));
    setNameErrorMessageAndVisibilityAndIsValid(name);
  };

  const setNameErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setNameErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.ALPHABETS_ONLY.test(enterdText))
      setNameErrorMessageAndVisibility(true);
    else {
      setNameErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setNameErrorMessageAndVisibility = (visibility) => {
    setIsNameRequired(visibility);
  };
  //#endregion

  //#region Father Name Validation

  const onFatherNameTextChangeListener = (event) => {
    setFatherName((fatherName = event.currentTarget.value));
    setFatherNameErrorMessageAndVisibilityAndIsValid(fatherName);
  };

  const setFatherNameErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setFatherNameErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.ALPHABETS_ONLY.test(enterdText))
      setFatherNameErrorMessageAndVisibility(true);
    else {
      setFatherNameErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setFatherNameErrorMessageAndVisibility = (visibility) => {
    setIsFatherNameRequired(visibility);
  };
  //#endregion

  //#region Email Validation

  const onEmailTextChangeListener = (event) => {
    setEmail((email = event.currentTarget.value));
    setEmailErrorMessageAndVisibilityAndIsValid(email);
  };

  const setEmailErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setEmailErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.EMAIL_REGEX.test(enterdText))
      setEmailErrorMessageAndVisibility(true);
    else {
      setEmailErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setEmailErrorMessageAndVisibility = (visibility) => {
    setIsEmailRequired(visibility);
  };
  //#endregion

  //#region Phone Number Validation

  const onPhoneNumberTextChangeListener = (event) => {
    setPhoneNumber((phoneNumber = event.currentTarget.value));
    setPhoneNumberErrorMessageAndVisibilityAndIsValid(phoneNumber);
  };

  const setPhoneNumberErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setPhoneNumberErrorMessageAndVisibility(true);
    else if (!VALIDATION_REGEX.PAKISTAN_NUMBER.test(enterdText))
      setPhoneNumberErrorMessageAndVisibility(true);
    else {
      setPhoneNumberErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPhoneNumberErrorMessageAndVisibility = (visibility) => {
    setIsPhoneRequired(visibility);
  };
  //#endregion

  //#region Session Validation

  const onSessionTextChangeListener = (event) => {
    setSession((session = event.currentTarget.value));
    setSessionErrorMessageAndVisibilityAndIsValid(session);
  };

  const setSessionErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setSessionErrorMessageAndVisibility(true);
    else {
      setSessionErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setSessionErrorMessageAndVisibility = (visibility) => {
    setIsSessionRequired(visibility);
  };
  //#endregion

  //#region Education Validation

  const onEducationTextChangeListener = (event) => {
    setEducation((education = event.currentTarget.value));
    setEducationErrorMessageAndVisibilityAndIsValid(education);
  };

  const setEducationErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setEducationErrorMessageAndVisibility(true);
    else {
      setEducationErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setEducationErrorMessageAndVisibility = (visibility) => {
    setIsEducationRequired(visibility);
  };
  //#endregion

  //#region Graduation University Validation

  const onGraduationUniversityTextChangeListener = (event) => {
    setGraduationUniversity((graduationUniversity = event.currentTarget.value));
    setGraduationUniversityErrorMessageAndVisibilityAndIsValid(
      graduationUniversity
    );
  };

  const setGraduationUniversityErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setGraduationUniversityErrorMessageAndVisibility(true);
    else {
      setGraduationUniversityErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setGraduationUniversityErrorMessageAndVisibility = (visibility) => {
    setIsGraduationUniRequired(visibility);
  };
  //#endregion

  //#region Post Graduation University Validation

  const onPostGraduationUniversityTextChangeListener = (event) => {
    setPostGraduationUniversity(
      (postGraduationUniversity = event.currentTarget.value)
    );
    setPostGraduationUniversityErrorMessageAndVisibilityAndIsValid(
      postGraduationUniversity
    );
  };

  const setPostGraduationUniversityErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setPostGraduationUniversityErrorMessageAndVisibility(true);
    else {
      setPostGraduationUniversityErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPostGraduationUniversityErrorMessageAndVisibility = (visibility) => {
    setIsPostGraduationUniRequired(visibility);
  };
  //#endregion

  //#region Profession Validation

  const onProfessionTextChangeListener = (event) => {
    setProfession((profession = event.currentTarget.value));
    setProfessionErrorMessageAndVisibilityAndIsValid(profession);
  };

  const setProfessionErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText)) setProfessionErrorMessageAndVisibility(true);
    else {
      setProfessionErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setProfessionErrorMessageAndVisibility = (visibility) => {
    setIsProfessionRequired(visibility);
  };
  //#endregion

  //#region current business Validation

  const onCurrentBusinessAddressTextChangeListener = (event) => {
    setCurrentBusinessAddress(
      (currenBusinessAddress = event.currentTarget.value)
    );
    setCurrentBusinessAddressErrorMessageAndVisibilityAndIsValid(
      currenBusinessAddress
    );
  };

  const setCurrentBusinessAddressErrorMessageAndVisibilityAndIsValid = (
    enterdText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setCurrentBusinessAddressErrorMessageAndVisibility(true);
    else {
      setCurrentBusinessAddressErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setCurrentBusinessAddressErrorMessageAndVisibility = (visibility) => {
    setIsCurrentBusinessRequired(visibility);
  };
  //#endregion

  //#region Life after LGS Validation

  const onLifeAfterLgsTextChangeListener = (event) => {
    setLifeAfterLgs((lifeAfterLgs = event.currentTarget.value));
    setLifeAfterLgsErrorMessageAndVisibilityAndIsValid(lifeAfterLgs);
  };

  const setLifeAfterLgsErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setLifeAfterLgsErrorMessageAndVisibility(true);
    else {
      setLifeAfterLgsErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setLifeAfterLgsErrorMessageAndVisibility = (visibility) => {
    setIsLifeAfterLgsRequired(visibility);
  };
  //#endregion

  //#region Fondest Memory Validation

  const onFondestMemoryTextChangeListener = (event) => {
    setFondestMemory((fondestMemory = event.currentTarget.value));
    setFondestMemoryErrorMessageAndVisibilityAndIsValid(fondestMemory);
  };

  const setFondestMemoryErrorMessageAndVisibilityAndIsValid = (enterdText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enterdText))
      setFondestMemoryErrorMessageAndVisibility(true);
    else {
      setFondestMemoryErrorMessageAndVisibility(false);
      isViewValid = true;
    }
    return isViewValid;
  };

  const setFondestMemoryErrorMessageAndVisibility = (visibility) => {
    setIsFondestMemoryRequired(visibility);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setNameErrorMessageAndVisibilityAndIsValid(name);

    if (viewValid)
      viewValid = setFatherNameErrorMessageAndVisibilityAndIsValid(fatherName);
    if (viewValid)
      viewValid = setEmailErrorMessageAndVisibilityAndIsValid(email);
    if (viewValid)
      viewValid =
        setPhoneNumberErrorMessageAndVisibilityAndIsValid(phoneNumber);
    if (viewValid)
      viewValid = setSessionErrorMessageAndVisibilityAndIsValid(session);
    if (viewValid)
      viewValid = setEducationErrorMessageAndVisibilityAndIsValid(education);
    if (viewValid)
      viewValid =
        setGraduationUniversityErrorMessageAndVisibilityAndIsValid(
          graduationUniversity
        );
    if (viewValid)
      viewValid =
        setPostGraduationUniversityErrorMessageAndVisibilityAndIsValid(
          postGraduationUniversity
        );
    if (viewValid)
      viewValid = setProfessionErrorMessageAndVisibilityAndIsValid(profession);
    if (viewValid)
      viewValid = setCurrentBusinessAddressErrorMessageAndVisibilityAndIsValid(
        currenBusinessAddress
      );
    if (viewValid)
      viewValid =
        setLifeAfterLgsErrorMessageAndVisibilityAndIsValid(lifeAfterLgs);
    if (viewValid)
      viewValid =
        setFondestMemoryErrorMessageAndVisibilityAndIsValid(fondestMemory);

    return viewValid;
  };
  //#endregion

  const genderOption = [
    {
      label: "Male",
    },
    {
      label: "Female",
    },
  ];
  return (
    <>
      <div className="our__school__container">
        <img
          src={ourCampusBg}
          alt="ourCampusBg"
          className="home__main__container__img"
        />
        <div className="home__main__container__overlay">
          <div className="our__school__container__overlay__content">
            <div className="our__school__overlay__content__heading__wrapper">
              <svg
                style={{ width: 230 }}
                xmlns="http://www.w3.org/2000/svg"
                width="308"
                height="42"
                viewBox="0 0 598 42"
              >
                <rect
                  id="Rectangle_190"
                  data-name="Rectangle 190"
                  width="598"
                  height="42"
                  rx="21"
                  fill="#ec5700"
                />
              </svg>
              <div className="our__school__overlay__content__heading">
                Alumni
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="careers__content__section">
        <div className="careers__content__section__cards__wrapper">
          <div className="careers__content__section__card__public__speaking">
            <div className="careers__content__section__card__public__speaking__left">
              <div className="careers__content__section__card__public__speaking__left__heading">
                Overview
              </div>
              <div className="careers__content__section__card__public__speaking__left__paragraph">
                It has been almost forty years since LGS FSD opened its doors to
                a handful of students. Today, our Alums make Worldwide as they
                cover the globe in their active involvement in a diverse range
                of fields. Stay connected to your home at LGS through Annual
                Reunions, The Alumane Page, the Mashal, and social media, and
                contribute to networking opportunities as they arise. Come
                visit! Arrange a meeting with the Alumni Relations Society,
                mentor a current student, or simply drop in for a cup of coffee
                with Mrs. Shah to share your experiences of the real world! We
                would be absolutely delighted to see you and discover what you
                have been up to! Remember to show your Pride and always stay a
                Grammarian at heart.
              </div>
            </div>
            <img
              src={overviewPic}
              alt="overviewPic"
              className="careers__content__section__card__public__speaking__img"
            />
          </div>
          <div className="careers__content__section__card__international__explore">
            <img
              src={AlumniRegistrationsPic}
              alt="AlumniRegistrationsPic"
              className="careers__content__section__card__international__explore__img"
            />
            <div className="careers__content__section__card__international__explore__overlay">
              <svg
                id="Group_218"
                data-name="Group 218"
                xmlns="http://www.w3.org/2000/svg"
                width="60.001"
                height="56.211"
                viewBox="0 0 60.001 56.211"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_210"
                      data-name="Rectangle 210"
                      width="60.001"
                      height="56.211"
                      fill="#fff"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Group_211"
                  data-name="Group 211"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Path_7976"
                    data-name="Path 7976"
                    d="M20.466,36.261c-.8-.623-1.531-1.138-2.2-1.728a17.575,17.575,0,0,1-6.2-13,1.882,1.882,0,0,0-.491-1.106,4.062,4.062,0,0,1-1.156-2.939c.016-1.409,0-2.818,0-4.227,0-1.87-.155-2.022-2.233-2.119q0,5.524,0,11.042c0,.469.014.94,0,1.409a2.157,2.157,0,0,1-2.222,2.278,2.123,2.123,0,0,1-2.084-2.294c-.019-4.154-.041-8.309.014-12.463.013-1.022-.217-1.582-1.3-1.755A8.327,8.327,0,0,1,0,8.412c.79-.244,1.576-.5,2.371-.73Q15.623,3.9,28.883.152A3.239,3.239,0,0,1,30.546.079Q44.8,4.087,59.035,8.164c.233.067.457.167.966.355-1.082.333-1.914.6-2.754.845-2.285.658-4.561,1.354-6.867,1.93a1.207,1.207,0,0,0-1.087,1.48c.073,1.514,0,3.034.027,4.551a4.159,4.159,0,0,1-1.275,3.218,1.344,1.344,0,0,0-.385.828,17.725,17.725,0,0,1-8.122,14.646.358.358,0,0,0-.115.14c2.044,1.222,4.168,2.3,6.084,3.674a28.165,28.165,0,0,1,9.916,13.112c.57,1.477.11,2.639-1.182,3.119-1.216.452-2.265-.133-2.845-1.585C48.37,46.9,43.133,41.645,35.141,39.57A22.319,22.319,0,0,0,10.667,49.725a39.551,39.551,0,0,0-2.385,4.721c-.671,1.481-1.684,2.09-2.93,1.589S3.68,54.39,4.277,52.87A28.351,28.351,0,0,1,19.433,36.736c.317-.143.632-.291,1.033-.475m22.674-13.4a56.2,56.2,0,0,1-13.281,1.476,57.525,57.525,0,0,1-13.285-1.467c.482,6.423,6.587,11.692,13.389,11.672,6.641-.02,12.759-5.385,13.177-11.68m.922-14.229.007-.247c-4.6-1.316-9.2-2.642-13.816-3.93a2.335,2.335,0,0,0-1.156.118c-2.224.609-4.442,1.237-6.659,1.869-2.26.644-4.517,1.3-7.066,2.034.588.214.875.338,1.172.423,2.6.741,5.275,1.278,7.78,2.257A14.811,14.811,0,0,0,35.5,11.133c2.773-1.054,5.706-1.686,8.567-2.506m.807,5.072c-.294.067-.571.117-.841.192-4.3,1.2-8.593,2.428-12.909,3.565a5.141,5.141,0,0,1-2.51-.006C24.291,16.313,20,15.08,15.7,13.886c-.287-.08-.586-.112-.98-.184a10.3,10.3,0,0,1-.013,1.543c-.157.947.257,1.374,1.111,1.746a33.74,33.74,0,0,0,27.007.448c2.348-.939,2.34-.96,2.154-3.5a1.528,1.528,0,0,0-.107-.243"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                </g>
              </svg>

              <div className="careers__content__section__card__public__speaking__left__heading">
                ALUMNI RELATIONS SOCIETY
              </div>

              <div className="careers__content__section__card__public__speaking__left__paragraph">
                LGS FSD has produced a generation of ambitious and independent
                young women who have sought for success and accomplished their
                goals.
                <br />
                <br />
                The Alumni Relations Society at LGS FSD enables us to keep in
                touch with our graduate students and ensures that every
                Grammarian legacy lives on. We take great pride in our alums and
                wish to keep them tied in this familial bond that every share.
              </div>
            </div>
          </div>
          <div className="careers__content__section__card__community__service">
            <img
              src={ReunionsPic}
              alt="ReunionsPic"
              className="careers__content__section__card__community__service__img"
            />
            <div className="careers__content__section__card__community__service__right">
              <div className="careers__content__section__card__community__service__right__heading">
                THE REUNION
              </div>
              <div className="careers__content__section__card__community__service__right__paragraph">
                At LGS FSD, we celebrate Reunions in full bloom! Graduates from
                all over the country and around the world join us. Together we
                pay tribute to the institution we call home. Expectations are
                high, but so are our efforts in making our Reunions a success.
                The Alumni Relations Society and the Media Society join forces
                to form a special decorations committee who manage a fantastic
                job; handmade lamps and banners, LGS graffiti stickers, picture
                imprinted mugs, and a fabulous entrance and stage, all made
                possible with the tireless efforts of the Student Council. We
                also release iconic hoodies with the School’s Head
                Administrators serving as brand ambassadors! Each year we hope
                that our audience is reminded of all the wonderful memories a
                they made during their time at school. Whether that is during
                the teachers parodies or dance performances, the mood of the
                night is nostalgic. The Annual Reunion is a great reminder of
                some of the principles we learn at LGS: teamwork, respect for
                one another, and the collective celebration of our common love
                for the school. It reinforces that here at LGS, those that are
                gone are never forgotten!
              </div>
            </div>
          </div>
          <div
            className="careers__content__section__card__international__explore"
            style={{ marginBottom: "-5em" }}
          >
            <img
              src={AlumniRegistrationsPic}
              alt="AlumniRegistrationsPic"
              className="careers__content__section__card__international__explore__img"
            />
            <div className="careers__content__section__card__international__explore__overlay__new">
              <div
                style={{
                  color: "#000",
                  fontSize: 36,
                  display: "flex",
                  flexDirection: "column",
                }}
                className="careers__content__section__card__public__speaking__left__heading"
              >
                <span style={{ fontSize: 20 }}>THEME DINNER</span>
                COMING SOON
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career__section__content" style={{ marginBottom: "5em" }}>
        <div className="career__section__content__header">
          <div
            style={{ fontSize: 22, fontWeight: "bold", marginBottom: 10 }}
            className="career__section__content__para"
          >
            Alumni Registration Form
          </div>
        </div>
        <div className="career__section__content__input__row">
          <InputField
            placeholder="Enter Name"
            label="Name"
            required={isNameRequired}
            value={name}
            onChange={(event) => onNameTextChangeListener(event)}
          />
          <InputField
            placeholder="Enter Father name"
            label="Father Name"
            type="text"
            required={isFatherNameRequired}
            value={fatherName}
            onChange={(event) => onFatherNameTextChangeListener(event)}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            placeholder="Enter Email"
            label="Email"
            required={isEmailRequired}
            value={email}
            onChange={(event) => onEmailTextChangeListener(event)}
          />
          <InputField
            placeholder="Enter Phone No"
            label="Phone No"
            required={isPhoneRequired}
            value={phoneNumber}
            onChange={(event) => {
              if (!isNaN(event.currentTarget.value))
                onPhoneNumberTextChangeListener(event);
            }}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            placeholder="Enter Session"
            label="Session"
            required={isSessionRequired}
            value={session}
            onChange={(event) => onSessionTextChangeListener(event)}
          />
          <InputField
            placeholder="Enter Education"
            label="Education"
            required={isEducationRequired}
            value={education}
            onChange={(event) => onEducationTextChangeListener(event)}
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            placeholder="Enter Name of University (Graduation)"
            label="Name of University (Graduation)"
            required={isGraduationUniRequired}
            value={graduationUniversity}
            onChange={(event) =>
              onGraduationUniversityTextChangeListener(event)
            }
          />
          <InputField
            placeholder="Enter Name of University (Post-Graduation)"
            label="Name of University (Post-Graduation)"
            required={isPostGraduationUniRequired}
            value={postGraduationUniversity}
            onChange={(event) =>
              onPostGraduationUniversityTextChangeListener(event)
            }
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            placeholder="Enter Profession"
            label="Profession"
            required={isProfessionRequired}
            value={profession}
            onChange={(event) => onProfessionTextChangeListener(event)}
          />
          <InputField
            placeholder="Enter Current Business Address"
            label="Current Business Address"
            required={isCurrentBusinessRequired}
            value={currenBusinessAddress}
            onChange={(event) =>
              onCurrentBusinessAddressTextChangeListener(event)
            }
          />
        </div>
        <div className="career__section__content__input__row">
          <InputField
            placeholder="Enter Where did life take you after LGS?"
            label="Where did life take you after LGS?"
            required={isLifeAfterLgsRequired}
            value={lifeAfterLgs}
            onChange={(event) => onLifeAfterLgsTextChangeListener(event)}
          />
          <InputField
            placeholder="Enter Fondest Memory of LGS?"
            label="Fondest Memory of LGS?"
            required={isFondestMemoryRequired}
            value={fondestMemory}
            onChange={(event) => onFondestMemoryTextChangeListener(event)}
          />
        </div>
        <div className="career__section__content__header">
          <div
            style={{
              marginBottom: 20,
              marginTop: 20,
              fontSize: 14,
              color: "red",
            }}
            className="career__section__content__para"
          >
            * Limited seats available.
          </div>
          <div
            style={{ marginBottom: 20, fontSize: 14 }}
            className="career__section__content__para"
          >
            <span style={{ color: "red" }}>Please Note</span> - Event is only
            for the Alumni. Students are requested to not register for event
          </div>
          <button6
            className="section__btn__primary"
            onClick={() => {
              if (isLoading == false) addAlumniRegistrationApiCall();
            }}
          >
            {isLoading ? "Registering..." : "Register"}
          </button6>
        </div>
      </div>
      {/* <div
        style={{
          fontSize: 25,
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "2em",
          textTransform: "uppercase",
        }}
      >
        Photo Gallery
      </div>
      <div className="careers__content__section__campus__life">
        <div className="careers__content__section__campus__life__content">
          <AluminiGalleryCard title="Alumni Dinner '22" />
          <AluminiGalleryCard title="Alumni Dinner'21" />
          <AluminiGalleryCard title="Reunion'19" />
          <AluminiGalleryCard title="Reunion'18" />
        </div>
      </div> */}
    </>
  );
}
