import React, { useEffect, useState } from "react";
import logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X } from "react-feather";
import "animate.css";

function NavLink({ to, children, setIsNavOpen, list, defaultChecked }) {
  const navigate = useNavigate();
  return (
    <div className="header_wrappernav_link">
      <input
        type="radio"
        className="header_wrappernavlink_input"
        name="header_wrappernavlink_input"
        id={children}
        defaultChecked={defaultChecked}
        onClick={() => {
          navigate(to);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          if (window.innerWidth < 1050) {
            setIsNavOpen(false);
          }
        }}
      />
      <div className="header_wrappernavlink_content">{children}</div>
      {list ? (
        <div className="header_wrappernav_link__list">
          {list.map((item, i) => (
            <button
              onClick={() => {
                navigate(to);
                document.getElementById(children).checked = true;
                setTimeout(() => {
                  document.getElementById(item.id).scrollIntoView();
                }, 300);
              }}
              className="header_wrappernav_link__list__entry"
            >
              {item.label}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default function Header() {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(true);
  useEffect(() => {
    if (window.innerWidth < 1150) {
      setIsNavOpen(false);
    } else {
      setIsNavOpen(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1150) {
        setIsNavOpen(false);
      } else {
        setIsNavOpen(true);
      }
    });
    return () => window.removeEventListener("resize", null);
  }, []);
  return (
    <div className="header">
      <div className="header__wrapper">
        <Link to="/" href="#" className="header_wrapper_logo">
          <img src={logo} alt="logo" className="header_wrapperlogo_img" />
        </Link>
        <button
          className="header_wrapper_menu"
          onClick={() => {
            isNavOpen ? setIsNavOpen(false) : setIsNavOpen(true);
          }}
        >
          {isNavOpen ? (
            <X size={20} color="currentColor" />
          ) : (
            <Menu size={20} color="currentColor" />
          )}
        </button>
        {isNavOpen ? (
          <div className="header_wrapper_nav animate__animated  animate__backInDown">
            <NavLink
              to="/"
              id="home"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={window.location.pathname == "/" ? true : false}
            >
              Home
            </NavLink>
            <NavLink
              to="/our-school"
              id="ourschool"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/our-school" ? true : false
              }
              list={[
                {
                  id: "ourschool",
                  label: "our school",
                },
                {
                  id: "ourstory",
                  label: "our story",
                },
                {
                  id: "ourvision",
                  label: "our vision",
                },
                {
                  id: "parentshandbook",
                  label: "parents hand book",
                },
                {
                  id: "academiccalendar",
                  label: "academic calendar",
                },
                {
                  id: "behaviourpolicy",
                  label: "behaviour policy",
                },
                {
                  id: "codeofconduct",
                  label: "code of conduct",
                },
              ]}
            >
              Our School
            </NavLink>
            <NavLink
              to="/our-campus"
              id="ourcampus"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/our-campus" ? true : false
              }
              list={[
                {
                  id: "canalcampus",
                  label: "Canal Campus",
                },
                {
                  id: "105campus",
                  label: "105 Campus",
                },
              ]}
            >
              Our Campus
            </NavLink>
            <NavLink
              to="/admissions"
              id="admissions"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/admissions" ? true : false
              }
              list={[
                {
                  id: "admissionprocedure",
                  label: "Admission Procedure",
                },
                {
                  id: "admissiontestdates",
                  label: "Admission Test Dates",
                },
                {
                  id: "admissiontestrequirements",
                  label: "Admission Test Requirements",
                },
                {
                  id: "admissionprocedurealevel",
                  label: "Admission Procedure A Level",
                },
              ]}
            >
              Admissions
            </NavLink>
            <NavLink
              to="/campus-life"
              id="careers"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/campus-life" ? true : false
              }
              list={[
                {
                  id: "international",
                  label: "international exposure",
                },
                {
                  id: "public",
                  label: "public speaking",
                },
                {
                  id: "community",
                  label: "Community service",
                },
                {
                  id: "science",
                  label: "science and technology",
                },
                {
                  id: "drama",
                  label: "Drama, Music and Language",
                },
                {
                  id: "sports",
                  label: "Sports",
                },
                {
                  id: "qalamkaar",
                  label: "qalamkaar",
                },
              ]}
            >
              Campus Life
            </NavLink>
            <NavLink
              to="/alumini"
              id="alumini"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/alumini" ? true : false
              }
            >
              Alumni
            </NavLink>
            <NavLink
              to="/counselling-center"
              id="counselling-center"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/counselling-center" ? true : false
              }
            >
              Counseling
            </NavLink>
            <NavLink
              to="/complaints"
              id="complaints"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/complaints" ? true : false
              }
            >
              Complaints
            </NavLink>
            <NavLink
              to="/career"
              id="career"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/career" ? true : false
              }
            >
              Careers
            </NavLink>
            {/* <NavLink to="/about" id="about" setIsNavOpen={setIsNavOpen}>
              About Us
            </NavLink> */}
            <NavLink
              to="/contact-us"
              id="contactus"
              setIsNavOpen={setIsNavOpen}
              defaultChecked={
                window.location.pathname == "/contact-us" ? true : false
              }
            >
              Contact Us
            </NavLink>
          </div>
        ) : null}
      </div>
    </div>
  );
}
